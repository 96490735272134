import React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import userAvatar from 'app/assets/images/user_avatar.png'
import './TopNavbar.scss'

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  )
  const { t } = useTranslation()

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const logout = () => {
    document.cookie =
      'id_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    document.cookie =
      'access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    caches.delete('profile')
    window.location.reload()
  }

  const routesMenu = [
    {
      path: '/',
      name: t('menu.dashboard')
    },
    {
      path: '/vips',
      name: t('menu.vipsList')
    },
    {
      path: '/contacts',
      name: t('menu.savedContacts')
    },
    {
      path: '/dictionary',
      name: t('general.dictionary')
    }
  ]

  const location = useLocation()

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: 'white', padding: '0 10px', marginBottom: '1em' }}
    >
      <Container
        maxWidth="xl"
        sx={{ paddingLeft: '12px !important', paddingRight: '8px !important' }}
      >
        <Toolbar disableGutters>
          <Link to={'/'} className="navbar-link">
            <img
              alt="logo"
              src="https://www.angelinipharma.com/images/logo_pharma/angelini_pharma_v2.svg"
            />
          </Link>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' },
              color: '#85879C'
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' }
              }}
            >
              {routesMenu.map((route, index) => {
                if (index === routesMenu.length - 1) {
                  return (
                    <div>
                      <Link to={route.path}>
                        <Typography
                          fontSize={14}
                          fontWeight={500}
                          color="#85879C"
                          className={`${
                            route.path === location.pathname ? 'active' : ''
                          }`}
                        >
                          {window.location.pathname === route.path ? (
                            <b>{route.name}</b>
                          ) : (
                            route.name
                          )}
                        </Typography>
                      </Link>
                    </div>
                  )
                }
                return true
              })}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {routesMenu.map((route, index) => {
              return (
                <div>
                  <Link to={route.path}>
                    <Typography
                      fontSize={16}
                      fontWeight={500}
                      color="#85879C"
                      className={`${
                        route.path === location.pathname ? 'active' : ''
                      }`}
                    >
                      {window.location.pathname === route.path
                        ? route.name
                        : route.name}
                    </Typography>
                  </Link>
                </div>
              )
            })}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar src={userAvatar} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Link to="/scoring-criteria">
                <MenuItem className="navbar-menu">
                  <Typography textAlign="center">
                    {t('general.scoringCriteria')}
                  </Typography>
                </MenuItem>
              </Link>
              <Link to="/profile">
                <MenuItem className="navbar-menu">
                  <Typography textAlign="center">
                    {t('general.profile')}
                  </Typography>
                </MenuItem>
              </Link>
              <MenuItem onClick={logout} className="navbar-menu">
                <Typography textAlign="center">
                  {t('general.logout')}
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default ResponsiveAppBar
