import { Entity } from "app/types/Entity";
import { Person } from "app/types/Person";
import { axios, checkStatus, header_options, url } from "./ProviderHelpers";

const getAllVips = async (text?: string) => {
  try {
    const response = await axios.get(`${url}/vips?text=${text}`, {
      ...header_options,
    });

    return response.data.data;
  } catch (error: any) {
    await checkStatus(error.response);
  }
};

const getVip = async (
  id: number,
  cognitoId: string,
  skip: number = 0,
  text: string
) => {
  try {
    const response = await axios.get(
      `${url}/vips/${id}/${cognitoId}?skip=${skip}&text=${text}`,
      {
        ...header_options,
      }
    );

    return response.data.data;
  } catch (error: any) {
    await checkStatus(error.response);
  }
};

const getVipGraph = async (id: number, cognitoId: string) => {
  try {
    const response = await axios.get(`${url}/vips/graph/${id}/${cognitoId}`, {
      ...header_options,
    });

    return response.data.data;
  } catch (error: any) {
    await checkStatus(error.response);
  }
};

const getAllContacts = async (cognitoId: string, text?: string) => {
  try {
    const response = await axios.get(
      `${url}/contacts/favourites/${cognitoId}?text=${text}`,
      {
        ...header_options,
      }
    );

    return response.data.data;
  } catch (error: any) {
    await checkStatus(error.response);
  }
};

const getFavouriteVipContacts = async (
  vip: number,
  cognitoId: string,
  skip: number = 0,
  text?: string
) => {
  try {
    const response = await axios.get(
      `${url}/vips/favourites/${vip}/${cognitoId}?skip=${skip}&text=${text}`,
      {
        ...header_options,
      }
    );

    return response.data.data;
  } catch (error: any) {
    await checkStatus(error.response);
  }
};

const getContact = async (
  id: number,
  cognitoId: string,
  skip: number = 0,
  text: string
) => {
  try {
    const response = await axios.get(
      `${url}/contacts/${id}/${cognitoId}?skip=${skip}&text=${text}`,
      {
        ...header_options,
      }
    );

    return response.data.data;
  } catch (error: any) {
    await checkStatus(error.response);
  }
};

const getVipEntitiesCategories = async (id: number, startDate?: string, endDate?: string) => {
  try {
    const response = await axios.get(`${url}/vips/${id}/categories`, {
      ...header_options,
      params: {
        startDate,
        endDate,
      },
    });

    return response.data.data;
  } catch (error: any) {
    await checkStatus(error.response);
  }
};

const getContactEntitiesCategories = async (id: number, startDate?: string, endDate?: string) => {
  try {
    const response = await axios.get(`${url}/contacts/${id}/categories`, {
      ...header_options,
      params: {
        startDate,
        endDate,
      },
    });

    return response.data.data;
  } catch (error: any) {
    await checkStatus(error.response);
  }
};

const getGeographicalAreas = async () => {
  try {
    const response = await axios.get(`${url}/geographical-areas/`, {
      ...header_options,
    });

    return response.data.data;
  } catch (error: any) {
    await checkStatus(error.response);
  }
};

const postCreateVip = async (data: Person, id: number, cognito_id: string) => {
  try {
    const response = await axios.post(`${url}/vips/create`, [data, id, cognito_id], {
      ...header_options,
    });
    return response.data.data ? response.data.data : response.data;
  } catch (error: any) {
    await checkStatus(error.response);
  }
};

const deleteVip = async (id: number) => {
  try {
    const response = await axios.delete(`${url}/vips/remove/${id}`, {
      ...header_options,
    });
    return response.data.data;
  } catch (error: any) {
    await checkStatus(error.response);
  }
};

const getEntitiesByVip = async (id: number, startDate?: string, endDate?: string): Promise<Entity[]> => {
  try {
    const response = await axios.get(`${url}/vips/${id}/entities`, {
      ...header_options,
      params: {
        startDate,
        endDate,
      },
    });
    return response.data.data as Entity[];
  } catch (error: any) {
    await checkStatus(error.response);
  }
};

const getInfoById = async (id: number, cognitoId: string) => {
  try {
    const response = await axios.get(`${url}/vips/info/${id}/${cognitoId}`, {
      ...header_options,
    });
    return response.data.data;
  } catch (error: any) {
    await checkStatus(error.response);
  }
};

const getMaxScore = async () => {
  try {
    const response = await axios.get(`${url}/vips/maxscore`, {
      ...header_options,
    });
    return response.data.data;
  } catch (error: any) {
    await checkStatus(error.response);
  }
};

class VipsProvider {
  public static getAllVips = getAllVips;
  public static getVip = getVip;
  public static getVipGraph = getVipGraph;
  public static getContact = getContact;
  public static getAllContacts = getAllContacts;
  public static getFavouriteVipContacts = getFavouriteVipContacts;
  public static getVipEntitiesCategories = getVipEntitiesCategories;
  public static getContactEntitiesCategories = getContactEntitiesCategories;
  public static getGeographicalAreas = getGeographicalAreas;
  public static postCreateVip = postCreateVip;
  public static deleteVip = deleteVip;
  public static getEntitiesByVip = getEntitiesByVip;
  public static getInfoById = getInfoById;
  public static getMaxScore = getMaxScore;
}

export default VipsProvider;
