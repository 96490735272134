import { useMemo } from "react"
import { Doughnut } from 'react-chartjs-2';

const DoughnutCustom = ({data, options}: any) => {

    const doughnut = useMemo(() => (
        <Doughnut data={data} options={options()} />
    ),[data])

    return (
        doughnut
    )

}

export default DoughnutCustom;