import useEntityStore from "app/stores/entities.store";
import { Entity } from "app/types/Entity";
import { EntityGroup } from "app/types/EntityGroup";
import { t } from "i18next";
import React from "react";
import { useEffect, useState } from "react";
import EntityContainer from "./EntityContainer";
import "./EntityList.scss";

interface Props {
  vipId: number;
  startDate?: string;
  endDate?: string;
  isConnection?: boolean;
}

const EntityList = (props: Props) => {
  const [vipId, setVipId] = useState(props.vipId);
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);
  const [data, setData] = useState<any[]>([]);
  
  const { getEntities } = useEntityStore();

  const getData = async () => {
    await useEntityStore.destroy();
    
    if(vipId === 0) return;
    const data = await getEntities(
      vipId,
      startDate,
      endDate,
      props.isConnection
    );
    setData(
      data
        .map((group) => {
          let result: any = { ...group };
          result.words = group.words
            .reduce((acc, entity) => {
              const entityInAcc = acc.find(
                (e) => e.entity.name === entity.name
              );
              if (entityInAcc) {
                entityInAcc.count += 1;
                if (entity.source != null && entity.source.url != null)
                  entityInAcc.sources.push(entity.source);
              } else {
                if (entity.source != null && entity.source.url != null)
                  acc.push({
                    entity: entity,
                    count: 1,
                    sources: [entity.source],
                  });
                else acc.push({ entity: entity, count: 1, sources: [] });
              }
              return acc;
            }, [] as { entity: Entity; count: number; sources: { url: string; date: string; type: string }[] }[])
            .sort((a, b) => {
              return a.count > b.count ? -1 : 1;
            });
          return result;
        })
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        })
    );
    useEntityStore.subscribe((state, prevState) => {
      getData();
    });
  };

  useEffect(() => {
    if (
      props.vipId === vipId &&
      props.startDate === startDate &&
      props.endDate === endDate
    )
      return;

    setVipId(props.vipId);
    setStartDate(props.startDate);
    setEndDate(props.endDate);
  }, [props.vipId, props.endDate, props.startDate]);

  useEffect(() => {
    
    getData();
  }, [vipId, startDate, endDate]);

  useEffect(
    () => () => {
      useEntityStore.destroy();
    },
    []
  );

  return (
    <div className="entityList">
      {data.length > 0 ? (
        data.map((group) => {
          return (
            <div className="entityGroup" key={group.id}>
              <div className="entityGroupTitle">{group.name}</div>
              <div className="entityGroupContainer">
                {group.words.map((item: any) => {
                  return (
                    <EntityContainer
                      entity={item.entity}
                      vipId={vipId}
                      count={item.count}
                      sources={item.sources}
                    />
                  );
                })}
              </div>
            </div>
          );
        })
      ) : (
        <p
          style={{
            color: "#85879C",
            fontSize: 14,
            fontStyle: "italic",
            marginRight: "300px",
          }}
        >
          {t("general.noTopics")}{" "}
        </p>
      )}
    </div>
  );
};

export default EntityList;
