import useEntityStore from 'app/stores/entities.store'

import { useEffect, useState } from 'react'
import DoughnutCustom from 'app/components/DoughnutCustom/DoughnutCustom'
import { t } from 'i18next'
import { Typography } from '@mui/material'

interface Props {
  vipId: number,
  startDate?: string,
  endDate?: string
}

const EntityDoughnut = (props: Props) => {
  const [groupCount, setGroupCount] = useState<any>({})
  const [doughnutOthers, setDoughnutOthers] = useState<string>('')
  const [dougnutData, setDoughnutData] = useState<any>({})
  const [possibleDoughnutColours, setPossibleDoughnutColours] = useState<any>({
    0: '#0D60AC',
    1: '#BDDEFF',
    2: '#18B19D',
    3: '#65AEDC',
    4: '#7AD6CA',
    5: '#228191',
    6: '#E1F0FF',
    7: '#3970D5',
    8: '#82D480',
    9: '#B8B9C6'
  })


  const doughnutOptions = () => {
    return {
      cutout: 70,
      maintainAspectRatio: false,
      responsive: true,
      animation: false,
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            label: (context: any) => {
              let label = ''
              let group = context.label != '' ? context.label + ': ' : t('general.others') + ': '

              if (context.parsed) {
                label = group + context.parsed + t('general.words')
              }
              return label
            }
          }
        }
      }
    }
  }

  const setEntities = async () => {
    await new Promise((resolve) => setTimeout(resolve, 300));
    const entities = await useEntityStore.getState().entities
    if (!entities[props.vipId]) {
      return
    }
    let entityCount: any = {}
    let othersCount = 0
    let othersGroup = ''

    const sorted = entities[props.vipId].items.sort(
      (a: { name: string; words: any }, b: { name: string; words: any }) => {
        return b.words.length - a.words.length
      }
    )
    sorted.forEach((entity: { name: string; words: any }, index: number) => {
      if (index < 9) {
        entityCount[entity.name] = entity.words.length
      } else {
        entityCount['Otros'] = othersCount += entity.words.length
        othersGroup +=
          sorted.length == index + 1 ? entity.name : entity.name + ', '
      }
    })

    setGroupCount(entityCount)
    setDoughnutOthers(othersGroup)
  }

  const calculateDoughnutData = () => {
    const ret = {
      labels: Object.keys(groupCount).slice(0, 9),
      datasets: [
        {
          label: '',
          data: Object.values(groupCount).slice(0, 10),
          backgroundColor: Object.values(possibleDoughnutColours).slice(0, 10),
          rotation: 90
        }
      ]
    }

    setDoughnutData(ret)
  }

  const subscribeEntities = useEntityStore.subscribe((state, prevState) => {
    setEntities()
  })


  useEffect(() => {
    return () => {
      subscribeEntities()
    }
  }, [])

  useEffect(() => {
    calculateDoughnutData()
  }, [groupCount])

  const doughnutCount = Object.values(groupCount).reduce(
    (acc: any, cur: any) => {
      return acc + cur
    },
    0
  )

  useEffect(() => {
    setEntities()
  }, [props.vipId, props.startDate, props.endDate])


  return (
    <div>
      {doughnutCount !== 0 && (
        <>
          <Typography variant="h6">{t('categories.title')}</Typography>
          <div className="grid-chart-wrapper">
            <div className="grid-charts-amount">
              <span>
                {Object.values(groupCount).reduce((acc: any, cur: any) => {
                  return acc + cur
                }, 0)}
              </span>
            </div>
            <div className="grid-charts-doughnut">
              <DoughnutCustom data={dougnutData} options={doughnutOptions} />
              <div className="grid-charts-legend">
                {Object.keys(groupCount)
                  .slice(0, 10)
                  .map((idx: string, index: number) => {
                    const color = possibleDoughnutColours[index]

                    return (
                      <div className="legend-item-wrapper">
                        <div
                          className="legend-color"
                          style={{ backgroundColor: color }}
                        ></div>
                        <span>{idx}</span>
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default EntityDoughnut
