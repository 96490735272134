import React from 'react'
import TopNavbar from "app/components/TopNavbar/TopNavbar"
import { withTranslation, WithTranslation } from "react-i18next";
import CriteriaOptions from "app/components/CriteriaOptions/CriteriaOptions";
import { Parameters } from "app/types/Parameters";
import ParametersProvider from "app/services/ParametersProvider"; 
import {
  Box,
  Button,
  Slide,
  Snackbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import MDBox from "materials/components/MDBox";
import { TransitionProps } from "@mui/material/transitions";
import moment from 'moment'


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class ScoringCriteria extends React.Component<
  WithTranslation,
  {
  parameters_likes: Parameters[];
  newParameters: Parameters[];
  openSnackbar: boolean;
  oldParameters: Parameters[];
  date: string
  }> {
  constructor(props: any) {
    super(props);

    this.state = {
      parameters_likes: null,
      newParameters: [],
      oldParameters: [],
      openSnackbar: false,
      date: moment().format('DD/MM/YYYY')
    };
    this.getParameters();
  }

  getParameters = async (memory?: boolean) => {
    const parameters = memory? this.state.oldParameters : await ParametersProvider.getAllParameters();
    
    this.setState({
      parameters_likes: parameters
        .filter((param:any) => param.table_id === 1)
        .sort(
          (a, b) =>
            a.label.localeCompare(b.label) || a.name.localeCompare(b.name)
        ),
      newParameters: [],
      oldParameters: parameters
    });
  };

  handleParametersChange = (parameters: Parameters[]) => {
    const _parameters = this.state.parameters_likes.map(
      (parameter: Parameters) => {
        const _parameterIndex = parameters.findIndex(
          (item) => item.id === parameter.id
        );
        if (_parameterIndex !== -1) {
          return parameters[_parameterIndex];
        }
        return parameter;
      }
    );
    this.setState({
      parameters_likes: _parameters,
      newParameters: this.state.newParameters
        .filter((item) => item.table !== parameters[0].table)
        .concat(parameters),
    });
  };

  updateParameters = async () => {
    if (this.state.newParameters.length > 0) {
      await ParametersProvider.updateParameters(this.state.newParameters);
      this.setState({
        parameters_likes: null,       
        newParameters: [],
        openSnackbar: true,
      });
      this.getParameters();
    }
  };

  render() {

    return (
      <>
        <TopNavbar />
        <div className='container pt-4 pb-4'>
          <h1>{this.props.t("general.scoringCriteria")}</h1>
          <p style={{marginBottom: 0, fontSize: 17}}>Aplicado desde 12/09/2022 a {this.state.date}</p>
          <CriteriaOptions
            {...{
              parameters: this.state.parameters_likes,
            }}
            dataChange={this.handleParametersChange}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
              bottom: 0,
              left: 0,
              zIndex: 1,
            }}
          >
            <Button
              sx={{
                height: "3rem",
                width: "7.5rem",
                merginLeft: "auto",
                marginRight: "1rem",
                background: "#EBEBF4",
                color: "#585A6A",
                border: "none",
                "&:hover": {
                  border: "none",
                  background: "none",
                },
              }}
              onClick={() => this.getParameters(true) }
            >
              {this.props.t("adminPanel.criteriaManagement.criterias.cancel")}
            </Button>
            <Button
              sx={{
                height: "3rem",
                width: "7.5rem",
                merginLeft: "1rem",
                backgroundColor: "#2EA0B2",
                color: "#fff",
                border: "none",
                "&:hover": {
                  border: "none",
                  backgroundColor: "var(--secondary-3)",
                },
              }}
              onClick={this.updateParameters}
            >
              {this.props.t("adminPanel.criteriaManagement.criterias.save")}
            </Button>
            <Snackbar
              open={this.state.openSnackbar}
              autoHideDuration={6000}
              TransitionComponent={Transition}
              onClose={() => this.setState({ openSnackbar: false })}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <MDBox
                sx={{
                  width: "15.75rem",
                  borderLeft: "1px solid var(--positive)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  backgroundColor: "var(--white)",
                  height: "7rem",
                  boxShadow: " 1px 1px 4px rgba(0, 0, 0, 0.15)",
                  borderRadius: "2px",
                  marginTop: "5rem",
                  padding: "2rem",
                }}
              >
                <CheckCircleRoundedIcon
                  sx={{
                    color: "var(--positive)",
                    marginRight: "1rem",
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "24px",
                  }}
                >
                  {this.props.t(
                    "adminPanel.criteriaManagement.successfullySaved"
                  )}
                </Typography>
                <CloseIcon
                  sx={{
                    position: "absolute",
                    right: "0.5rem",
                    height: "100%",
                    paddingBottom: "2rem",
                    cursor: "pointer",
                  }}
                  onClick={() => this.setState({ openSnackbar: false })}
                />
              </MDBox>
            </Snackbar>
          </Box>
        </div>
      </>
    )
  }
}

export default withTranslation("")(ScoringCriteria);