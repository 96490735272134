import { Dropdown, Space, Tooltip } from "antd";
import { GroupType } from "app/types/GroupType";
import React, { useEffect } from "react";
import type { MenuProps } from "antd";
import { t } from "i18next";
import "./GroupDropdown.scss";
import chevronDown from "app/assets/images/chevron_down.svg";
import checkMark from "app/assets/images/checkmark.svg";
import { Keyword } from "app/types/Keyword";
import DictionaryProvider from "app/services/DictionaryProvider";
import useEntityStore from "app/stores/entities.store";
interface GroupDropdownProps {
  groups: GroupType[];
  selected: GroupType;
  dictionary: Keyword;
  changeGroup: () => void;
}

export const GroupDropdown = (props: GroupDropdownProps) => {
  const [menu, setMenu] = React.useState<MenuProps>(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<GroupType>(props.selected);
  useEffect(() => {
    const menu: MenuProps = {
      items: [],
    } as MenuProps;
    menu["items"] = props.groups.map((group) => {
      return {
        key: group.id,
        label: group.name,
        icon:
          (props.selected === null && group.id === 0) ||
          group.id === props.selected?.id ? (
            <img src={checkMark} alt="checkmark" />
          ) : null,
      };
    });
    menu["onClick"] = (e) => {
      const selectedGroup = props.groups.find(
        (group) => group.id === parseInt(e.key)
      );
      //setSelected(selectedGroup);
      save(selectedGroup.id);
    };

    const selected = props.selected
      ? ({
          ...props.selected,
          name:
            props.selected.entity_type_id !== null
              ? t("group.entityType." + props.selected.name)
              : props.selected.name,
        } as GroupType)
      : null;
    setSelected(selected);
    setMenu(menu);
    
  }, [props.groups, props.selected, props.dictionary]);

  const {deleteAll} = useEntityStore();
  const save = async (group: number) => {
    const key = {
      keyword: props.dictionary.keyword,
      group_id: group,
    };
    await DictionaryProvider.updateKeywords(props.dictionary.id, key);
    deleteAll();
    props.changeGroup();
  };

  return (
    <>
      <Dropdown
        className="group-filters-dropdown"
        menu={menu}
        trigger={["click"]}
        onOpenChange={() => setOpen(!open)}
      >
        <Space>
          <Tooltip
            placement="topLeft"
            title={selected?.name || t("groupCard.noGroup")}
          >
            {selected?.name || t("groupCard.noGroup")}
          </Tooltip>
          <img
            src={chevronDown}
            alt="chevron"
            style={{ transform: open ? "rotate(180deg)" : "rotate(0deg)" }}
          />
        </Space>
      </Dropdown>
    </>
  );
};
