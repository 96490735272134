import { EntityGroupParser } from "app/helpers/EntityGroupParser";
import ConnectionProvider from "app/services/ConnectionProvider";
import PersonProvider from "app/services/PersonProvider";
import { EntityGroup } from "app/types/EntityGroup";
import { create } from "zustand";
import {
  createJSONStorage,
  persist,
  subscribeWithSelector,
} from "zustand/middleware";

interface EntityStore {
  entities: { [vip: number]: { items: EntityGroup[]; date: string,  startDate: string, endDate:string , isconnection?: boolean} };
  loading: boolean;
  getEntities: (vip: number, startDate?: string, endDate?: string, isconnection?:boolean) => Promise<EntityGroup[]>;
  updateEntity: (vip: number, isconnection?:boolean) => void;
  deleteAll: () => void;
}

const useEntityStore = create<EntityStore>()(
  subscribeWithSelector(
    persist(
      (set, get) => ({
        entities: {},
        loading: false,
        getEntities: async (vip: number, startDate?: string, endDate?: string, isconnection: boolean = false) => {
          try {
            const { entities } = get();
            if (
              entities[vip] != null 
              && (startDate && entities[vip].startDate == startDate)
              && (endDate && entities[vip].endDate == endDate)
            ) {
              return entities[vip].items;
            } else {
              if (get().loading) return [];
              set({ loading: true });
              const startDateStr = startDate? startDate : entities[vip]?.startDate || undefined;
              const endDateStr = endDate? endDate : entities[vip]?.endDate || undefined;

              const entitiesNew = isconnection? await ConnectionProvider.getConnectionsEntities(vip,startDateStr, endDateStr) : await PersonProvider.getEntitiesByVip(vip, startDateStr, endDateStr) ;
              const entitiesGroup = EntityGroupParser(entitiesNew);
              // are diferents?
              if(entities[vip] != null && entitiesGroup.length == entities[vip].items.length){
                let diferents = entitiesGroup.filter((entity, index) => {
                  return entity.id != entities[vip].items[index].id;
                });
                if(diferents.length == 0){
                  set((state) => ({
                    loading: false,
                  }));
                  return entitiesGroup;
                }
              }
              set((state) => ({
                entities: {
                  [vip]: { items: entitiesGroup, date: new Date().toString(), startDate: (startDateStr || startDate) , endDate: (endDateStr || endDate) , isconnection},
                },
                loading: false,
              }));
              return entitiesGroup;
            }
          } catch (e) {
            console.error(e);
            return [];
          }

        },
        updateEntity: async (vip: number) => {
          const { entities } = get();
          const entity = entities[vip];
          const {startDate, endDate, isconnection} = entity;
          const _entities = isconnection? await ConnectionProvider.getConnectionsEntities(vip,startDate, endDate) : await PersonProvider.getEntitiesByVip(vip, startDate, endDate) ;
          const entitiesGroup = EntityGroupParser(_entities);
          set({
            entities: {
              [vip]: { items: entitiesGroup, date: new Date().toString(), startDate, endDate, isconnection },
            },
          });
        },
        deleteAll: () => {
          set({ entities: {}, loading: false });
        },
      }),
      {
        name: "entities-store",
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);

window.onload = () => {
  useEntityStore.getState().deleteAll();

};

export default useEntityStore;
