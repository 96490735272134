import { axios, url, header_options, checkStatus } from "./ProviderHelpers";

const getFavourites = async (cognitoId: string) => {
    try {       
        const response = await axios.get(`${url}/favourite/${cognitoId}`,
            {
                ...header_options,
            }
        );
       
        return response.data.data;
    }
    catch (error:any) {
        await checkStatus(error.response);
    }
}

const putUserFavourite = async (cognitoId: string, personId: number) => {
    try {
        const response = await axios.put(
            `${url}/favourite/${cognitoId}`,
            {personId},
            { ...header_options }
        );
        return response.data.data;
    } catch (error: any) {
        await checkStatus(error.response);
    }
}

const putEditUser = async (oldPassword:string, newPassword: string) => {
    try {
        const acces_token = document.cookie.split("access_token=")[1].split(";")[0];
        header_options.headers['Authorization'] = `Bearer ${acces_token}`;
        
        const response = await axios.post(`${url}/user/changepassword`,
            {old_password: oldPassword, new_password: newPassword},
            { ...header_options }
        );
        if(response.status === 200) {
            return response.data.data.message;
        }
        else {
            return false;
        }
    } catch (error: any) {
        await checkStatus(error.response);
    }
}

class UserProvider {
    public static putUserFavourite = putUserFavourite;
    public static getFavourites = getFavourites;
    public static putEditUser = putEditUser;
}

export default UserProvider;