import { useMemo } from "react"
import { Line } from 'react-chartjs-2';

const LineCustom = ({data}: any) => {
    const lineChartOptions = () => {
        return {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            }
          },
          ticks: {
            precision: 0
          },
          scales: {
            x: {
              display: false,
              grid: {
                display: true
              }
            },
            y: {
              display: true,
              grid: {
                display: false
              },
              min: 0
            }
          }
        }
    }

    const line = useMemo(()=> (
        <Line data={data} options={lineChartOptions()} />
    ), [data])

    return (
        line
    )
}

export default LineCustom;