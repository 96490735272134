
export const color = (id: number): {textColor: string, backgroundColor:string} => {
    const possibleColors = [
        {

            textColor :'--acent---1',
            backgroundColor : '--acent-1-back',
        },
        {
            textColor :'--acent---2',
            backgroundColor: '--acent-2-back'
        },
        {
            textColor :'--acent---3',
            backgroundColor: '--acent-3-back'
        },
        {
            textColor :'--acent---4',
            backgroundColor: '--acent-4-back'
        },
        {
            textColor :'--acent---5',
            backgroundColor: '--acent-5-back'
        },
        {
            textColor :'--acent---6',
            backgroundColor: '--acent-6-back'
        },
        {
            textColor :'--acent---7',
            backgroundColor: '--acent-7-back'
        },
        {
            textColor :'--acent---8',
            backgroundColor: '--acent-8-back'
        },
    ];
    return  possibleColors[id % possibleColors.length];
}