import React from 'react'

class NotFound extends React.Component {
  constructor (props: {}) {
    super(props)
    console.log('NotFount constructor')
  }

  public render () {
    return (
      <div>
        <h1>NotFount</h1>
        <p>This is the not found page.</p>
      </div>
    )
  }
}

export default NotFound
