import { Card, Switch, Input, Button, Dropdown, Tooltip } from 'antd';
import { t } from "i18next";
import { useState } from 'react';
import type { MenuProps } from 'antd';

import chevronDown from 'app/assets/images/chevron_down.svg'
import infoIcon from 'app/assets/images/info-icon.png'

import './KeywordCard.scss'

interface KeywordCardProps {
    addKeyword: (kw: string, group: number, checked: boolean) => void;
    groups?: any;
    showKeywordError: boolean;
}
  

const KeywordCard = (props: KeywordCardProps) => {
    const [checked, setChecked] = useState(true);
    const [keyword, setKeyword] = useState('');
    const [group, setGroup] = useState(null);	

    const onChangeSwitch = (checked: boolean) => {
        checked ? setChecked(true) : setChecked(false);
    }

    const handleGroupClick: MenuProps['onClick'] = (e) => {        
        const selectedGroup = props.groups.find((idx: { id: number; name: string; }) => idx.id === parseInt(e.key)) 
        setGroup(selectedGroup);
    }

    const handleRenderGroup = () => {
        if (group) {
            return group.name
        } else {
            return props.groups[0].name
        }
    }

    const groupsList = (): MenuProps => {
        return {
          items: props.groups.map((idx: { id: number; name: string; }) => ({
            key: idx.id,
            label: (
              <>
                {idx.name}
              </>
            )
        })),
        onClick: handleGroupClick
        }
    };
    
    return (
        <Card className='addVip-card mb-4' size="small" 
                title={t("dictionary.addKeyword.title")} 
                extra={<Tooltip placement='rightTop' title={t("dictionary.addKeyword.tooltip")}><img src={infoIcon} /></Tooltip>} >
            <div className='addVip-input'>
                <span className='mb-2'>{t("dictionary.table.list")}</span>
                <div className='d-flex switch-container'>
                    <Switch defaultChecked onChange={onChangeSwitch} />
                    <span className='ml-2'>{checked ? t("dictionary.table.relevant") : t("dictionary.table.ignored")}</span>
                </div>
                <hr />
                <span className='mb-2'>{t("dictionary.table.group")}</span>
                <Dropdown className="groups-dropdown" menu={groupsList()} trigger={['click']}>
                    <Button>
                        <span style={{fontFamily: "Poppins"}} >{props.groups.length > 0 && handleRenderGroup()}</span>
                        <img src={chevronDown} alt="chevron" />
                    </Button>
                </Dropdown>
                <hr />
                <span className='mb-2'>{t("dictionary.table.keywordCard.keyword")}</span>
                <Input 
                    onChange={(e) => setKeyword(e.target.value)} 
                    value={keyword}
                    className={`${props.showKeywordError  && 'error-input'}`}
                    onKeyPress={(event) => {
                        if (event.key === "Enter" && keyword !== '') {
                            props.addKeyword(keyword, group ? group.id : props.groups[0].name, checked)
                            setKeyword('')
                        }
                    }}
                />
                { props.showKeywordError && <span className='error'>{ t("dictionary.table.keywordCard.repeatedKeywordError") }</span> }
            </div>
        </Card>
    )
}

export default KeywordCard;