import { Stack, Box, Typography, Chip } from '@mui/material';
import { Tooltip } from 'antd';
import { t } from 'i18next';
import { useEffect, useState } from "react";
import Info from "app/assets/images/info.png";
import "./CategoryTopics.scss"

interface Props {
    data: [];
}

const CategoryTopics = (props: Props) => {
    const { data } = props;

    const [categories, setCategories] = useState(null);


    const parseCategories = () => {
        let categories: any = [];
        data && data.map((category: any) => {
            const fields = category?.type.name.split('/');
            let subChild = fields[4] ? fields[3] + " - " + fields[4] : fields[3];
            let childCategory = { subChild: subChild ? subChild : "" }
            let data = { categoryPrimary: fields[2], childCategory: childCategory }
            categories.push(data)
            return data;
        })

        const result = Array.from(new Set(categories.map((s: { categoryPrimary: any; }) => s.categoryPrimary)))
            .map(lab => {
                return {
                    label: lab?.toString() || "",
                    data: categories.filter((s: { categoryPrimary: any }) => s.categoryPrimary === lab)
                        .map((edition: { childCategory: any; score: number }) => {
                            return {
                                child: edition.childCategory.child,
                                subChild: edition.childCategory.subChild
                            }
                        })
                }
            })


        for (const part of result) {
            const result2 = groupBy(part.data, "child")
            part.data = result2;
        }

        result.sort((a, b) => a.label.localeCompare(b.label))

        setCategories(result)
    }

    const groupBy = (items: [], key: string) => items.reduce(
        (result, item) => ({
            ...result,
            [item[key]]: [
                ...(result[item[key]] || []),
                item,
            ],
        }),
        {},
    );

    useEffect(() => {
        parseCategories()
    }, [data])


    return (
        <Box className='categories-container' sx={{ marginTop: "1em", background: "#fff", overflowY: "visible" }}>
            <Typography variant='h3' className={`title`} sx={{ marginBottom: "0.5em" }} >
                {t("general.categories")}
                <Tooltip className='ml-2' placement="right" overlayStyle={{minWidth: '370px'}} title={<div className="categories-tooltip">{t("categories.tooltip")}</div>}>
                    <img src={Info} />
                </Tooltip>
            </Typography>
            <Stack direction="row" style={{ display: "block" }} >
                {categories && categories.length > 0 ?
                    categories.filter((it: any) => it.label != "Other").map((category: { label: string, data: any }) => (
                        <>
                            {category.data && Object.keys(category.data)
                            .map((item) => {
                                let subChild = category.data[item].map((subChild: any, i: number) => (
                                    subChild.subChild
                                )).sort().filter((it:any) => it != "Other")
                                return (
                                    <Tooltip title={subChild.includes('') || subChild.length == 0 ? "" : <><div>{t("categories.highlighted")}</div> {subChild.join(", ")}</>} placement="top">
                                        <Chip label={category.label} variant="outlined" />
                                    </Tooltip>
                                )
                            })}
                        </>
                    ))
                    : <p style={{ color: "#85879C", fontSize: 14, fontStyle: "italic" }}>{t("general.noCategories")} </p>
                }
            </Stack>
        </Box>
    )
}

export default CategoryTopics;