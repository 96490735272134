import { Entity } from "app/types/Entity";
import { EntityGroup } from "app/types/EntityGroup";
import { t } from "i18next";

const types = [
  "PERSON",
  "ORGANIZATION",
  "LOCATION",
  "EVENT",
  "CONSUMER_GOOD",
  "ADDRESS",
  "WORK_OF_ART",
];

export const EntityGroupParser = (data: Entity[]): EntityGroup[] => {
  if (!data) return [];
  const groups = data.reduce((acc, entity) => {
    const group = acc.find((g) => g.id === entity.group_id);

    if (group) {
      group.words.push(entity);
    } else {
      if (entity.group_id) {
        const name =
          types.findIndex((t) => t === entity.group) > -1
            ? t("group.entityType." + entity.group)
            : entity.group;
        acc.push({
          id: entity.group_id,
          name: name,
          words: [entity],
        });
      } else {
        acc.push({
          id: null,
          name: t("groupCard.noGroup"),
          words: [entity],
        });
      }
    }

    return acc;
  }, []);

  return groups;
};
