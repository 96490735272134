import { axios, checkStatus, header_options, url } from "./ProviderHelpers";

const getDashboardData = async () => {
  try {
    const response = await axios.get(`${url}/dashboard`, {
      ...header_options,
    });
    return response.data.data;
  } catch (error: any) {
    setTimeout(async () => {
      const response = await axios.get(`${url}/dashboard`, {
        ...header_options,
      });
      return response.data.data;
    }, 1000);
    await checkStatus(error.response);
  }
};

class DashboardProvider {
  public static getDashboardData = getDashboardData;
}

export default DashboardProvider;
