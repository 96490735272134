import { Parameters } from "app/types/Parameters";
import { Status } from "app/types/Status";
import axios from "axios";
import {  url, checkStatus, header_options } from "./ProviderHelpers";

const getAllParameters = async (): Promise<Parameters[]> => {
  try {
    const response = await axios.get(`${url}/parameters`, {
      ...header_options,
    });

    return response.data.data as Parameters[];
  } catch (error: any) {
    await checkStatus(error.response);
  }
};

const updateParameters = async (
  parameters: Parameters[]
): Promise<Parameters[]> => {
  try {
    const response = await axios.put(
      `${url}/parameters`,

      parameters,
      { ...header_options }
    );
    return response.data.data as Parameters[];
  } catch (error: any) {
    await checkStatus(error.response);
  }
};

const getStatus = async (): Promise<Status[]> => {
    try {
        const response = await axios.get(`${url}/parameters/status`, {
            ...header_options,
        });
        return response.data.data as Status[];
    } catch (error: any) {
        await checkStatus(error.response);
    }
}

class ParametersProvider {
  public static getAllParameters = getAllParameters;
  public static updateParameters = updateParameters;
  public static getStatus = getStatus;
}

export default ParametersProvider;
