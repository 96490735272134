import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import EnglishTranslations from "./messages/en.json";
import SpanishTranslations from "./messages/es.json";

const SUPPORTED_LANGUAGES = ["en", "es"];

const resources = {
    en: {
        translation: EnglishTranslations,
    },
    es: {
        translation: SpanishTranslations,
    },    
};

const detectionOptions = {
    order: ["querystring", "cookie", "localStorage", "sessionStorage", "navigator", "htmlTag", "path", "subdomain"],
    lookupQuerystring: "lng",
    lookupCookie: "i18next",
    lookupLocalStorage: "i18nextLng",
    lookupSessionStorage: "i18nextLng",
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,
    caches: ["localStorage"],
};

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "es",
        supportedLngs: SUPPORTED_LANGUAGES,
        nonExplicitSupportedLngs: true,
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
        detection: detectionOptions,
    });

export const isSupportedLanguage = lang => SUPPORTED_LANGUAGES.some(lng => lng === lang);

export default i18n;
