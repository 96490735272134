import React from "react";

const axios = require('axios');
const url = process.env.REACT_APP_API_URI;

const verifyToken = async (token: string): Promise<JSON> => {
    try {
        const response = await axios.get(`${url}/auth/token`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization': `Bearer ${token}`,
                },
            }
        );
        if(response.status === 200){
            const decode: any = JSON.parse(atob(token.split('.')[1]));
            return decode
        }
        else{
            return null;
        }
    }
    catch (error) {
       // console.error(error);
    };
}

const useAuth = () => {
    const token = document.cookie.includes("id_token")? document.cookie.split("id_token=")[1].split(";")[0] : null;
    const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(document.cookie.includes("id_token"));
    if (token) {
        verifyToken(token).then(result => {
            if (result) {
                setIsAuthenticated(true);
                sessionStorage.setItem("user", JSON.stringify(result));
            }
            setIsLoading(false);
        });
    }

    return {
        isAuthenticated ,
        isLoading,
    }
}

export default useAuth;