import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "./noConnectionsContact.scss";
import noContacts from "app/assets/images/no_contacts.svg";

interface Props extends WithTranslation {
  name: string;
}

class NoConnectionsContact extends React.Component<Props> {
  render() {
    const { t, name } = this.props;

    return (
      <div className="no-connections-contact">
        <div className="no-connections-contact__images">
            <img src={noContacts} alt="no connections contact"/>
        </div>
        <div className="no-connections-contact__text">
          <span>{t("noConnectionsContact.text_top")}</span>
          <span>{t("noConnectionsContact.text_center")}"{name}"</span>
          <span>{t("noConnectionsContact.text_bottom")}</span>
        </div>
      </div>
    );
  }
}

export default withTranslation("")(NoConnectionsContact);

