import { useState } from 'react';
import {
  Button,
  Modal,
  Box,
  Typography,
  CardMedia,
  CardContent,
  CardActions,
  Card
} from '@mui/material';
import { Space, Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import './PersonCardDetail.scss'
import { Link } from "react-router-dom";
import { t } from 'i18next';
import UserProvider from "app/services/UserProvider"
import ModalDeleteVip from '../ModalDeleteVip/ModalDeleteVip';
import PersonProvider from 'app/services/PersonProvider';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import PersonForm from 'app/components/PersonForm/PersonForm'

//Import images
import avatarVip from 'app/assets/images/avatar_vip.png'
import avatarContact from 'app/assets/images/contact_avatar.png'
import twitter from 'app/assets/images/twitter_logo.png'
import medalVip from 'app/assets/images/medal.png'
import medalContact from 'app/assets/images/contact_medal.png'
import contactsIcon from 'app/assets/images/contact_icon.png'
import medalIcon from 'app/assets/images/medal_icon.png'
import starIcon from 'app/assets/images/star_icon.png'
import vipIcon from 'app/assets/images/vip_icon.png'
import trashIcon from 'app/assets/images/trash_icon.png'
import chevronDown from 'app/assets/images/chevron_down.svg'
import badgeFavorite from 'app/assets/images/star_icon_filled.png'
import savedContact from 'app/assets/images/saved-tag.png'


const PersonCardDetail = ({ person, isVip, isShareIcons, isGoToProfile, showContacts, isSaveContact, getContactDetails, saveContactMode, memberCount, savedContacts }: any) => {

  const [hover, setHover] = useState(false)
  const [openVipModal, setOpenVipModal] = useState(false);
  const [openModal, setOpenModal] = useState(false)
  
  const navigate = useNavigate();

  const handleVipClose = () => {
    setOpenVipModal(false)
  };

  const handleVipOpen = () => {
    setOpenVipModal(true)
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#fff',
    border: '1px solid #EBEBF4',
    boxShadow: '1px 1px 20px rgba(195, 196, 207, 0.5)',
    borderRadius: '8px'
  };

  const handleSubmitAddVip = async (values: any) => {
    let user = JSON.parse(sessionStorage.user);
    const result = await PersonProvider.postCreateVip(values, person.id, user.sub)
    if (result.result == "success") {
      const newVip: string = person.id;
      navigate('/vips', { state: { itemId: newVip } })
    }
    else {
      result.result == "error" ? toast.error(t('addVip.toast.error')) : toast.error(t('dictionary.toast.error'))
    }
  }

  const handleSaveContact = async () => {
    let user = JSON.parse(sessionStorage.user);
    await UserProvider.putUserFavourite(user.sub, person.id)
    getContactDetails && getContactDetails()
  }
  
  const handleDeleteVip = async () => {
    const result = await PersonProvider.deleteVip(person.id)
    
    if (result.result == "success"){
      navigate('/vips', { state: { vipRemoved: true } })
    } 
    else {
      toast.error(t('addVip.toast.error'))
    }
  }

  const items: MenuProps['items'] =
    isVip ?
      [
        {
          label:
            <Button onClick={handleVipOpen} className="action-button" >
              <img style={{ marginRight: "1em" }} src={trashIcon} alt="trash" />
              {t("general.deleteVip")}
            </Button>,
          key: '0',
        }        
      ]
      :
      [
        {
          label:
            <Button onClick={handleSaveContact} className="action-button" >
              <img style={{ marginRight: "1em" }} src={starIcon} alt="star" />
              {person.favourites?.length === 0 ? t("general.saveContact") : t("general.unsaveContact")}
            </Button>,
          key: '0',
        },
        {
          label:
            <Button onClick={() => setOpenModal(true)} className="action-button" >
              <img style={{ marginRight: "1em" }} src={vipIcon} alt="add vip" />
              {t("general.addVip")}
            </Button>,
          key: '1',
        }
      ];

      const profile_image = person.avatar.indexOf("jpg") > -1 || person.avatar.indexOf("png") > -1 || person.avatar.indexOf("jpeg") > -1
       ? person.avatar : (isVip ? avatarVip : avatarContact)
  return (
    <Card sx={{ maxWidth: "100%" }} className={`card-vip-container `}>
      <div className='flex-row d-flex'>
        <div style={{ position: "relative" }}>
          {isGoToProfile ?
            <Link to={isVip ? `/vips/${person.id}` : `/contacts/${person.id}`} className="avatar-link" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} >
              <CardMedia
                component="img"
                height="110"
                image={profile_image}
                alt="avatar vip"
                className={`${isVip ? "avatar-vip-image" : "avatar-contact-image"}`}
              />
            </Link>
            :
            <CardMedia
              component="img"
              height="110"
              image={profile_image}
              alt="avatar vip"
              className={`${isVip ? "avatar-vip-image" : "avatar-contact-image"}`}
            />
          }
          {isVip ?
            <img src={medalVip} alt="vip" className="medal-image" />
            :
            <>
            <img src={medalContact} alt="contact" className="contact-image" />
            {person.favourites?.length > 0 && 
              <img src={savedContact} alt="contact" className="saved-contact" />
            }
            </>
          }
        </div>
        <CardContent className='text-left m-auto' sx={{ paddingRight: 0 }} >
          <Typography gutterBottom variant="subtitle1" component="div">
            {person.location ? person.location : "-"} | {person.work_area ? person.work_area : "-"}
          </Typography>
          <Typography gutterBottom variant="h1" component="div">
            {isGoToProfile ?
              isVip ?
                <Link to={`/vips/${person.id}`} className={`link ${hover ? "text-underline" : ""}`} >{person.name}</Link>
                :
                <Link to={`/contacts/${person.id}`} className={`link ${hover ? "text-underline" : ""}`} >{person.name}</Link>
              :
              person.name
            }
          </Typography>
          <div className="social-icons">
            {isGoToProfile &&
              <CardActions sx={{ display: "inline" }} >
                <a href={person.mediaUrl ? person.mediaUrl : `https://twitter.com/${person.person_social[0]?.username}`} target="blank" style={{ display: "inline-block" }} >
                  <img src={twitter} alt="twitter" />
                </a>
              </CardActions>
            }
            {(isGoToProfile && isSaveContact && saveContactMode === "compact") && (
              <div className="separator"></div>
            )}
            {(isSaveContact && saveContactMode === "compact") && (
              <div className="save-contact-badge-compact">
                <Dropdown className="filters-dropdown" menu={{ items }} trigger={['click']}>
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      ···
                    </Space>
                  </a>
                </Dropdown>
              </div>
            )}
          </div>
          {showContacts ?
            <>
            <Typography gutterBottom variant="subtitle1" component="div" className='contacts-number d-inline-flex mr-2' sx={isVip ? { background: "#FAF0D1" } : { background: "#FBEAF4" }} >
              <img src={isVip ? contactsIcon : medalIcon} alt="medal" />
              &nbsp; {memberCount ? memberCount : 0}
              &nbsp;{isVip ? t("general.contacts") : t("general.vip")}
            </Typography>
            { isVip && 
              <Typography gutterBottom variant="subtitle1" component="div" className='saved-contacts-number d-inline-flex' sx={{ background: "#FBEAF4" }} >
                <img src={badgeFavorite} alt="star" />
                &nbsp; {savedContacts ? savedContacts : 0}
                &nbsp;{t("general.saved")}
              </Typography>
            }
            </>
            : null
          }
        </CardContent>
        {!isGoToProfile && isShareIcons && person.person_social && person.person_social.length > 0 ?
          <div className="social-icons">
            <CardActions>
              <a href={person.mediaUrl ? person.mediaUrl : `https://twitter.com/${person.person_social[0]?.username}`} target="blank" ><img src={twitter} alt="twitter" /></a>
            </CardActions>
          </div>
          : null
        }
        <div className='save-contact-container'>
          {saveContactMode !== "compact"  && (
            <Dropdown className="filters-dropdown" menu={{ items }} trigger={['click']}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <img src={chevronDown} alt="chevron" />
                  {t("general.actions")}
                </Space>
              </a>
            </Dropdown>
          )}
        </div>
      </div>
      { isVip &&
        <ModalDeleteVip handleConfirm={handleDeleteVip} openModal={openVipModal} handleClose={handleVipClose}  />
      }
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <Box sx={style}>
          <PersonForm handleSubmit={handleSubmitAddVip} handleClose={() => setOpenModal(false)} data={person} />
        </Box>
      </Modal>
    </Card>
  );
}

export default PersonCardDetail;
