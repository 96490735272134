import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { t } from 'i18next';
import moment from 'moment'
import "./DataTable.scss";
import contactToVip from 'app/assets/images/contact_to_vip.png'
import vipToContact from 'app/assets/images/vip_to_contact.png'
import bidirectional from 'app/assets/images/bidirectional.png'
import Twitter from 'app/assets/images/twitter.svg'

import SelectorUpDefault from 'app/assets/images/selector-up-default.svg'
// import SelectorUpHover from 'app/assets/images/selector-up-hover.svg'
import SelectorUpSelected from 'app/assets/images/selector-up-selected.svg'
import SelectorDownDefault from 'app/assets/images/selector-down-default.svg'
// import SelectorDownHover from 'app/assets/images/selector-down-hover.svg'
import SelectorDownSelected from 'app/assets/images/selector-down-selected.svg'


const DataTable = (props: any) => {

    const setDirectionalityImage = (directionalityId: number) => {
        let source;
        switch (directionalityId) {
            case 1:
                source = vipToContact;
                break;
            case 2:
                source = contactToVip;
                break;
            default:
                source = bidirectional;
                break;
        }
        return source;
    }

    const renderSortingToggle = () => {
        return (
            <div className="table-sorting-wrapper" onClick={() => props.changeSorting(props.sorting === 'ASC' ? 'DESC' : 'ASC')}>
                <img src={props.sorting === 'ASC' ? SelectorUpSelected : SelectorUpDefault} alt="up" />
                <img src={props.sorting === 'DESC' ? SelectorDownSelected : SelectorDownDefault} alt="down" />
            </div>
        )
    }

    return (
        <TableContainer component={Paper} className="data-table">
            <Table sx={{ minWidth: 650 }} size="medium" >
                <TableHead>
                    <TableRow>
                        <TableCell align="left">
                            {t("interactionTable.date")}
                            {renderSortingToggle()}
                        </TableCell>
                        <TableCell align="left">{t("interactionTable.type")}</TableCell>
                        <TableCell align="left">{t("interactionTable.directionality")}</TableCell>
                        <TableCell align="left">{t("interactionTable.source")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.length > 0 && props.data.map((row: any) => (
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{moment(row.date).format('DD/MM/YYYY')}</TableCell>
                            <TableCell sx={{fontWeight: "normal"}} align="left">{row.type ? t(`interactionTable.interaction.${row.type.id}`) : ""}</TableCell>
                            <TableCell align="left">{row.directionality ? <img src={setDirectionalityImage(row.directionality.id)} alt="direction" /> : ""}</TableCell>
                            <TableCell align="left">
                                <div className="social-link">
                                    <a href={row.url} target="_blank" rel="noreferrer">
                                        <img src={Twitter} alt={t('interactionTable.socialmedia.' + row.type.socialmedia.id)} />
                                    </a>
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default DataTable;