import {
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
  Tooltip,
  Modal,
  Box
} from '@mui/material'
import { useEffect, useState } from 'react'
import './PersonCardContact.scss'
import likeIcon from 'app/assets/images/like_icon.png'
import interactionContact from 'app/assets/images/interaction_contact.png'
import interactionVip from 'app/assets/images/interaction_vip.png'
import { Link } from 'react-router-dom'
import { t } from 'i18next'
import ModalUnsaveContact from '../ModalUnsaveContact/ModalUnsaveContact'
import UserProvider from 'app/services/UserProvider'
import twitter from 'app/assets/images/twitter_logo.png'
import { Dropdown, MenuProps, Space } from 'antd'
import starIcon from 'app/assets/images/star_icon.png'
import badgeFavorite from 'app/assets/images/badge-favorite.png'
import vipIcon from 'app/assets/images/vip_icon.png'
import PersonForm from '../PersonForm/PersonForm'
import PersonProvider from 'app/services/PersonProvider'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

const PersonCardContact = ({
  person,
  score,
  connectionId = null,
  isVip,
  showAddFavourite,
  reload,
  interactionNumber
}: any) => {
  const [openModal, setOpenModal] = useState(false)
  const [openAddVipModal, setOpenAddVipModal] = useState(false)
  const [maxScore, setMaxScore] = useState(0)
  const [zoomLevel, setZoomLevel] = useState(100);
  const navigate = useNavigate()
  const zoomIsActive = zoomLevel >= 145
  const maxNameLength = zoomLevel >= 145 ? 25 : 100;

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#fff',
    border: '1px solid #EBEBF4',
    boxShadow: '1px 1px 20px rgba(195, 196, 207, 0.5)',
    borderRadius: '8px'
  }

  useEffect(() => {
    const detectZoom = () => {
      const calculatedZoom = (window.outerWidth / window.innerWidth) * 100;
      setZoomLevel(calculatedZoom);
    };
  
    detectZoom();
    window.addEventListener('resize', detectZoom);
  
    return () => {
      window.removeEventListener('resize', detectZoom);
    };
  }, []);
  

  const getMaxScore = async () => {
    const maxscore = await PersonProvider.getMaxScore()
    setMaxScore(maxscore)
  }

  useEffect(() => {
    getMaxScore();
  }, [])

  const handleSubmitAddVip = async (values: any) => {
    let user = JSON.parse(sessionStorage.user)
    const result = await PersonProvider.postCreateVip(values, person.id, user.sub)

    if (result.result == 'success') {
      const newVip: string = person.id;
      navigate('/vips', { state: { itemId: newVip } })
    } else {
      result.result == 'error'
        ? toast.error(t('addVip.toast.error'))
        : toast.error(t('dictionary.toast.error'))
    }
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  const handleSaveContact = async () => {
    let user = JSON.parse(sessionStorage.user)
    await UserProvider.putUserFavourite(
      user.sub,
      person.personId ? person.personId : person.id
    )
    handleClose()
    reload()
  }

  const items: MenuProps['items'] = [
    {
      label: (
        <Button onClick={handleSaveContact} className="action-button">
          <img style={{ marginRight: '1em' }} src={starIcon} alt="star" />
          {person.favourites?.length === 0
            ? t('general.saveContact')
            : t('general.unsaveContact')}
        </Button>
      ),
      key: '0'
    },
    {
      label: (
        <Button
          onClick={() => setOpenAddVipModal(true)}
          className="action-button"
        >
          <img style={{ marginRight: '1em' }} src={vipIcon} alt="add vip" />
          {t('general.addVip')}
        </Button>
      ),
      key: '1'
    }
  ]

  return (
    <>
      <Card
        sx={
          isVip
            ? { borderTop: '4px solid #A98413' }
            : { borderTop: '4px solid #AE2577' }
        }
        className={`card-contact-container h-100`}
      >
        <div className="flex-column d-flex w-100">
          <div style={{ position: 'absolute', top: 7, right: 15 }}>
            { !isVip && showAddFavourite ? (
              <Dropdown
                className="filters-dropdown"
                menu={{ items }}
                trigger={['click']}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>···</Space>
                </a>
              </Dropdown>
            ) : null}
          </div>
          <CardContent className="text-left p-2 mb-2">
            <div style={{ marginBottom: '1.5em' }}>
              {person.favourites && person.favourites?.length !== 0 ? (
                <img
                  style={{ position: 'absolute', top: 7, left: 15 }}
                  src={badgeFavorite}
                />
              ) : null}
            </div>
            {zoomIsActive ?
              <Typography gutterBottom variant="subtitle1" component="div">
                {person.location && person.location != 'None' ? person.location.length <= maxNameLength ? person.location : person.location.slice(0, maxNameLength - 3) + '...' : '-'}
                <br />
                {person.work_area ? person.work_area.length <= maxNameLength ? person.work_area : person.work_area.slice(0, maxNameLength - 3) + '...' : '-'}
              </Typography> 
              :<Typography gutterBottom variant="subtitle1" component="div">
                {person.location && person.location != 'None'
                  ? person.location
                  : '-'}{' '}
                | {person.work_area ? person.work_area : '-'}
              </Typography>
            }
            <Typography gutterBottom variant="h5" component="div">
              <Link
                to={
                  !isVip
                    ? `/contacts/${
                        person.personId ? person.personId : person.id
                      }`
                    : `/vips/${person.personId ? person.personId : person.id}`
                }
                className="link profile-link"
              >
                {person.name ? person.name : person.label}
              </Link>
            </Typography>
            <Typography
              gutterBottom
              variant="body1"
              component="div"
              className="d-inline-flex"
              sx={{ verticalAlign: 'bottom', marginBottom: 0 }}
            >
              {person.url ||
              (person.social && person.social.length > 0) ||
              (person.person_social && person.person_social.length > 0) ? (
                <a
                  href={
                    person.url
                      ? person.url
                      : person.mediaUrl
                      ? person.mediaUrl
                      : `https://twitter.com/${
                          person.social
                            ? person.social
                            : person.person_social[0]?.username
                        }`
                  }
                  target="_blank"
                  style={{ padding: '0.3em 0.3em', margin: 'auto' }}
                >
                  <img
                    style={{ width: '18px', height: '18px', margin: 'auto' }}
                    src={twitter}
                    alt="twitter"
                  />
                </a>
              ) : null}
            </Typography>
            {connectionId ? (
              <div className="d-inline-flex b-left-grey" style={{borderLeft: zoomIsActive ? 'none' : '1px solid #EBEBF4', marginLeft: zoomIsActive ? '5px' : '10px'}}>
                <Tooltip
                  title={t('general.numberInteractions')}
                  placement="top"
                >
                  <div className="interactions-container ml-2">
                    <img
                      src={isVip ? interactionVip : interactionContact}
                      className="mr-2"
                      alt="interaction"
                    />
                    <span style={{ color: '#3F4253' }}>
                      {interactionNumber > 99 ? '99+' : interactionNumber}
                    </span>
                  </div>
                </Tooltip>
                <Tooltip title={t('general.score')} placement="top">
                  <div className="scoring-container">
                    <img src={likeIcon} className="mr-2" alt="like" />
                    <span>{(score/maxScore*100).toFixed(1)}%</span>
                  </div>
                </Tooltip>
              </div>
            ) : null}
          </CardContent>
          {connectionId ? (
            <CardActions>
              <Link to={`/connection/${connectionId}`}>
                {t('general.connectionDetails')}
              </Link>
            </CardActions>
          ) : null}
        </div>
      </Card>
      {showAddFavourite && (
        <ModalUnsaveContact
          person={person}
          handleSaveContact={handleSaveContact}
          openModal={openModal}
          handleClose={handleClose}
        />
      )}
      <Modal open={openAddVipModal} onClose={() => setOpenAddVipModal(false)}>
        <Box sx={style}>
          <PersonForm
            handleSubmit={handleSubmitAddVip}
            handleClose={() => setOpenAddVipModal(false)}
            data={person}
          />
        </Box>
      </Modal>
      <ToastContainer autoClose={8000} />
    </>
  )
}

export default PersonCardContact
