import React from 'react'
import TopNavbar from "app/components/TopNavbar/TopNavbar"
import PersonCard from 'app/components/PersonCard/PersonCard'
import { withTranslation, WithTranslation } from "react-i18next";
import PersonProvider from 'app/services/PersonProvider';
import { Grid, Button, Modal, Box } from '@mui/material';
import SearchBar from 'app/components/SearchBar/SearchBar';
import PersonForm from 'app/components/PersonForm/PersonForm'
import vipIcon from 'app/assets/images/vip.png'
import successIcon from 'app/assets/images/success_icon.png'
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import "./Vips.scss"
import BottomBar from 'app/components/BottomBar/BottomBar';


class Vips extends React.Component<WithTranslation & any, { vips: any, search: string, open: boolean, success_message: boolean, success_message_remove: boolean, vips_loaded: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = {
      vips: null,
      search: "",
      open: false,
      success_message: false,
      success_message_remove: false,
      vips_loaded: false
    };
  }

  style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#fff',
    border: '1px solid #EBEBF4',
    boxShadow: '1px 1px 20px rgba(195, 196, 207, 0.5)',
    borderRadius: '8px'
  };

  CustomToastWithLink = (person: string) => (
    <div>
      <p>{this.props.t("addVip.toast.success")}</p>
      <Link to={`/vips/${person}`}>{this.props.t("addVip.toast.seeProfile")}</Link>
    </div>
  );

  CustomToast = () => (
    <div>
      <p>{this.props.t("removeVip.toast.success")}</p>
    </div>
  );

  componentDidMount() {
    this.getVips();
  }

  componentDidUpdate() {
    const itemId = window.history.state.usr?.itemId
    const vipRemoved = window.history.state.usr?.vipRemoved
    
    if (itemId && !this.state.success_message) {
      toast.success(this.CustomToastWithLink(itemId), { icon: <img src={successIcon} /> });
      window.history.replaceState({}, document.title)
      this.setState({ success_message: true })
    }
    if (vipRemoved && !this.state.success_message_remove){
      toast.success(this.CustomToast(), { icon: <img src={successIcon} /> });
      this.setState({ success_message_remove: true })
      window.history.replaceState({}, document.title)
    }
  }

  getVips = async () => {
    let vips = await PersonProvider.getAllVips(this.state.search);
    vips = vips.sort(function (a: { name: any; fromCount: number; }, b: { name(name: any): number; fromCount: number; }) {
      return b.fromCount - a.fromCount || a.name.localeCompare(b.name);
    });
    this.setState({ vips: vips, vips_loaded: true });
  };

  handleSetSearch = (query: string) => {
    this.setState({ search: query })
  }

  handleClose = () => this.setState({ open: false });

  handleSubmitAddVip = async (values: any) => {
    const result = await PersonProvider.postCreateVip(values, null, null)

    if (result.result == "success") {
      const newVip = result.data.id;      
      toast.success(this.CustomToastWithLink(newVip), { icon: <img src={successIcon} /> });
      this.getVips()
      this.setState({ open: false })
    }
    else {
      result.result == "error" ? toast.error(this.props.t('addVip.toast.error')) : toast.error(this.props.t('dictionary.toast.error'))
    }
  }

  render() {
    return (
      <>
        <TopNavbar />
        <div className='container pt-4'>
          <div className='d-flex align-items-center mb-2'>
            <h1 style={{ fontWeight: "bold" }}>{this.props.t("menu.vipsList")}</h1>
            <Button className='button-default ml-auto' onClick={() => this.setState({ open: true })}><img src={vipIcon} /> &nbsp; {this.props.t("general.addVip")}</Button>
          </div>
          <SearchBar 
              getData={this.getVips} 
              search={this.handleSetSearch}
              disabled={!this.state.vips_loaded}
          />
          <p style={{ fontSize: '14px', color: "#85879C", lineHeight: "21px" }} >{this.props.t("vipsPage.showing")} {this.state.vips && this.state.vips.length} {this.props.t("vipsPage.vips")}</p>
          <Grid container spacing={2}>
            {this.state.vips ?
              this.state.vips.map((vip: any) => (
                <Grid item xs={3} >
                  <PersonCard person={vip} isVip={true} />
                </Grid>
              ))
              :
              null
            }
          </Grid>
        </div>
        <Modal
          open={this.state.open}
          onClose={this.handleClose}
        >
          <Box sx={this.style}>
            <PersonForm handleSubmit={this.handleSubmitAddVip} handleClose={this.handleClose} />
          </Box>
        </Modal>
        <ToastContainer autoClose={8000} />
        <BottomBar />
      </>
    )
  }
}

export default withTranslation("")(Vips);