import /* React, */ { useEffect, useMemo, useState } from 'react'
import './App.scss'
import { /* BrowserRouter as Router, */Routes, Route, useLocation } from 'react-router-dom'
import "bootstrap/dist/css/bootstrap.min.css";

import { GuardedRoute/*, SignIn */ } from 'app/auth'
import { CacheProvider, ThemeProvider } from '@emotion/react'
import { CssBaseline/*, Icon */ } from '@mui/material'
import theme from 'materials/assets/theme'
import themeDark from 'materials/assets/theme-dark'
import createCache from '@emotion/cache'
import { useMaterialUIController } from 'materials/context'
import themeRTL from 'materials/assets/theme/theme-rtl'
import themeDarkRTL from 'materials/assets/theme-dark/theme-rtl'

import rtlPlugin from 'stylis-plugin-rtl'
import routes from 'app/routes'
import { RoutesDefinitions } from 'app/types'


function App() {
  const [controller /*, dispatch*/] = useMaterialUIController()
  const {
    // miniSidenav,
    direction,
    layout,
    // openConfigurator,
    // sidenavColor,
    // transparentSidenav,
    // whiteSidenav,
    darkMode
  } = controller
  // const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null)
  const { pathname } = useLocation()

  // Cache for the rtl
  useMemo(() => {
    const pluginRtl: any = rtlPlugin
    const cacheRtl = createCache({
      key: 'rtl',
      stylisPlugins: [pluginRtl]
    })

    setRtlCache(cacheRtl)
  }, [])

  // Open sidenav when mouse enter on mini sidenav
  // const handleOnMouseEnter = () => {
  //   if (miniSidenav && !onMouseEnter) {
  //     setMiniSidenav(dispatch, false);
  //     setOnMouseEnter(true);
  //   }
  // };

  // Close sidenav when mouse leave mini sidenav
  // const handleOnMouseLeave = () => {
  //   if (onMouseEnter) {
  //     setMiniSidenav(dispatch, true);
  //     setOnMouseEnter(false);
  //   }
  // };

  // Change the openConfigurator state

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction)
  }, [direction])

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
  }, [pathname])


  const getRoutes = (_routes: RoutesDefinitions[] ): JSX.Element[] | null => {
    return _routes.map((route, index) => {
      const { path, main, auth ,  ...rest } = route;
      if(rest.children){
        getRoutes(rest.children.map((child) => {
          child.path = `${path}/${child.path}`;
          return child;
        }));
      }
      return auth?
      <Route key={index} path={path} element={<GuardedRoute />}>
        <Route key={index} path={path} element={main} />
      </Route> :
      <Route key={index} path={path}  element={main} />;
    });
  };

  const isRTL = direction === 'rtl'

  const appTheme = (
    <ThemeProvider theme={darkMode
      ? (
        isRTL ? themeDarkRTL : themeDark
      )
      : (
        isRTL ? themeRTL : theme
      )}
    >
      <CssBaseline />      
      {layout === 'vr' && ':)'}
        <Routes>
          {getRoutes(routes)}
        </Routes>
    </ThemeProvider>
  )


  return isRTL
    ? (
      <CacheProvider value={rtlCache}>
        {appTheme}
      </CacheProvider>
    )
    :
    (appTheme)

}

export default App
