import { styled } from "@mui/material/styles";
import { InputBase, Box } from "@mui/material";
import searchIcon from "app/assets/images/search_icon.png";
import { t } from "i18next";
import { useEffect, useState } from "react";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& .Mui-focused": {
    color: "#585A6A",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#85879C",
  minHeight: "55px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100ch",
    },
  },
  "& .MuiInputBase-input:hover::placeholder": {
    color: "#585A6A",
    opacity: 1,
  },

  "& .MuiInputBase-input:focus": {
    color: "#585A6A",
    opacity: 1,
  },
  "& .MuiInputBase-input:active": {
    color: "#585A6A",
    opacity: 1,
  },
}));

const SmallInputBase = styled(InputBase)(({ theme }) => ({
    
  fontSize: "18px",
  color: "var(--black)",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create("width"),
    color: "var(--gray---1)",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "21ch",
    },
  },
  "& .MuiInputBase-input:hover::placeholder": {
    color: "var(--black)",
    opacity: 1,
  },

  "& .MuiInputBase-input:focus": {
    color: "var(--gray---2)",
    opacity: 1,
  },
  "& .MuiInputBase-input:active": {
    color: "var(--black)",
    opacity: 1,
  },
}));

type Props = {
  getData?: Function;
  search: Function;
  isSmall?: boolean;
  placeholder?: string;
  height?: number | string;
  width?: number | string;
  fontSize?: number | string;
  disabled?: boolean;
};

const SearchBar = ({
  getData,
  search,
  isSmall,
  placeholder = t("general.searchbar"),
  height = "35px",
  width = "100%",
  fontSize = "18px",
  disabled = false
}: Props) => {
  const [query, setQuery] = useState("");

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      search(query);
      getData(query);
    }, 1000);
    return () => clearTimeout(timeOutId);
  }, [query]);

  return (
    <Box
      sx={{
        background: "#F9F9FA",
        border: "1px solid #EBEBF4",
        borderRadius: "4px",
        marginBottom: "0.5em",
        alignItems: "center",
        display: "flex",
      }}
    >
      <Search>
        <SearchIconWrapper>
          <img src={searchIcon} alt="search" />
        </SearchIconWrapper>
        {isSmall ? (
          <SmallInputBase
            placeholder={placeholder}
            style={{ height, width, fontSize }}
            inputProps={{ "aria-label": "search" }}
            onChange={(event) => setQuery(event.target.value)}
            disabled={disabled}
          />
        ) : (
          <StyledInputBase
            placeholder={placeholder}
            inputProps={{ "aria-label": "search" }}
            onChange={(event) => setQuery(event.target.value)}
            disabled={disabled}
          />
        )}
      </Search>
    </Box>
  );
};

export default SearchBar;
