import {
    Typography,
    Button,
    Box
} from '@mui/material'
import Modal from '@mui/material/Modal';
import { t } from 'i18next';
import warning from 'app/assets/images/warning.png' 

const ModalDeleteVip = ({ handleConfirm, openModal, handleClose} : any) => {
    
    const style = {
        position: 'absolute' as 'absolute',
        borderRadius: "2%",
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };


    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            className={"unsave-modal"}
        >
            <Box sx={style}>
                <img src={warning} alt="warning" />
                <Typography sx={{ mt: 2, textAlign: "center", fontSize: "16px", fontWeight: 600 }} >
                    {t("modal.deleteVip1")}
                </Typography>
                <Typography sx={{ mt: 2, textAlign: "center", fontSize: "14px", fontWeight: 500, color: "#585A6A" }} >
                    {t("modal.deleteVip2")}
                </Typography>
                <div className='d-flex mt-4 justify-content-center'>
                    <Button onClick={handleClose} sx={{ background: "#EBEBF4", color: "#585A6A", marginRight: "5%", textTransform: "none", letterSpacing: "1px" }} >{t("modal.no")}</Button>
                    <Button onClick={handleConfirm} sx={{ background: "#2EA0B2", color: "#fff", textTransform: "none", letterSpacing: "1px" }} >{t("modal.yes")}</Button>
                </div>
            </Box>
        </Modal>
    )
}

export default ModalDeleteVip;