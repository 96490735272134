import { debounce } from "@mui/material";
import { Box } from "@mui/system";
import { Parameters } from "app/types/Parameters";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import CriteriaLabel from "./CriteriaLabel";

class CriteriaOptions extends React.Component<
  WithTranslation & {
    parameters: Parameters[];
    dataChange: Function;
  },
  {
    parameters: Parameters[];
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      parameters: props.parameters,
    };
  }

  componentDidMount(): void {
    this.handleDataChange = debounce(this.handleDataChange, 200);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.parameters !== this.props.parameters) {
      this.setState({ parameters: this.props.parameters });
    }
  }

  handleDataChange = (parameter: Parameters) => {
    const _parameters = this.props.parameters.map((_parameter: Parameters) => {
      if (_parameter.id === parameter.id) {
        return parameter;
      }
      return _parameter;
    });
    
    this.setState({ parameters: _parameters });
    this.props.dataChange(_parameters);
  };

  render() {
    return (
      <Box
        sx={{ 
          paddingTop: "1em",         
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          border: 0,
          position: "relative",
        }}
      >
        {this.props.parameters? this.props.parameters
          .map((parameter: Parameters) => {
            const colorid = this.props.parameters.findIndex(
              (item: Parameters) => item.label === parameter.label
            );
            return (
              <CriteriaLabel
                {...this.props}
                parameter={parameter}
                dataChange={this.handleDataChange}
                colorid={colorid}
              />
            );
          }) : null}
      </Box>
    );
  }
}

export default withTranslation("")(CriteriaOptions);
