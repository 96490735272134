import MDBox from "materials/components/MDBox";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import loading from "app/assets/images/loading.svg" 

class Calculating extends React.Component<
WithTranslation & {
    active: boolean;
}, {}> {
  render() {
      if(this.props.active){
          return (
              <MDBox sx={{
                  zIndex: 9999,
                  backgroundColor: '#F5F5F5',
                  opacity: 0.9,
                  width: '100vw',
                  height: '100vh',
                  position: 'fixed',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  top:0,
                  left:0
              }}
              >
                  <MDBox sx={{
                    animation: 'App-logo-spin infinite 1.5s linear'
                  }}> <img  src={loading} alt="logo" /></MDBox>
                  <MDBox sx={{
                      marginTop: 3,
                      textAlign: 'center',
                      color: "var(--primary--800)",
                      whiteSpace: "pre-line",
                      fontWeight: 700,
                      fontSize: 20
                  }}>
                    {this.props.t('loading.calculatingScoring')}


                  </MDBox>

              </MDBox>
          )
      }
      return null;
  }
}

export default withTranslation("")(Calculating);