import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { t } from "i18next";
import moment from "moment";
import Trash from "app/assets/images/trash.png";
import SelectorUpDefault from "app/assets/images/selector-up-default.svg";
// import SelectorUpHover from 'app/assets/images/selector-up-hover.svg'
import SelectorUpSelected from "app/assets/images/selector-up-selected.svg";
import SelectorDownDefault from "app/assets/images/selector-down-default.svg";
import successIcon from 'app/assets/images/success_icon.png'
import blacklistIcon from 'app/assets/images/blacklist_icon.png'
import SelectorDownSelected from "app/assets/images/selector-down-selected.svg";
import { Keyword } from "app/types/Keyword";
import { GroupDropdown } from "../GroupCard/GroupDropdown";
import { GroupType } from "app/types/GroupType";

import "./DataTable.scss";

interface DictionaryTableProps {
  data: any;
  groups: GroupType[];
  changeSorting: (sorting: "DESC" | "ASC") => void;
  deleteRow: (id: number) => void;
  update: () => void;
}

const DictionaryTable = (props: DictionaryTableProps) => {
  const [sorting, setSorting] = React.useState<"DESC" | "ASC">("DESC");

  const handleChangeSort = () => {
    setSorting(sorting === "DESC" ? "ASC" : "DESC");
    props.changeSorting(sorting === "DESC" ? "ASC" : "DESC");
  };

  const deleteRow = (id: number) => {
    props.deleteRow(id);
  };

  const renderSortingToggle = () => {
    return (
      <div className="table-sorting-wrapper" onClick={handleChangeSort}>
        <img
          src={sorting === "ASC" ? SelectorUpSelected : SelectorUpDefault}
          alt="up"
        />
        <img
          src={sorting === "DESC" ? SelectorDownSelected : SelectorDownDefault}
          alt="down"
        />
      </div>
    );
  };

  return (
    <TableContainer component={Paper} className="data-table">
      <Table sx={{ minWidth: 650 }} size="medium">
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <div className="b-right">
                {t("dictionary.table.date")}
                {renderSortingToggle()}
              </div>
            </TableCell>
            <TableCell align="left">
              <div className="b-right">{t("dictionary.table.keyword")}</div>
            </TableCell>
            <TableCell align="left">
              <div className="b-right">{t("dictionary.table.list")}</div>
            </TableCell>
            <TableCell align="left">
              <div className="b-right">{t("dictionary.table.group")}</div>
            </TableCell>
            <TableCell align="left">
              <div>{t("dictionary.table.actions")}</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.length > 0 &&
            props.data.map((row: Keyword) => (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {moment(row.createdAt).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell sx={{ fontWeight: "normal" }} align="left">
                  {row.keyword}
                </TableCell>
                <TableCell sx={{ fontWeight: "normal" }} align="left">
                  {row.keyword_list_id === 1 ?
                    <span className="whitelist-text"><img src={successIcon} /> {t("dictionary.table.whitelist")}</span>
                    :
                    <span className="blacklist-text"><img src={blacklistIcon} />{t("dictionary.table.blacklist")} </span>
                  }
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "normal",
                    padding: "0 !important",
                    margin: "0 !important",
                  }}
                  align="left"
                >
                  <GroupDropdown
                    selected={row.group || null}
                    groups={props.groups}
                    dictionary={row}
                    changeGroup={props.update}
                  />
                </TableCell>
                <TableCell align="left">
                  <div className="social-link">
                    <img onClick={() => deleteRow(row.id)} src={Trash} />
                  </div>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DictionaryTable;
