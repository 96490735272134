import { Box } from "@mui/system";
import React, { useEffect } from "react";

interface Props {
  height?: (height: number) => void;
}

export default function BottomBar(props: Props) {
  const [height, setHeight] = React.useState(41);

  useEffect(() => {
  }, []);

  useEffect(() => {
    if (props.height) {
      props.height(height);
    }
  }, [height]);
    //print a bar at the bottom of the screen
  return (
    <Box sx={{
        position: "relative",
        display: "flex",
        flexDirection: "row",
        height: height,
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "var(--white)",
        borderTop: "0.5px solid var(--primary----blue-3)",
        zIndex: 1000,
    }}>
        <img src="/bottom-logo.svg" alt="logo" />
    </Box>
  );
}