import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from 'app/App'
import './index.css'
// Material Dashboard 2 PRO React TS Context Provider
import { MaterialUIControllerProvider } from 'materials/context'
import "./i18n/index";

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <App />
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
