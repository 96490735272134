import { Redirect, SignIn } from "app/auth";
import {
  Dashboard,
  Vips,
  Vip,
  Contacts,
  Connection,
  Contact,
  ScoringCriteria,
  Profile,
  Dictionary,
} from "app/pages";
import { NotFound } from "app/pages/error";
import { RoutesDefinitions } from "app/types/RoutesDefinitions";

const routes: RoutesDefinitions[] = [
  {
    path: "/",
    main: <Dashboard />,
    auth: true,
  },
  {
    path: "/vips",
    main: <Vips />,
    auth: true,
  },
  {
    path: "/vips/:id",
    main: <Vip />,
    auth: true,
  },
  //#region Auth
  {
    path: "/signin",
    main: <SignIn location={window.location.href} />,
    auth: false,
  },
  {
    path: "/redirect",
    main: <Redirect />,
    auth: false,
  },
  //#endregion
  // #region Errors
  {
    path: "/connection/:id",
    main: <Connection />,
    auth: true,
  },
  {
    path: "/contacts",
    main: <Contacts />,
    auth: true,
  },
  {
    path: "/contacts/:id",
    main: <Contact />,
    auth: true,
  },
  {
    path: "/profile",
    main: <Profile />,
    auth: true,
  },
  {
    path: "/scoring-criteria",
    main: <ScoringCriteria />,
    auth: true,
  },
  {
    path: "/dictionary",
    main: <Dictionary />,
    auth: true,
  },
  {
    path: "*",
    main: <NotFound />,
  },
];

export default routes;
