import Points from "app/assets/images/points.png";
import DictionaryProvider from "app/services/DictionaryProvider";
import useEntityStore from "app/stores/entities.store";
import { Entity } from "app/types/Entity";
import { t } from "i18next";
import { useEffect, useState } from "react";
import "./EntityDropdown.scss";
import SuccessIcon from "app/assets/images/success_icon.png";
import { ToastContainer, toast } from "react-toastify";
import AddIcon from "app/assets/images/addIcon.png";
import RemoveIcon from "app/assets/images/removeIcon.png";
import IgonoreIcon from "app/assets/images/ignoreIcon.png";
import React from "react";

const CustomToast = (text: string) => (
  <div>
    <p>{t(text)}</p>
  </div>
);

interface Props {
  id: number;
  dictionary: number;
  group_id: number;
  name: string;
  type: "dict" | "nlp";
  vipId: number;
  hidden: boolean;
  setOpen: (open: boolean) => void;
}

const EntityDropdown = (props: Props) => {
  const [type, setType] = useState(props.type);
  const [name, setName] = useState(props.name);
  const [id, setId] = useState(props.id);
  const [dictionary, setDictionary] = useState(props.dictionary);
  const [group_id, setGroup_id] = useState(props.group_id);
  const [vipId, setVipId] = useState(props.vipId);

  const [hide, setHide] = useState(props.hidden);
  const [showToast, setShowToast] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [top, setTop] = useState(0);
  const [right, setright] = useState(0);

  const { updateEntity } = useEntityStore();

  useEffect(() => {
    setType(props.type);
    setName(props.name);
    setId(props.id);
    setGroup_id(props.group_id);
    setDictionary(props.dictionary);
    setVipId(props.vipId);
  }, [
    props.type,
    props.name,
    props.id,
    props.group_id,
    props.dictionary,
    props.vipId,
  ]);

  useEffect(() => {
    setHide(props.hidden);
  }, [props.hidden]);

  const handleOpenModal = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const handleClickOutside = (event: any) => {
      if (event.clientX !== right && event.clientY !== top) {
        setOpenModal(false);
        document.removeEventListener("click", handleClickOutside);
      }
    };

    setOpenModal(true);

    setTimeout(
      () => document.addEventListener("click", handleClickOutside),
      200
    );
  };

  const deleteFromDictionary = async () => {
    const response = await DictionaryProvider.removeKeywords(dictionary);
    if (response[0] === "valid") {
      updateEntity(vipId);
      setOpenModal(false);
      setShowToast(<ToastContainer />);
      setTimeout(() => {
        setShowToast(null);
      }, 30000);
      toast.success(CustomToast("entities.dropdown.removed"), {
        icon: <img src={SuccessIcon} />,
      });
    }
  };

  const addToDictionaryRelevant = async () => {
    const response = await DictionaryProvider.saveKeywords({
      keyword: { keyword: name, group_id: group_id, keyword_list_id: 1, update: true },
    });
    if (response[0] === "valid") {
      updateEntity(vipId);
      setOpenModal(false);
      setShowToast(<ToastContainer />);
      setTimeout(() => {
        setShowToast(null);
      }, 30000);
      toast.success(CustomToast("entities.dropdown.added"), {
        icon: <img src={SuccessIcon} />,
      });
    }
  };

  const addToDictionaryIrrelevant = async () => {
    const response = await DictionaryProvider.saveKeywords({
      keyword: { keyword: name, group_id: group_id, keyword_list_id: 2, update: true },
    });
    if (response[0] === "valid") {
      updateEntity(vipId);
      setOpenModal(false);
      props.setOpen(false);
      setShowToast(<ToastContainer />);
      setTimeout(() => {
        setShowToast(null);
      }, 30000);
      toast.success(CustomToast("entities.dropdown.addedIgnored"), {
        icon: <img src={SuccessIcon} />,
      });
    }
  };

  return (
    <div onClick={(event) => event.stopPropagation()}>
      {showToast}
      <div
        className="entityContainerOptions"
        hidden={hide || openModal}
        onClick={handleOpenModal}
      >
        <img src={Points} alt="" />
      </div>
      <div
        key={id}
        className="entityContainerDropdownBackground"
        hidden={!openModal}
        onClick={() => setOpenModal(false)}
      ></div>
      <div
        className="entityContainerDropdown"
        hidden={!openModal}
        style={{ top: type == "nlp" ? "40px" : "20px" }}
      >
        <div
          id="add"
          className="entityContainerDropdownItem"
          hidden={type != "nlp"}
          style={{ color: "var(--gray---1)" }}
          onClick={() => addToDictionaryRelevant()}
        >
          <img src={AddIcon} alt="" />
          {t("entities.dropdown.add")}
        </div>
        <div
          id="add-ignored"
          className="entityContainerDropdownItem"
          style={{ color: "var(--gray---1)" }}
          hidden={type != "nlp"}
          onClick={() => addToDictionaryIrrelevant()}
        >
          <img src={IgonoreIcon} alt="" />
          {t("entities.dropdown.addIgnored")}
        </div>
        <div
          id="remove"
          className="entityContainerDropdownItem"
          style={{ color: "var(--black)" }}
          hidden={type != "dict"}
          onClick={() => deleteFromDictionary()}
        >
          <img src={RemoveIcon} alt="" />
          {t("entities.dropdown.delete")}
        </div>
      </div>
    </div>
  );
};

export default React.memo(EntityDropdown);
