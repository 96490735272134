import useEntityStore from "app/stores/entities.store";
import { Keyword } from "app/types/Keyword";
import { axios, url, header_options, checkStatus } from "./ProviderHelpers";


const getKeywords = async (
  skip: number = 0,
  sorting: "ASC" | "DESC",
  group_id: number,
  text: string
): Promise<any> => {
  try {
    const res = await axios.get(`${url}/dictionary`, {
      ...header_options,
      params: { skip, sorting, group_id, text },
    });
    return res.data;
  } catch (e: any) {
    await checkStatus(e.response);
  }
};

const getGroups = async (): Promise<any> => {
  try {
    const res = await axios.get(`${url}/dictionary/groups`, {
      ...header_options,
    });
    return res.data.data;
  } catch (e: any) {
    await checkStatus(e.response);
  }
};

const SaveKeywords = async (data: {
  keyword: { keyword: string; group_id: number; keyword_list_id: number, update?: boolean };
}) => {
  try {
    const res = await axios.post(
      `${url}/dictionary/add`,
      { ...data },
      { ...header_options }
    );

    if (res.data.data.item[1] && !res.data.data.item[2]) {
      return ["nonValid", res.data.data.item.keyword];
    }
    return ["valid", res.data];
  } catch (e: any) {
    await checkStatus(e.response);
    return ["nonValid", e.response.data];
  } 
};

const UpdateKeywords = async (
  id: number,
  keyword: {
    keyword: string;
    group_id?: number;
  }
) => {
 
  try {
    const res = await axios.put(
      `${url}/dictionary/update`,
      { keyword },
      { ...header_options }
    );
    return res.data;
  } catch (e: any) {
    await checkStatus(e.response);
    return ["nonValid", e.response.data];
  } 
  
};

const removeKeywords = async (id: number) => {
  try {
    const res = await axios.delete(`${url}/dictionary/delete/${id}`, {
      ...header_options,
    });
    return ["valid", res.data];
  } catch (e: any) {
    await checkStatus(e.response);
    return ["nonValid", e.response.data];
  }
};

class DictionaryProvider {
  
  public static getKeywords = getKeywords;
  public static getGroups = getGroups;
  public static saveKeywords = SaveKeywords;
  public static updateKeywords = UpdateKeywords;
  public static removeKeywords = removeKeywords;
}

export default DictionaryProvider;
