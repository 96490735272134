import { axios, url, header_options, checkStatus } from "./ProviderHelpers";

const getConnection = async (id: number) => {
  const userData = JSON.parse(sessionStorage.getItem("user"));
  try {
    const response = await axios.get(
      `${url}/connection/${id}?user_id=${userData.sub}`,
      {
        ...header_options,
      }
    );

    return response.data.data;
  } catch (error: any) {
    await checkStatus(error.response);
  }
};

const getInteractionsFromConnection = async (
  id: number,
  sorting: "ASC" | "DESC",
  skip: number = 0,
  interactions: string[] = [],
  directionality: string[] = [],
  date: string[] = []
) => {
  const userData = JSON.parse(sessionStorage.getItem("user"));
  try {
    let apiUrl: string = `${url}/interaction/${id}?sorting=${sorting}&skip=${skip}&user_id=${userData.sub}`;
    if (interactions.length > 0)
      apiUrl += `&interaction=${interactions.join(",")}`;
    if (directionality.length > 0)
      apiUrl += `&directionality=${directionality.join(",")}`;
    if (date.length > 0) apiUrl += `&date=${date.join(",")}`;
    const response = await axios.get(apiUrl, {
      ...header_options,
    });

    return response.data;
  } catch (error: any) {
    await checkStatus(error.response);
  }
};

const getConnectionByFromTo = async (from: number, to: number) => {
  try {
    const response = await axios.get(`${url}/connection/profiles`, {
      ...header_options,
      params: {
        from,
        to,
      },
    });

    return response.data.data;
  } catch (error: any) {
    await checkStatus(error.response);
  }
};

const getConnectionCategories = async (
  id: number,
  startDate?: string,
  endDate?: string
) => {
  try {
    const response = await axios.get(`${url}/connection/${id}/categories`, {
      ...header_options,
      params: {
        startDate,
        endDate,
      },
    });

    return response.data.data;
  } catch (error: any) {
    await checkStatus(error.response);
  }
};

const getConnectionsEntities = async (
  id: number,
  startDate?: string,
  endDate?: string
) => {
  try {
    const response = await axios.get(`${url}/connection/${id}/entities`, {
      ...header_options,
      params: {
        startDate,
        endDate,
      },
    });
    return response.data.data;
  } catch (error: any) {
    await checkStatus(error.response);
  }
};

class ConnectionProvider {
  public static getConnection = getConnection;
  public static getInteractionsFromConnection = getInteractionsFromConnection;
  public static getConnectionByFromTo = getConnectionByFromTo;
  public static getConnectionCategories = getConnectionCategories;
  public static getConnectionsEntities = getConnectionsEntities;
}

export default ConnectionProvider;
