import { axios, checkStatus, header_options, url } from "./ProviderHelpers";


const getNetworkAll = async () => {
  try {
    const response = await axios.get(`${url}/network`, {
      ...header_options,
    });

    return response.data.data;
  } catch (error: any) {
    await checkStatus(error.response);
  }
};

const getNetwork = async (id: string) => {
  try {
    const response = await axios.get(`${url}/network/${id}`, {
      ...header_options,
    });
    return response.data.data;
  } catch (error: any) {
    if (error.response.status === 504) {

      setTimeout(async () => {
        const response = await axios.get(`${url}/network/${id}`, {
          ...header_options,
        });
        return response.data.data;
      }, 2000);
    }
    await checkStatus(error.response);
  }
};

class NetworkProvider {
  static getAll = getNetworkAll;
  static get = getNetwork;
}

export default NetworkProvider;
