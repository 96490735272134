import { DownOutlined, MinusOutlined } from "@ant-design/icons";
import { Box, Grid, Pagination, Paper, Tab, Tabs } from "@mui/material";
import { Button, DatePicker, Tooltip } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import Info from "app/assets/images/info.png";
import CategoryTopics from "app/components/CategoryTopics/CategoryTopics";
import EntityDoughnut from "app/components/EntityDoughnut/EntityDoughnut";
import EntityList from "app/components/EntityTopics/EntityList";
import GoBackButton from "app/components/GoBackButton/GoBackButton";
import Calculating from "app/components/Load/Calculating";
import PersonCardContact from "app/components/PersonCardContact/PersonCardContact";
import PersonCardDetail from "app/components/PersonCardDetail/PersonCardDetail";
import SearchBar from "app/components/SearchBar/SearchBar";
import TabPanel from "app/components/TabPanel/TabPanel";
import TopNavbar from "app/components/TopNavbar/TopNavbar";
import ParametersProvider from "app/services/ParametersProvider";
import PersonProvider from "app/services/PersonProvider";
import { Status } from "app/types/Status";
import dayjs from "dayjs";
import moment from "moment";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "./Contact.scss";

const { RangePicker } = DatePicker;
let enabledDateFilters: string[] = [
  moment('2023-01-01').format("DD/MM/YYYY"),
  moment().format("DD/MM/YYYY"),
];

const disabledDate: RangePickerProps["disabledDate"] = (current) => {
  // Can not select days before today
  return current && current > dayjs().endOf("day");
};

class Contact extends React.Component<
  WithTranslation,
  {
    id: number;
    contact: any;
    contactData: any;
    categories: any;
    entities: any;
    count: number;
    menuTab: number;
    page: number;
    memberCount: number;
    dataAvailable: boolean;
    search: string;    
    openDateFilter: boolean;
    startDate: string;
    endDate: string;
  }
> {
  constructor(props: any) {
    super(props);
    const id = window.location.pathname.split("/").pop();

    this.state = {
      id: parseInt(id),
      contact: null,
      contactData: null,
      categories: null,
      entities: null,
      page: 1,
      count: 0,
      menuTab: 0,
      memberCount: 0,
      dataAvailable: true,
      search: "",
      openDateFilter: false,
      startDate: "",
      endDate: "",
    };
  }

  componentDidMount() {
    enabledDateFilters = [
      moment('2023-01-01').format("DD/MM/YYYY"),
      moment().format("DD/MM/YYYY"),
    ];
    this.setState({
      startDate: enabledDateFilters[0]?.split("/").reverse().join("-") || undefined,
      endDate: enabledDateFilters[1]?.split("/").reverse().join("-") || undefined,
    })
    this.getContactDetails();
    this.getContactEntitiesCategories();
  }


  handlePageChange = (event: any, value: number) => {
    this.setState({ page: value }, () => {
      this.getContactDetails();
    });
  };

  handleMenuChange = (event: React.SyntheticEvent, newValue: number) => {
    this.setState({ menuTab: newValue });
  };

  getContactEntitiesCategories = async () => {
    if (enabledDateFilters.length === 0 || enabledDateFilters[0] == undefined || enabledDateFilters[1] == undefined) {
      this.setState({
        dataAvailable: true,
      });
      return
    }
    const startDate = enabledDateFilters[0].split("/").reverse().join("-")
    const endDate = enabledDateFilters[1].split("/").reverse().join("-")
    const result = await PersonProvider.getContactEntitiesCategories(
      this.state.id,
      startDate,
      endDate
    );
    if (result?.category?.length === 0) {
      result.category = [];
    }
    this.setState({
      categories: result?.category || null,
      dataAvailable: true,
      startDate: startDate,
      endDate: endDate,
    });
  };

  getContactDetails = async (text = "") => {
    const { page } = this.state;
    let user = JSON.parse(sessionStorage.user);
    let skip = page === 1 ? 0 : (page - 1) * 12;
    await this.getStatus();
    const contact = await PersonProvider.getContact(
      this.state.id,
      user.sub,
      skip,
      text
    );
    let count = contact.memberCount / 12;
    count = count > 1 ? Math.trunc(count) + 1 : 1;
    if (text !== "") {
      this.setState({
        contactData: contact.data?.person_to,
        count: count,
        memberCount: contact.memberCount,
      });
    } else {
      this.setState({
        contact: contact.data,
        contactData: contact.data?.person_to,
        count: count,
        memberCount: contact.memberCount,
      });
    }
  };

  getStatus = async () => {
    return new Promise((resolve, reject) =>
      ParametersProvider.getStatus().then((status) => {
        if (status.findIndex((s: Status) => !s.score_available) < 0) {
          this.setState({ dataAvailable: true });
          resolve(true);
        } else {
          this.setState({ dataAvailable: false });
          setTimeout(() => {
            this.getStatus().then(() => resolve(true));
          }, 5000);
        }
      })
    );
  };

  handleDateFilterClick = () => {
    this.setState(
      {
        dataAvailable: false,
        openDateFilter: false,
      },
      () => {
        this.getContactEntitiesCategories();
      }
    );
  };

  handleOnChangeDateFilter = (date: any, dateString: any) => {
    if (dateString.includes("")) {
      enabledDateFilters = [];
    } else {
      enabledDateFilters = dateString;
    }
  };

  handleRemoveDateFilter = () => {
    enabledDateFilters = [];
    this.setState(
      {
        dataAvailable: false,
        openDateFilter: false,
      },
      () => {
        this.getContactEntitiesCategories();
      }
    );
  };



  handleSetSearch = (query: string) => {
    this.setState({ search: query });
  };

  render() {
    return (
      <>
        <TopNavbar />
        <div className="card-contact-detail">
          <div className="container pt-4">
            <GoBackButton />
            <Grid className="grid-profile">
              {this.state.contact ? (
                <PersonCardDetail
                  person={this.state.contact}
                  isVip={false}
                  isShareIcons={true}
                  showContacts={true}
                  isSaveContact={true}
                  getContactDetails={this.getContactDetails}
                  memberCount={this.state.memberCount}
                />
              ) : null}
            </Grid>
            {this.state.dataAvailable ? (
              <>
                <Grid className="grid-tabs">
                  <Box
                    className="menutabs-container"
                    sx={{
                      display: "flex",
                      borderBottom: 1,
                      borderColor: "#EBEBF4",
                    }}
                  >
                    <Tabs
                      value={this.state.menuTab}
                      onChange={this.handleMenuChange}
                    >
                      <Tab label={this.props.t("general.vipsList")} />
                      <Tab label={this.props.t("general.hotTopics")} />
                    </Tabs>
                  </Box>
                </Grid>
                <TabPanel value={this.state.menuTab} index={0}>
                  <Grid className="mb-2 mt-4">
                    <div className="d-flex justify-content-start">
                      <SearchBar
                        isSmall={true}
                        getData={this.getContactDetails}
                        search={this.handleSetSearch}
                      />
                    </div>
                  </Grid>
                  <Grid container className="grid-table" spacing={2}>
                    {this.state.contact && this.state.contactData ? (
                      <>
                        {this.state.contactData.map((contact: any) => (
                          <Grid
                            item
                            xs={3}
                            className="contact-list"
                            sx={{ height: "100%" }}
                          >
                            <PersonCardContact
                              person={contact.from_person}
                              score={contact.score}
                              interactionNumber={contact.interactionNumber}
                              connectionId={contact.id}
                              isVip={true}
                            />
                          </Grid>
                        ))}
                      </>
                    ) : null}
                  </Grid>
                  <div className="table-pagination pb-4">
                    {this.state.contact && this.state.contactData ? (
                      <Pagination
                        count={this.state.count}
                        page={this.state.page}
                        onChange={this.handlePageChange}
                        variant="outlined"
                        shape="circular"
                      />
                    ) : null}
                  </div>
                </TabPanel>
                <TabPanel value={this.state.menuTab} index={1}>
                  <Grid container spacing={2} sx={{ marginBottom: "2em" }}>
                  <Grid item xs={12} sx={{position: "relative"}}>
                      <RangePicker
                        size="large"
                        separator={<MinusOutlined />}
                        suffixIcon={<DownOutlined />}
                        disabledDate={disabledDate}
                        open={this.state.openDateFilter}
                        onFocus={() => this.setState({ openDateFilter: true })}
                        onBlur={() => this.setState({ openDateFilter: false })}
                        onKeyDown={() =>
                          this.setState({ openDateFilter: false })
                        }
                        value={[
                          enabledDateFilters[0]
                            ? dayjs(enabledDateFilters[0], "DD/MM/YYYY")
                            : dayjs('2023-01-01'),
                          enabledDateFilters[1]
                            ? dayjs(enabledDateFilters[1], "DD/MM/YYYY")
                            : dayjs(),
                        ]}
                        renderExtraFooter={() => (
                          <div
                            style={{
                              float: "right",
                              marginRight: "0.5em",
                              paddingBottom: "0.5em",
                              fontSize: "13px",
                              marginTop: "0.5em",
                            }}
                            className="filter-date-container"
                          >
                            <Button
                              className="ant-cancel-button"
                              onClick={() => this.handleRemoveDateFilter()}
                            >
                              {this.props.t("general.cancel")}
                            </Button>
                            <Button
                              onClick={() => this.handleDateFilterClick()}
                              className="ant-apply-button"
                              disabled={enabledDateFilters.length === 0}
                            >
                              {this.props.t("interactionData.apply")}
                            </Button>
                          </div>
                        )}
                        format={"DD/MM/YYYY"}
                        onChange={this.handleOnChangeDateFilter}
                        style={{
                          float: "right",
                          marginBottom: "1em",
                          position: "absolute",
                          zIndex: 1,
                          right: "0px",
                          top: "28px",
                        }}
                      />
                      <CategoryTopics data={this.state.categories} />
                    </Grid>
                    <Grid item xs={8}>
                      <div className="entityTitle">
                        {this.props.t("general.entityTitle")}{" "}
                        <Tooltip
                          className="ml-2 mt-auto mb-auto"
                          overlayStyle={{ maxWidth: "325px" }}
                          placement="right"
                          title={this.props.t("entities.tooltip")}
                        >
                          <img src={Info} />
                        </Tooltip>
                      </div>
                      <EntityList
                        vipId={this.state.id}
                        startDate={this.state.startDate || null}
                        endDate={this.state.endDate || null}
                      />
                    </Grid>
                    <Grid item xs={4} className="grid-charts" >
                      <Paper className="mt-4">
                        <EntityDoughnut vipId={this.state.id} />
                      </Paper>
                    </Grid>
                  </Grid>
                </TabPanel>
              </>
            ) : (
              <Calculating active={!this.state.dataAvailable} />
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation("")(Contact);
