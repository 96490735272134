import { axios, url, header_options, checkStatus } from "./ProviderHelpers";
import { GroupType } from "../types/GroupType";

const getGroups = async (): Promise<GroupType[]> => {
  try {
    const res = await axios.get(`${url}/dictionary/groups`, {
      ...header_options,
    });
    if (res.data) {
      return res.data.data;
    }
    return [];
  } catch (e: any) {
    await checkStatus(e.response);
    return [];
  }
};

const saveGroup = async (group: GroupType) => {
  try {
    const res = await axios.post(
      `${url}/dictionary/group`,
       group ,
      { ...header_options }
    );
    return [res.data.data, "valid"] ;
  } catch (e: any) {
    await checkStatus(e.response);
    return [null, "notValid"];
  }
};

const updateGroup = async (group: GroupType) => {
    try {
        const res = await axios.put(
            `${url}/dictionary/group/${group.id}`,
            group ,
            { ...header_options }
        );
        return [res.data.data, "valid"];
    } catch (e: any) {
        await checkStatus(e.response);
        return [null, "notValid"];
    }
};

const removeGroup = async (group: GroupType) => {
  try {
    const res = await axios.delete(
      `${url}/dictionary/group/${group.id}`,
      { ...header_options }
    );
    return [res.data.data, "valid"];
  } catch (e: any) {
      await checkStatus(e.response);
      return [null, "notValid"];
  }
};

class GroupProvider {
  public static getGroups = getGroups;
  public static saveGroup = saveGroup;
  public static updateGroup = updateGroup;
  public static removeGroup = removeGroup;
}

export default GroupProvider;
