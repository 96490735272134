import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import "./InvalidJSONFormatModal.scss";
import AlertIcon from "app/assets/images/alert_icon.svg";

class InvalidJSONFormatModal extends React.Component<WithTranslation> {
    handleRefreshClick = () => {
        window.location.reload();
    }

    render() {
      const { t } = this.props;
  
      return (
        <div className="invalid-format-modal">
            <div className="invalid-format-card">
                <div className="invalid-format-card__icon">
                    <img src={AlertIcon} alt="Alert Icon" />
                </div>
                <div className="invalid-format-card__title">
                    <span>{t("invalidJSONFormatModal.title")}</span>
                </div>
                <div className="invalid-format-card__text">
                    <span>{t("invalidJSONFormatModal.text")}</span>
                </div>
                <button 
                    className="invalid-format-card__button" 
                    onClick={this.handleRefreshClick}
                >
                    <span className="invalid-format-card__button-text">{t("invalidJSONFormatModal.button")}</span>
                </button>
            </div>
        </div>
        
      );
    }
  }
  
  export default withTranslation("")(InvalidJSONFormatModal);