import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Modal,
  Box
} from '@mui/material'
import { useState, useEffect } from 'react'
import './PersonCard.scss'
import avatarVip from 'app/assets/images/avatar_vip.png'
import avatarContact from 'app/assets/images/contact_avatar.png'
import twitter from 'app/assets/images/twitter_logo.png'
import medalVip from 'app/assets/images/medal.png'
import medalContact from 'app/assets/images/contact_medal.png'
import contactsIcon from 'app/assets/images/contact_icon.png' 
import medalIcon from 'app/assets/images/medal_icon.png'
import { Link } from "react-router-dom";
import { t } from 'i18next';
import UserProvider from "app/services/UserProvider"
import ModalUnsaveContact from '../ModalUnsaveContact/ModalUnsaveContact'
import { Dropdown, MenuProps, Space } from 'antd';
import vipIcon from 'app/assets/images/vip_icon.png'
import starIcon from 'app/assets/images/star_icon.png'
import trashIcon from 'app/assets/images/trash_icon.png'
import ModalDeleteVip from '../ModalDeleteVip/ModalDeleteVip'
import PersonForm from '../PersonForm/PersonForm'
import PersonProvider from 'app/services/PersonProvider'
import { toast } from 'react-toastify';
import {useNavigate} from 'react-router-dom';


const PersonCard = ({ person, isVip, showAddFavourite, reloadContacts }: any) => { 
  const [openModal, setOpenModal] = useState(false);
  const [openVipModal, setOpenVipModal] = useState(false);
  const [openAddVipModal, setOpenAddVipModal] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(100);
  const zoomIsActive = zoomLevel >= 145
  const maxNameLength = zoomLevel >= 145 ? 25 : 100;
  const navigate = useNavigate();

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#fff',
    border: '1px solid #EBEBF4',
    boxShadow: '1px 1px 20px rgba(195, 196, 207, 0.5)',
    borderRadius: '8px'
  };

  const handleClose = () => {
    setOpenModal(false)
  };
  const handleOpen = () => {
    setOpenModal(true)
  };

  const handleVipClose = () => {
    setOpenVipModal(false)
  };
  const handleVipOpen = () => {
    setOpenVipModal(true)
  };

  useEffect(() => {
    const detectZoom = () => {
      const calculatedZoom = (window.outerWidth / window.innerWidth) * 100;
      setZoomLevel(calculatedZoom);
    };
  
    detectZoom();
    window.addEventListener('resize', detectZoom);
  
    return () => {
      window.removeEventListener('resize', detectZoom);
    };
  }, []);

  const handleSubmitAddVip = async (values: any) => {
    let user = JSON.parse(sessionStorage.user)
    const result = await PersonProvider.postCreateVip(values, person.id, user.sub)
    
    if (result.result == "success"){
      const newVip : string = person.id;
      navigate('/vips', { state: {itemId: newVip}})
    }
    else {
      result.result == "error" ? toast.error(t('addVip.toast.error')) : toast.error(t('dictionary.toast.error'))
    }
  }

  const handleSaveContact = async () => {
    let user = JSON.parse(sessionStorage.user);
    await UserProvider.putUserFavourite(user.sub, person.id)
    reloadContacts()
    handleClose()
  }

  const handleDeleteVip = async () => {
    const result = await PersonProvider.deleteVip(person.id)
    
    if (result.result == "success"){
      navigate('/vips', { state: { vipRemoved: true } })
      window.location.reload()
    }
    else {
      toast.error(t('removeVip.toast.error'))
    }
  }

  const items: MenuProps['items'] =
    isVip ?
      [
        {
          label:
            <Button onClick={(e) => { handleVipOpen(); e.preventDefault() }} className="action-button" >
              <img style={{ marginRight: "1em" }} src={trashIcon} alt="trash" />
              {t("general.deleteVip")}
            </Button>,
          key: '0',
        }
      ]
      :
      [
        {
          label:
            <Button onClick={(e) => { handleOpen(); e.preventDefault() }} className="action-button"  >
              <img style={{ marginRight: "1em" }} src={starIcon} alt="star" />
              {person.favourites?.length === 0 ? t("general.saveContact") : t("general.unsaveContact")}
            </Button>,
          key: '0',
        },
        {
          label:
            <Button onClick={(e)=> {setOpenAddVipModal(true); e.preventDefault()} } className="action-button" >
              <img style={{ marginRight: "1em" }} src={vipIcon} alt="add vip" />
              {t("general.addVip")}
            </Button>,
          key: '1',
        }
      ];
  
  const profile_image = person.avatar.indexOf("jpg") > -1 || person.avatar.indexOf("png") > -1 || person.avatar.indexOf("jpeg") > -1   ? person.avatar : (isVip ? avatarVip : avatarContact)
  return (
    <>
    <Card sx={{ maxWidth: 345, height: "100%" }} className={`card-container ${isVip ? "card-vip" : "card-contact"}`}>
      <Link to={isVip ? `/vips/${person.id}` : `/contacts/${person.id}`} className="link" >
        <div style={{ position:"relative", width: "100%" }}>
          <CardMedia
            component="img"
            height="100"
            image={profile_image}
            alt="avatar vip"
            className='avatar-image'
          />
          {isVip ?
            <img src={medalVip} className="medal-image" alt="vip medal" />
            :
            <img src={medalContact} className="contact-image" alt="contact medal" />
          }
          <Dropdown className="filters-dropdown" menu={{ items }} trigger={['click']} >
            <a onClick={(e) => e.preventDefault()} style={{ position: "absolute", top: 0, right: 0, border: "1px solid #EBEBF4", background: "#fff ", padding: 0, zIndex: 1 }} >
              <Space>
                ···
              </Space>
            </a>
          </Dropdown>
        </div>
        <CardContent className='text-center pb-2'>
        {zoomIsActive ?
          <Typography gutterBottom variant="subtitle1" component="div">
            {person.location ? person.location.length <= maxNameLength ? person.location : person.location.slice(0, maxNameLength - 3) + '...' : '-'} 
            <br />
            {person.work_area ? person.work_area.length <= maxNameLength ? person.work_area : person.work_area.slice(0, maxNameLength - 3) + '...' : '-'}
          </Typography>
        :
          <Typography gutterBottom variant="subtitle1" component="div">
            {person.location ? person.location : "-"} | {person.work_area ? person.work_area : "-"}
          </Typography>
        }
          <Typography gutterBottom variant="h2" component="div">
            {person.name}
          </Typography>
          <Typography gutterBottom variant="subtitle1" component="div" className='contacts-number'>
            <img src={isVip ? contactsIcon : medalIcon} className="mr-2" alt="medal" />
            &nbsp; { isVip ? person.person_from.length : person.person_to.length}
            &nbsp;{isVip ? t("general.contacts") : t("general.vip")}
          </Typography>
        </CardContent>
      </Link>
      <CardActions>
        {person.person_social && person.person_social.length > 0 ?
          <a href={person.mediaUrl ? person.mediaUrl : `https://twitter.com/${person.person_social[0]?.username}`} target="blank" ><img src={twitter} alt="twitter" /></a>
          : null
        }
      </CardActions>
    </Card>
    { showAddFavourite &&
      <ModalUnsaveContact person={person} handleSaveContact={handleSaveContact} openModal={openModal} handleClose={handleClose}  />
    }
    { isVip &&
      <ModalDeleteVip handleConfirm={handleDeleteVip} openModal={openVipModal} handleClose={handleVipClose}  />
    }
    <Modal
      open={openAddVipModal}
      onClose={() => setOpenAddVipModal(false)}
    >
      <Box sx={style}>
        <PersonForm handleSubmit={handleSubmitAddVip} handleClose={() => setOpenAddVipModal(false)} data={person} />
      </Box>
    </Modal>
    </>
  );
}

export default PersonCard;
