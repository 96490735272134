import { t } from 'i18next';
import {
    Typography,
    Button,
    IconButton,
    OutlinedInput,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Select from "react-select";
import PersonProvider from 'app/services/PersonProvider';
import vipIcon from 'app/assets/images/vip.png'
import "./PersonForm.scss"
import { useEffect, useState } from 'react';


const PersonForm = (props: any) => {
    let {handleSubmit, handleClose, data} = props;
    const [areas, setAreas] = useState([])
    const regex = /^@.*$/;

    const SignupSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required(t("personForm.requiredField")),
        twitter: Yup.string()
            .min(1, 'Too Short!')
            .max(50, 'Too Long!')
            .matches(regex, t("personForm.twitterField")),
        linkedin: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!'),
        geographicalArea: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!'),
        workArea: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!'),
    });

    const colourStyles = {
        control: (styles: any) => ({ ...styles, backgroundColor: 'white' }),
        option: (styles: any) => {
          return {
            ...styles,
            backgroundColor: '#F9F9FA',
            color: '#535564'
          }
        },
    };

    const fetchGeographicalAreas = async () => {
        let geographicalAreas = await PersonProvider.getGeographicalAreas();
        setAreas(geographicalAreas)
    }

    useEffect(() => {
        fetchGeographicalAreas()
    }, [])


    return (
        <div className='p-4 person-form'>
            <Typography className='title' >
                <img className='vip-color' src={vipIcon} /> &nbsp;{t("personForm.title")}
            </Typography>
            <Typography className='text mb-2' >
                {t("personForm.text1")}<br/>{t("personForm.text2")}
            </Typography>
            <IconButton sx={{position: "absolute", right: 0, top: 0, color: "#3F4253"}} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <Formik
                initialValues={{
                    name: data?.name ? data?.name : '',
                    twitter: data?.social ? "@" + data.social : data?.person_social ? "@" + data?.person_social[0].username : "" ,
                    linkedin: '',
                    location: data?.location ? data?.location : '',
                    work_area: data?.work_area ? data?.work_area : ''
                }}
                validationSchema={SignupSchema}
                onSubmit={async values => {
                    if (values.twitter){
                        values.twitter = values.twitter.replace('@', '')
                    }
                    handleSubmit(values)                    
                }}
            >
                {({
                    handleChange,
                    handleBlur,
                    values,
                    errors,
                    touched
                }) => (
                    <Form>
                        <Typography className='text' >{t("personForm.name")} *</Typography>
                        <Field value={values.name} name="name" className={`mb-2 input ${errors.name && touched.name ? "input-error" : ""}`} />
                        {errors.name && touched.name ? (
                            <div className='field-error mb-2'>{errors.name}</div>
                        ) : null}

                        <Typography className='text'>{t("personForm.twitter")}</Typography>
                        <Field value={values.twitter} name="twitter" placeholder="@user" className={`mb-2 input ${errors.twitter && touched.twitter ? "input-error" : ""}`} />
                        {errors.twitter && touched.twitter ? (
                            <div className='field-error mb-2'>{errors.twitter}</div>
                        ) : null}

                        <Typography className='text'>{t("personForm.linkedin")}</Typography>
                        <Field value={values.linkedin} name="linkedin" className='mb-2 input' />
                        {errors.linkedin && touched.linkedin ?
                            <div>{errors.linkedin}</div>
                            : null}

                        <Typography className='text'>{t("personForm.geographicalArea")}</Typography>
                        <Select
                            className='selector mb-2'
                            getOptionLabel={(option) => option.name}
                            onChange={selectedOption => {
                                let event = { target: { name: 'location', value: selectedOption.name } }
                                handleChange(event)
                            }}
                            onBlur={() => {
                                handleBlur({ target: { name: 'location' } });
                            }}
                            isSearchable={true}
                            options={areas}
                            placeholder=""
                            name="location"
                            styles={colourStyles}
                            isLoading={false}
                        />
                        {errors.location && touched.location ?
                            <div>{errors.location}</div>
                        : null}

                        <Typography className='text'>{t("personForm.workArea")}</Typography>
                        <Field name="work_area" className='mb-2 input' />
                        {errors.work_area && touched.work_area ? (
                            <div>{errors.work_area}</div>
                        ) : null}
                        <Button type="submit" className='button mt-2' >{t("personForm.save")}</Button>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default PersonForm;