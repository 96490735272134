import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Parameters } from "app/types/Parameters";
import {
  Accordion,
  AccordionSummary,
  Typography,
  FormControlLabel,
  Switch,
  AccordionDetails,
  Box,
  Slider,
  Chip,
  Input,
} from "@mui/material";
import { color } from "../../helpers/ColorAcent";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import CachedIcon from '@mui/icons-material/Cached';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IllustrationScoring from "app/assets/images/Illustration-scoring.png"
import "./CriteriaLabel.scss";
import { styled } from '@mui/material/styles';


class CriteriaOptions extends React.Component<
  WithTranslation & {
    parameter: Parameters;
    colorid: number;
    dataChange: Function;
  },
  {
    oldparameter: Parameters;
    parameter: Parameters;
    range1: number;
    range2: number[];
    range3: number;
    displayHover: boolean;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      oldparameter: props.parameter,
      parameter: props.parameter,
      range1: props.parameter.range1max,
      range2: [props.parameter.range1max + 1, props.parameter.range2max],
      range3: props.parameter.range2max + 1,
      displayHover: true,
    };
  }

  componentDidMount(): void {
    //this.emitdataChange = debounce(this.emitdataChange, 200);
  }

  handlePoints1 = async (event: any, newValue: number | number[]) => {
    const value =
      (newValue as number) > 1
        ? (newValue as number) < this.state.range2[1]
          ? (newValue as number)
          : this.state.range1
        : 1;
    this.setState({
      range1: value < this.state.range2[1] ? value : this.state.range2[1] - 1,
      range2: [value + 1, this.state.range3 - 1],
    });
  };

  handleChangePoints1 = async (newValue: number) => {
    this.setState({
      parameter: {
        ...this.state.parameter,
        points1: newValue > 0 ? newValue < 150 ? newValue : 150 : 0,
      },
    });
  };

  handlePoints2 = async (event: any, newValue: number | number[]) => {
    this.setState({
      range1: (newValue as number[])[0] - 1 > 1 ? (newValue as number[])[0] - 1 : 1,
      range2: [(newValue as number[])[0] > 2 ? (newValue as number[])[0] : 2, (newValue as number[])[1] < (this.state.parameter.maxRange - 1) ? (newValue as number[])[1] > 2 ? (newValue as number[])[1] : 2 : (this.state.parameter.maxRange - 1)],
      range3: (newValue as number[])[1] + 1,
    });
  };

  handleChangePoints2 = async (newValue: number) => {
    this.setState({
      parameter: {
        ...this.state.parameter,
        points2: newValue > 0 ? newValue < 150 ? newValue : 150 : 0,
      },
    });
  };

  handlePoints3 = async (event: any, newValue: number | number[]) => {
    const value =
      (newValue as number)

    this.setState({
      range2: [this.state.range2[0], value - 1 < this.state.range2[0] ? this.state.range2[0] : value - 1],
      range3: value > this.state.range2[0] ? value : this.state.range2[0] + 1,
    });
  };

  handleChangePoints3 = async (newValue: number) => {
    this.setState({
      parameter: {
        ...this.state.parameter,
        points3: newValue > 0 ? newValue < 150 ? newValue : 150 : 0,
      },
    });
  };

  componentDidUpdate(
    prevProps: Readonly<
      WithTranslation<"translation"> & {
        parameter: Parameters;
        colorid: number;
        dataChange: Function;
      }
    >,
    prevState: Readonly<{
      parameter: Parameters;
      range1: number;
      range2: number[];
      range3: number;
    }>,
    snapshot?: any
  ): void {
    if (prevProps.parameter !== this.props.parameter) {
      this.setState({
        parameter: this.props.parameter,
        range1: this.props.parameter.range1max,
        range2: [this.props.parameter.range1max+1, this.props.parameter.range2max],
        range3: this.props.parameter.range2max+1,
        displayHover: true,
      });
    }

    if (prevProps.parameter !== this.props.parameter) {
      this.setState({
        parameter: this.props.parameter,
      });
    }

    if (
      prevState.parameter !== this.state.parameter ||
      prevState.range2 !== this.state.range2 
    ) {
      this.emitdataChange();
    }
  }

  emitdataChange = () => {
    const _parameter = {
      ...this.state.parameter,
      range1max: this.state.range1,
      range2max: this.state.range2[1],
    };
    this.props.dataChange(_parameter);
  };

  handleChangeDisabled = (event: any) => {
    this.setState({
      parameter: {
        ...this.state.parameter,
        disabled: !event.target.checked,
      },
    });
  };

  handleRestoreOldParams = () => {
    this.setState({
      parameter: this.state.oldparameter,
      range1: this.state.oldparameter.range1max,
      range2: [this.state.oldparameter.range1max + 1, this.state.oldparameter.range2max],
      range3: this.state.oldparameter.range2max + 1,
    });
  }

  handleAccordionChange = (event: React.SyntheticEvent, expanded: boolean) => {
    this.setState({
      displayHover: expanded
    })
  }

  MaterialUISwitch = styled(Switch)(() => ({
    '.Mui-checked+.MuiSwitch-track': {
      backgroundColor: '#2EA0B2!important',
      border: "none"
    },
  }));

  render() {
    const parameter = this.props.parameter;
    const colors = color(this.props.colorid);


    return (
      <>
        <Accordion
          sx={{
            color: "var(--primary----dark-blue)",
            marginY: "0.2rem",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "8px",
            boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.15)",
            backgroundColor: "var(--white)",
            borderColor: "transparent !important",
            border: 0,
          }}
          onChange={this.handleAccordionChange}
        >
          <AccordionSummary
            sx={{
              minHeight: "5rem",
            }}
            expandIcon={<ExpandMoreIcon sx={{background: "#EBEBF4", borderRadius: "5px", color: "#85879C"}} />}
          >

            <Chip
              label={parameter.label}
              sx={{
                color: `#18B19D`,
                backgroundColor: `var(${colors.backgroundColor})`,
                border: "1px solid #18B19D",
                textTransform: "uppercase",
                borderRadius: "5px"
              }}
            />
            <Typography
              sx={{
                marginLeft: "1.2rem",
                marginRight: "auto",
                marginY: "auto",
                fontWeight: 400,
                fontSize: "1rem",
              }}
            >
              {parameter.name}
            </Typography>
            <FormControlLabel
              sx={{
                marginLeft: "auto",
                marginRight: "1rem",
                color: "#85879C",
                fontWeight: 400
              }}
              onClick={(event) => event.stopPropagation()}
              control={
                <this.MaterialUISwitch
                  checked={!this.state.parameter.disabled}
                  sx={{
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "18px",
                    color: "#2EA0B2"
                  }}                  
                  onChange={this.handleChangeDisabled}
                />
              }
              label={
                !this.state.parameter.disabled
                  ? this.props.t(
                    "adminPanel.criteriaManagement.criterias.enabled"
                  )
                  : this.props.t(
                    "adminPanel.criteriaManagement.criterias.disabled"
                  )
              }
              labelPlacement="start"
            />            
          </AccordionSummary>
          <AccordionDetails
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <Box sx={{
              width: '100%',
              backgroundColor: '#E6F4F6',
              minHeight: '100px',
              padding: '1rem',
              color: '#3F4253',
              borderRadius: '4px',
              justifyContent: 'flex-start',
              alignItems: 'center',
              position: 'relative',
              display: this.state.displayHover ? 'flex' : 'none'
            }} >
              <CloseIcon
                onClick={() => this.setState({ displayHover: false })}
                sx={{
                  position: 'absolute',
                  top: '0.5rem',
                  right: '0.5rem',
                  color: '#6C7580',
                  cursor: "pointer"
                }}
              />
              <img src={IllustrationScoring} alt='img' />
              <Box sx={{
                color: 'var(--primary----blue)',
                fontSize: '14px',
                marginLeft: '1rem'
              }}>
                <Typography sx={{ fontWeight: '600', fontSize: '14px', lineHeight: 1.3 }} >{this.props.t('adminPanel.criteriaManagement.criterias.howTo')}</Typography>
                <Typography sx={{ fontSize: '14px', lineHeight: 1.3 }}>{this.props.t('adminPanel.criteriaManagement.criterias.explanation1')}</Typography>
                <Typography sx={{ fontSize: '14px', lineHeight: 1.3 }}>{this.props.t('adminPanel.criteriaManagement.criterias.explanation2')}</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                border: "1px solid #EBEBF4",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                height: "5rem",
                borderRadius: '4px',
                margin: '1rem'
              }}
            >
              <Typography
                sx={{
                  color: "#3F4253",
                  fontSize: "14px",
                  lineHeight: "18px",
                  marginY: "auto",
                  fontWeight: 400,
                }}
              >
                {this.props.t(
                  "adminPanel.criteriaManagement.criterias.pointsRange"
                )}{" "}
                1
              </Typography>
              <Box
                sx={{
                  width: "5rem",
                  height: "2rem",
                  backgroundColor: "#EBEBF4",
                  borderRadius: "0.3rem",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  paddingLeft: "0.5rem",
                  alignItems: "center",
                }}
              >
                <Input
                  disableUnderline={true}
                  type="number"
                  sx={{ color: "#2EA0B2", fontWeight: "600" }}
                  value={Number(this.state.parameter.points1).toString()}
                  onChange={(event) =>
                    this.handleChangePoints1(Number(event.target.value))
                  }
                />
                <RemoveCircleOutlineIcon
                  sx={{
                    marginLeft: "auto",
                    fontSize: "1rem",
                    cursor: "pointer",
                    fill: "#2EA0B2",
                    background: "#fff",
                    borderRadius: "50%"
                  }}
                  onClick={() =>
                    this.handleChangePoints1(this.state.parameter.points1 - 1)
                  }
                />
                <AddCircleOutlineRoundedIcon
                  sx={{
                    marginX: "0.3rem",
                    fontSize: "1rem",
                    cursor: "pointer",
                    fill: "#2EA0B2",
                    background: "#fff",
                    borderRadius: "50%"
                  }}
                  onClick={() =>
                    this.handleChangePoints1(this.state.parameter.points1 + 1)
                  }
                />
              </Box>
              <hr />
              <Slider
                valueLabelDisplay="auto"
                sx={{
                  width: "60%",
                  "& .MuiSlider-rail": {
                    opacity: "1 !important",
                    backgroundColor: "#EBEBF4",
                    height: "0.425rem"
                  },
                  "& .MuiSlider-track": {
                    backgroundColor: "#7EC1CC",
                    opacity: "1 !important",
                    height: "0.425rem"
                  },
                }}
                step={1}
                max={this.state.parameter.maxRange}
                min={0}
                onChange={this.handlePoints1}
                value={this.state.range1}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                height: "5rem",
                borderRadius: '4px',
                margin: '1rem',
                border: "1px solid #EBEBF4"
              }}
            >
              <Typography
                sx={{
                  color: "#3F4253",
                  fontSize: "14px",
                  lineHeight: "18px",
                  marginY: "auto",
                  fontWeight: 400,
                }}
              >
                {this.props.t(
                  "adminPanel.criteriaManagement.criterias.pointsRange"
                )}{" "}
                2
              </Typography>
              <Box
                sx={{
                  width: "5rem",
                  height: "2rem",
                  backgroundColor: "#EBEBF4",
                  borderRadius: "0.3rem",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  paddingLeft: "0.5rem",
                  alignItems: "center",
                }}
              >
                <Input
                  disableUnderline={true}
                  type="number"
                  sx={{ color: "#2EA0B2", fontWeight: "600" }}
                  value={Number(this.state.parameter.points2).toString()}
                  onChange={(event) =>
                    this.handleChangePoints2(Number(event.target.value))
                  }
                />
                <RemoveCircleOutlineIcon
                  sx={{
                    marginLeft: "auto",
                    fontSize: "1rem",
                    cursor: "pointer",
                    fill: "#2EA0B2",
                    background: "#fff",
                    borderRadius: "50%"
                  }}
                  onClick={() =>
                    this.handleChangePoints2(this.state.parameter.points2 - 1)
                  }
                />
                <AddCircleOutlineRoundedIcon
                  sx={{
                    marginX: "0.3rem",
                    fontSize: "1rem",
                    cursor: "pointer",
                    fill: "#2EA0B2",
                    background: "#fff",
                    borderRadius: "50%"
                  }}
                  onClick={() =>
                    this.handleChangePoints2(this.state.parameter.points2 + 1)
                  }
                />
              </Box>
              <hr />
              <Slider
                valueLabelDisplay="auto"
                sx={{
                  width: "60%",
                  color: "var(--primary----blue-1)",
                  "& .MuiSlider-rail": {
                    backgroundColor: "#EBEBF4",
                    opacity: "1 !important",
                    height: "0.425rem"
                  },
                  "& .MuiSlider-track": {
                    backgroundColor: "#7EC1CC",
                    opacity: "1 !important",
                    height: "0.425rem"
                  },
                }}
                step={1}
                max={this.state.parameter.maxRange}
                min={0}
                onChange={this.handlePoints2}
                value={this.state.range2}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                border: "1px solid #EBEBF4",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                height: "5rem",
                borderRadius: '4px',
                margin: '1rem'
              }}
            >
              <Typography
                sx={{
                  color: "var(--primary----blue)",
                  fontSize: "14px",
                  lineHeight: "18px",
                  marginY: "auto",
                  fontWeight: 400,
                }}
              >
                {this.props.t(
                  "adminPanel.criteriaManagement.criterias.pointsRange"
                )}{" "}
                3
              </Typography>
              <Box
                sx={{
                  width: "5rem",
                  height: "2rem",
                  backgroundColor: "#EBEBF4",
                  borderRadius: "0.3rem",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  paddingLeft: "0.5rem",
                  alignItems: "center",
                }}
              >
                <Input
                  disableUnderline={true}
                  type="number"
                  sx={{ color: "#2EA0B2", fontWeight: "600" }}
                  value={Number(this.state.parameter.points3).toString()}
                  onChange={(event) =>
                    this.handleChangePoints3(Number(event.target.value))
                  }
                />
                <RemoveCircleOutlineIcon
                  sx={{
                    marginLeft: "auto",
                    fontSize: "1rem",
                    cursor: "pointer",
                    fill: "#2EA0B2",
                    background: "#fff",
                    borderRadius: "50%"
                  }}
                  onClick={() =>
                    this.handleChangePoints3(this.state.parameter.points3 - 1)
                  }
                />
                <AddCircleOutlineRoundedIcon
                  sx={{
                    marginX: "0.3rem",
                    fontSize: "1rem",
                    cursor: "pointer",
                    fill: "#2EA0B2",
                    background: "#fff",
                    borderRadius: "50%"
                  }}
                  onClick={() =>
                    this.handleChangePoints3(this.state.parameter.points3 + 1)
                  }
                />
              </Box>
              <hr />
              <Slider
                valueLabelDisplay="auto"
                sx={{
                  width: "60%",
                  "& .MuiSlider-track": {
                    backgroundColor: "#EBEBF4",
                    border: "transparent !important",
                    height: "0.425rem"
                  },
                  "& .MuiSlider-rail": {
                    backgroundColor: "#2EA0B2",
                    opacity: 1,
                    height: "0.425rem"
                  },
                }}
                track="inverted"
                step={1}
                max={this.state.parameter.maxRange}
                min={0}
                onChange={this.handlePoints3}
                value={this.state.range3}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          marginY: '0.3rem',
          marginBottom: "1em",
          cursor: 'pointer'
        }}
          onClick={this.handleRestoreOldParams}>
          <CachedIcon sx={{
            fontSize: "12px",
            marginRight: "0.5rem",
            color: "#2EA0B2",
            marginY: "auto"
          }} />
          <Typography sx={{
            fontSize: "12px",
            lineHeight: "16px",
            color: "#2EA0B2",

          }} >

            {this.props.t('adminPanel.criteriaManagement.criterias.restoreInitialSettings')}
          </Typography>
        </Box>

      </>
    );
  }
}

export default withTranslation("")(CriteriaOptions);
