import { Component } from "react";


class Redirect extends Component {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    const url = window.location.href;
    const hash = url.split("#")[1];

    hash.split('&').forEach(item => {
      document.cookie = item;
    });
    
    document.location.href = "/";
  }

  render() {
    return (
      <section>
        Loading...
      </section>
    );
  }
}

export default Redirect;