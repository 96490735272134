import React from 'react'
import TopNavbar from "app/components/TopNavbar/TopNavbar"
import { withTranslation, WithTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import PersonProvider from 'app/services/PersonProvider';
import PersonCard from 'app/components/PersonCard/PersonCard'
import SearchBar from 'app/components/SearchBar/SearchBar';

class Contacts extends React.Component<WithTranslation, { contacts: any, openModal: boolean, search: string, contacts_loaded: boolean }> {
  constructor(props: any) {
    super(props);

    this.state = {
      contacts: null,
      openModal: false,
      search: "",
      contacts_loaded: false
    };
    this.getContacts();
  }
 

  getContacts = async (text="") => {
    let user = JSON.parse(sessionStorage.user);
    const contacts = await PersonProvider.getAllContacts(user.sub, text);
    this.setState({ contacts: contacts, contacts_loaded: true });
  };

  handleSetSearch = (query:string) => {
    this.setState({search: query})
  }


  render() {

    return (
      <>
        <TopNavbar />
        <div className='container pt-4'>
          <h1 style={{marginBottom: "1em", fontWeight: "bold"}}>{this.props.t("menu.savedContacts")}</h1>
          <SearchBar 
              getData={this.getContacts} 
              search={this.handleSetSearch}
              disabled={!this.state.contacts_loaded}
              />
          <p style={{ fontSize: '14px', color: "#85879C", lineHeight: "21px" }} >{this.props.t("vipsPage.showing")} {this.state.contacts && this.state.contacts.length} {this.props.t("contactsPage.contacts")}</p>
          <Grid container spacing={2}>
            {this.state.contacts ?
              this.state.contacts.map((contact: any) => (
                <Grid item xs={3} >
                  <PersonCard person={contact} isVip={false} showAddFavourite={true} reloadContacts={this.getContacts} />
                </Grid>                
              ))
              :
              null
            }
          </Grid>
        </div>
      </>
    )
  }
}

export default withTranslation("")(Contacts);