import React from "react";
import TopNavbar from "app/components/TopNavbar/TopNavbar";
import { Box, Grid, Modal } from "@mui/material";
import { withTranslation, WithTranslation } from "react-i18next";
import DashboardProvider from "app/services/DashboardProvider";
import dashboardVip from "app/assets/images/dashboardVip.png";
import dashboardContact from "app/assets/images/dashboardContact.png";
import dashboardConnection from "app/assets/images/dashboardConnection.png";
import dashboardInteraction from "app/assets/images/dashboardInteraction.png";
import "./Dashboard.scss";
import NetworkProvider from "app/services/NetworkProvider";
import { VOSviewerOnline } from "vosviewer-online";
import BottomBar from "app/components/BottomBar/BottomBar";
import PersonCardContact from "app/components/PersonCardContact/PersonCardContact";
import PersonProvider from "app/services/PersonProvider";
import InvalidJSONFormatModal from "app/components/InvalidJSONFormatModal/InvalidJSONFormatModal";

class Dashboard extends React.Component<
  WithTranslation & any,
  {
    vipCount: number;
    contactCount: number;
    connectionCount: number;
    interactionCount: number;
    selectedPerson: any;
    json: any;
    open: boolean;
    info: any;
    validJson: boolean;
  }
> {
  constructor(props: any) {
    super(props)
    this.state = {
      vipCount: 0,
      contactCount: 0,
      connectionCount: 0,
      interactionCount: 0,
      json: null,
      selectedPerson: null,
      open: false,
      info: null,
      validJson: false,
    };
    this.getNetwork();
    window.addEventListener("click", this.handleClickCanvas);
  }

  style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 2,
    transform: 'translate(-50%, -50%)',
    width: 310,
    bgcolor: '#fff',
    border: '1px solid #EBEBF4'
  }

  handleClickCanvas = (event: any) => {
    // find the canvas element
    if (event.target.className.includes("interaction-canvas")) {
      const toolbox = document.querySelectorAll('[ class*="-info-item" ]');
      if (toolbox.length > 3) {
        const text = toolbox[0].innerHTML.split(":")[1]?.trim() || "";
        if (text !== "" && text !== "undefined") this.handleOnClickNode(text);
      }
    }
  }
  handleClose = () => this.setState({ open: false })

  getInformation = async (id: number) => {
    let user = JSON.parse(sessionStorage.user);
    const info = await PersonProvider.getInfoById(id, user.sub);
    this.setState({ info: info });
    return info;
  };

  reloadPersonData = async (id: number) => {
    let user = JSON.parse(sessionStorage.user);
    const info = await PersonProvider.getInfoById(id, user.sub);
    this.setState({ selectedPerson: info });
  };

  handleOnClickNode = async (nodeId: any) => {
    if (!this.state.json) return;

    const person = this.state.json.network.items.find(
      (el: { label: any }) => el.label === nodeId
    )

    if (!person) return;
    
    const info = await this.getInformation(person.id);

    const selectedPerson = {
      ...info,
      url: person.url
    };    

    if (person) {
      this.setState({ selectedPerson: selectedPerson, open: true });
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener('click', this.handleClickCanvas)
  }

  getNetwork = async () => {
    const network = await NetworkProvider.getAll()
    this.setState({ json: { network } })
  }

  calculateZoomLevel = () => {
    return window.devicePixelRatio * 100;
  };


  parent_ref: any = React.createRef()
  div_ref: any = React.createRef()
  bottom_ref: any = 0

  isValidJsonFormat(data: { network: { items: any; links: any; }; }) {
      if (!data || typeof data !== 'object' || !data.network) return false;

      const { items, links } = data.network;

      if (!Array.isArray(items) || !Array.isArray(links)) return false;

      return true;
  }

  renderIframe() {
    if (this.state.json && (this.state.validJson !== this.isValidJsonFormat(this.state.json))) {
      this.setState({ validJson: this.isValidJsonFormat(this.state.json) })
    }
    return (
      <div
      style={{
        width:  this.div_ref ? this.div_ref.current?.offsetWidth : 0,
        height: "650px",
        transform: 'scale(1)',
        transformOrigin: '0 0'
      }}
      >
        {this.state.json ? (
            <VOSviewerOnline
              data={this.state.json}
              parameters={{
                item_size: 1,
                item_size_variation: 0.3,
                scale: 0.5,
                link_size_variation: 2,
                zoom_level: 1,
                simple_ui: true,
                show_info: false,
                show_legend: false
              }}
            />
        ) : (
          'Loading...'
        )}
        {this.state.json && !this.state.validJson ? (
          <Modal
            open={!this.state.validJson}
            onClose={() => window.location.reload()}>
            <InvalidJSONFormatModal />
          </Modal>
        ) : null}
      </div>
    )
  }

  componentDidMount(): void {
    this.getDashboardData()
  }

  getDashboardData = async () => {
    const data = await DashboardProvider.getDashboardData()
    if (data) {
      this.setState({
        vipCount: data.vipCount,
        contactCount: data.contactCount,
        connectionCount: data.connectionCount,
        interactionCount: data.interactionCount
      })
    }
  }

  render() {
    return (
      <>
        <TopNavbar />
        <div className="container" ref={this.parent_ref}>
          <Grid
            container
            className={this.calculateZoomLevel() === 150 ? "cards-container-zoomed" : "cards-container"}
            ref={this.div_ref}
          >
            <Grid item >
              <div className="dashboard-card">
                <div className="dashboard-card-image">
                  <img src={dashboardVip} />
                </div>
                <div className="">
                  <h4 className="dashboard-card-title">
                    {this.state.vipCount}
                  </h4>
                  <p className="dashboard-card-subtitle">
                    {this.calculateZoomLevel() === 150
                    ? this.props.t('dashboard.vipsCount150')
                    : this.props.t('dashboard.vipsCount')}
                  </p>
                </div>
              </div>
            </Grid>
            <Grid item >
              <div className="dashboard-card">
                <div className="dashboard-card-image">
                  <img src={dashboardContact} />
                </div>
                <div className="">
                  <h4 className="dashboard-card-title">
                    {this.state.contactCount}
                  </h4>
                  <p className="dashboard-card-subtitle">
                    {this.calculateZoomLevel() === 150
                    ? this.props.t('dashboard.contactsCount150')
                    : this.props.t('dashboard.contactsCount')}
                  </p>
                </div>
              </div>
            </Grid>
            <Grid item >
              <div className="dashboard-card">
                <div className="dashboard-card-image">
                  <img src={dashboardConnection} />
                </div>
                <div className="">
                  <h4 className="dashboard-card-title">
                    {this.state.connectionCount}
                  </h4>
                  <p className="dashboard-card-subtitle">
                    {this.calculateZoomLevel() === 150
                    ? this.props.t('dashboard.connectionsCount150')
                    : this.props.t('dashboard.connectionsCount')}
                  </p>
                </div>
              </div>
            </Grid>
            <Grid item >
              <div className="dashboard-card">
                <div className="dashboard-card-image">
                  <img src={dashboardInteraction} />
                </div>
                <div className="">
                  <h4 className="dashboard-card-title">
                    {this.state.interactionCount}
                  </h4>
                  <p className="dashboard-card-subtitle">
                    {this.calculateZoomLevel() === 150
                    ? this.props.t('dashboard.interactionsCount150')
                    : this.props.t('dashboard.interactionsCount')}
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
          {this.renderIframe()}
          <Modal
            open={this.state.open}
            onClose={this.handleClose}
            className={'graph-modal'}
          >
            <Box sx={this.style}>
              <PersonCardContact
                person={this.state.selectedPerson}
                score={this.state.selectedPerson?.score}
                interactionNumber={this.state.selectedPerson?.interactionNumber}
                connectionId={this.state.selectedPerson?.connectionId}
                showAddFavourite={true}
                isVip={this.state.selectedPerson?.person_type_id.id === 1}
                reload={() => this.reloadPersonData(this.state.selectedPerson?.id)}
              />
            </Box>
          </Modal>
        </div>

        <BottomBar height={(event) => (this.bottom_ref = event)} />
      </>
    )
  }
}

export default withTranslation('')(Dashboard)
