import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import PersonProvider from "app/services/PersonProvider";
import ConnectionProvider from "app/services/ConnectionProvider";
import TopNavbar from "app/components/TopNavbar/TopNavbar";
import {
  Grid,
  Tabs,
  Tab,
  Pagination,
  Box,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import PersonCardDetail from "app/components/PersonCardDetail/PersonCardDetail";
import PersonCardContact from "app/components/PersonCardContact/PersonCardContact";
import GoBackButton from "app/components/GoBackButton/GoBackButton";
import ListIcon from "@mui/icons-material/List";
import { Button, Checkbox, DatePicker, Tooltip } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import TabPanel from "app/components/TabPanel/TabPanel";
import interaction from "app/assets/images/interaction_default.png";
import interactionFocus from "app/assets/images/interaction_focus.png";
import NetworkGraph from "app/components/NetworkGraph/NetworkGraph";
import contactAvatar from "app/assets/images/contact_graph.svg";
import emptyContacts from "app/assets/images/empty_contacts.png";
import vipAvatar from "app/assets/images/vip_graph.svg";
import SearchBar from "app/components/SearchBar/SearchBar";
import Info from "app/assets/images/info.png";
import CategoryTopics from "app/components/CategoryTopics/CategoryTopics";
import ParametersProvider from "app/services/ParametersProvider";
import { Status } from "app/types/Status";
import Calculating from "app/components/Load/Calculating";
import EntityList from "app/components/EntityTopics/EntityList";
import useEntityStore from "app/stores/entities.store";
import EntityDoughnut from "app/components/EntityDoughnut/EntityDoughnut";
import { VOSviewerOnline } from "vosviewer-online";
import NetworkProvider from "app/services/NetworkProvider";
import "./Vip.scss";
import BottomBar from "app/components/BottomBar/BottomBar";
import { RangePickerProps } from "antd/es/date-picker";
import moment from "moment";
import dayjs from "dayjs";
import { DownOutlined, MinusOutlined } from "@ant-design/icons";
import NoConnectionsContact from "app/components/noConnectionsContact/noConnectionsContact";
import InvalidJSONFormatModal from "app/components/InvalidJSONFormatModal/InvalidJSONFormatModal";

const { RangePicker } = DatePicker;
let enabledDateFilters: string[] = [
  moment('2023-01-01').format("DD/MM/YYYY"),
  moment().format("DD/MM/YYYY"),
];

const disabledDate: RangePickerProps["disabledDate"] = (current) => {
  // Can not select days before today
  return current && current > dayjs().endOf("day");
};

class Vip extends React.Component<
  WithTranslation,
  {
    id: number;
    vip: any;
    vipData: any;
    categories: any;
    selectedPerson: any;
    entities: any;
    graph: any;
    tab: number;
    menuTab: number;
    count: number;
    page: number;
    memberCount: number;
    favouriteCount: number;
    open: boolean;
    dataAvailable: boolean;
    showSaved: boolean;
    search: string;
    json: Object;
    openDateFilter: boolean;
    startDate: string;
    endDate: string;
    validJson: boolean;
  }
> {
  constructor(props: any) {
    super(props);
    const id = window.location.pathname.split("/").pop();

    this.state = {
      id: parseInt(id),
      vip: null,
      vipData: null,
      graph: null,
      memberCount: 0,
      favouriteCount: 0,
      categories: null,
      entities: null,
      selectedPerson: null,
      menuTab: 0,
      tab: 0,
      page: 1,
      count: 0,
      open: false,
      dataAvailable: true,
      showSaved: false,
      search: "",
      json: null,
      openDateFilter: false,
      startDate: "",
      endDate: "",
      validJson: false,
    };

    window.addEventListener("click", this.handleClickCanvas);
  }

  handleClickCanvas = (event: any) => {
    // find the canvas element
    if (event.target.className.includes("interaction-canvas")) {
      const toolbox = document.querySelectorAll('[ class*="-info-item" ]');
      if (toolbox.length > 3) {
        const text = toolbox[0].innerHTML.split(":")[1]?.trim() || "";
        if (text !== "" && text !== "undefined") this.handleOnClickNode(text);
      }
    }
  };

  componentWillUnmount(): void {
    window.removeEventListener("click", this.handleClickCanvas);
  }

  canvas_ref = React.createRef();

  handleClose = () => this.setState({ open: false });

  style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    zIndex: 2,
    transform: "translate(-50%, -50%)",
    width: 310,
    bgcolor: "#fff",
    border: "1px solid #EBEBF4",
  };

  handleMenuChange = (event: React.SyntheticEvent, newValue: number) => {
    this.setState({ menuTab: newValue });
  };

  handleChange = (event: React.SyntheticEvent, newValue: number) => {
    this.setState({ tab: newValue });
  };

  componentDidMount() {
    enabledDateFilters = [
      moment('2023-01-01').format("DD/MM/YYYY"),
      moment().format("DD/MM/YYYY"),
    ];
    this.setState({
      startDate: enabledDateFilters[0]?.split("/").reverse().join("-") || undefined,
      endDate: enabledDateFilters[1]?.split("/").reverse().join("-") || undefined,
    })
    this.getVipDetails();
    this.getVipEntitiesCategories();
    this.getGraphDetails();
    this.getNetwork();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.search !== this.state.search) {
      this.setState({ page: 1 });
    }
  }

  getNetwork = async () => {
    const network = await NetworkProvider.get(this.state.id.toString());
    this.setState({ json: { network } });
  };

  getVipDetails = async () => {
    const { page } = this.state;
    let user = JSON.parse(sessionStorage.user);
    let skip = page === 1 ? 0 : (page - 1) * 12;
    await this.getStatus();
    const vip = await PersonProvider.getVip(
      this.state.id,
      user.sub,
      skip,
      this.state.search
    );
    let count = vip.memberCount / 12;
    count = count > 1 ? Math.ceil(count) : 1;
    if (this.state.search !== "") {
      if (this.state.showSaved) {
        const vips = await PersonProvider.getFavouriteVipContacts(
          this.state.id,
          user.sub,
          skip,
          this.state.search
        );
        let count = vips.memberCount / 12;
        count = count > 1 ? Math.ceil(count) : 1;
        this.setState({ vipData: vips.data, count: count });
      } else {
        this.setState({
          vipData: vip.data.person_from ? vip.data.person_from : [],
          count: count,
          memberCount: vip.memberCount,
          favouriteCount: vip.favouriteCount,
        });
      }
    } else {
      if (this.state.showSaved) {
        const vips = await PersonProvider.getFavouriteVipContacts(
          this.state.id,
          user.sub,
          skip,
          this.state.search
        );
        let count = vips.memberCount / 12;
        count = count > 1 ? Math.ceil(count) : 1;
        this.setState({
          vipData: vips.data,
          count: count,
          favouriteCount: vip.favouriteCount,
        });
      } else {
        this.setState({
          vip: vip.data,
          vipData: vip.data?.person_from,
          count: count,
          memberCount: vip.memberCount,
          favouriteCount: vip.favouriteCount,
        });
      }
    }
  };

  handleDateFilterClick = () => {
    this.setState(
      {
        dataAvailable: false,
        openDateFilter: false,
      },
      () => {
        this.getVipEntitiesCategories();
      }
    );
  };

  handleOnChangeDateFilter = (date: any, dateString: any) => {
    if (dateString.includes("")) {
      enabledDateFilters = [];
    } else {
      enabledDateFilters = dateString;
    }
  };

  handleRemoveDateFilter = () => {
    enabledDateFilters = [];
    this.setState(
      {
        dataAvailable: false,
        openDateFilter: false,
      },
      () => {
        this.getVipEntitiesCategories();
      }
    );
  };

  getVipEntitiesCategories = async () => {
    if (enabledDateFilters.length === 0 || enabledDateFilters[0] == undefined || enabledDateFilters[1] == undefined) {
      this.setState({
        dataAvailable: true,
      });
      return
    }
    const startDate = enabledDateFilters[0].split("/").reverse().join("-")
    const endDate = enabledDateFilters[1].split("/").reverse().join("-")
    const result = await PersonProvider.getVipEntitiesCategories(
      this.state.id,
      startDate,
      endDate
    );
    if (result?.category?.length === 0) {
      result.category = [];
    }
    this.setState({
      categories: result?.category || null,
      dataAvailable: true,
      startDate: startDate,
      endDate: endDate,
    });
  };

  handlePageChange = (event: any, value: number) => {
    this.setState({ page: value }, () => {
      this.getVipDetails();
    });
  };

  getGraphDetails = async () => {
    let user = JSON.parse(sessionStorage.user);
    let graph = await PersonProvider.getVipGraph(this.state.id, user.sub);

    const contactCount = graph.nodes.filter(
      (el: { favourites: [] }) => el.favourites?.length > 0
    ).length;
    graph.nodes = graph.nodes.map((obj: any) => ({
      ...obj,
      svg: contactAvatar,
    }));
    graph.nodes[0].svg = vipAvatar;
    this.setState({ graph: graph, favouriteCount: contactCount });

    if (this.state.selectedPerson) {
      const person = graph.nodes.find(
        (el: { id: any }) => el.id === this.state.selectedPerson.id
      );
      this.setState({ selectedPerson: person });
    }
  };

  getStatus = async () => {
    return new Promise((resolve, reject) =>
      ParametersProvider.getStatus().then((status) => {
        if (status.findIndex((s: Status) => !s.score_available) < 0) {
          this.setState({ dataAvailable: true });
          resolve(true);
        } else {
          this.setState({ dataAvailable: false });
          setTimeout(() => {
            this.getStatus().then(() => resolve(true));
          }, 5000);
        }
      })
    );
  };

  handleOnClickNode = (nodeId: any) => {
    if (!this.state.graph) return;

    const person = this.state.graph.nodes.find(
      (el: { name: any }) => el.name === nodeId
    );

    if (!person) return;

    if (person.connectionId) {
      this.setState({ selectedPerson: person, open: true });
    }
  };

  handleSetSearch = (query: string) => {
    this.setState({ search: query });
  };

  onChangeShowSaved = async (e: CheckboxChangeEvent) => {
    let user = JSON.parse(sessionStorage.user);
    let skip = 0;
    this.setState({ page: 1 });

    if (e.target.checked) {
      const vips = await PersonProvider.getFavouriteVipContacts(
        this.state.id,
        user.sub,
        skip,
        this.state.search
      );
      let count = vips.memberCount / 12;
      count = count > 1 ? Math.ceil(count) : 1;
      this.setState({ vipData: vips.data, showSaved: true, count: count });
    } else {
      this.handlePageChange(1, 1);
      this.setState({ showSaved: false });
    }
  };

  div_ref: any = React.createRef();
  bottom_ref: any = 0;
  
  isValidJsonFormat(data: any) {
      if (!data || typeof data !== 'object' || !data.network) return false;

      const { items, links } = data.network;

      if (!Array.isArray(items) || !Array.isArray(links)) return false;

      return true;
  }

  renderIframe() {
    if (this.state.json && (this.state.validJson !== this.isValidJsonFormat(this.state.json))) {
      this.setState({ validJson: this.isValidJsonFormat(this.state.json) })
    }
    return (
      <div
      style={{
        width:  this.div_ref ? this.div_ref.current?.offsetWidth : 0,
        height: "650px",
        transform: 'scale(1)',
        transformOrigin: '0 0'
      }}
      >
        <VOSviewerOnline
          data={this.state.json}
          parameters={{
            item_size: 1,
            item_size_variation: 0.4,
            scale: 0.6,
            link_size_variation: 2,
            zoom_level: 1,
            simple_ui: true,
            show_info: false,
            show_legend: false,
          }}
        />
        {this.state.json && !this.state.validJson ? (
          <Modal
            open={!this.state.validJson}
            onClose={() => window.location.reload()}>
            <InvalidJSONFormatModal />
          </Modal>
        ) : null}
      </div>
    );
  };

  bottom_height: number = 0;
  render() {
    return (
      <>
        <TopNavbar />
        <div className="card-vip-detail">
          <div className="container pt-4">
            <GoBackButton />
            <Grid className="grid-profile" spacing={4}>
              {this.state.vip ? (
                <PersonCardDetail
                  person={this.state.vip}
                  isVip={true}
                  isShareIcons={true}
                  showContacts={true}
                  memberCount={this.state.memberCount}
                  savedContacts={this.state.favouriteCount}
                />
              ) : null}
            </Grid>

            {this.state.dataAvailable ? (
              <>
                <Grid className="grid-tabs" ref={this.div_ref} spacing={4}>
                  <Box
                    className="menutabs-container"
                    sx={{
                      display: "flex",
                      borderBottom: 1,
                      borderColor: "#EBEBF4",
                    }}
                  >
                    <Tabs
                      value={this.state.menuTab}
                      onChange={this.handleMenuChange}
                    >
                      <Tab label={this.props.t("general.contactsList")}/>
                      <Tab label={this.props.t("general.hotTopics")} />
                    </Tabs>
                  </Box>
                </Grid>
                <TabPanel value={this.state.menuTab} index={1}>
                    <Grid container spacing={2} sx={{ marginBottom: "2em" }}>
                      <Grid item xs={12} sx={{position: "relative"}}>
                        <RangePicker
                          size="large"
                          separator={<MinusOutlined />}
                          suffixIcon={<DownOutlined />}
                          disabledDate={disabledDate}
                          open={this.state.openDateFilter}
                          onFocus={() => this.setState({ openDateFilter: true })}
                          onBlur={() => this.setState({ openDateFilter: false })}
                          onKeyDown={() =>
                            this.setState({ openDateFilter: false })
                          }
                          value={[
                            enabledDateFilters[0]
                              ? dayjs(enabledDateFilters[0], "DD/MM/YYYY")
                              : dayjs('2023-01-01'),
                            enabledDateFilters[1]
                              ? dayjs(enabledDateFilters[1], "DD/MM/YYYY")
                              : dayjs(),
                          ]}
                          renderExtraFooter={() => (
                            <div
                              style={{
                                float: "right",
                                marginRight: "0.5em",
                                paddingBottom: "0.5em",
                                fontSize: "13px",
                                marginTop: "0.5em",
                              }}
                              className="filter-date-container"
                            >
                              <Button
                                className="ant-cancel-button"
                                onClick={() => this.handleRemoveDateFilter()}
                              >
                                {this.props.t("general.cancel")}
                              </Button>
                              <Button
                                onClick={() => this.handleDateFilterClick()}
                                className="ant-apply-button"
                                disabled={enabledDateFilters.length === 0}
                              >
                                {this.props.t("interactionData.apply")}
                              </Button>
                            </div>
                          )}
                          format={"DD/MM/YYYY"}
                          onChange={this.handleOnChangeDateFilter}
                          style={{
                            float: "right",
                            marginBottom: "1em",
                            position: "absolute",
                            zIndex: 1,
                            right: "0px",
                            top: "28px",
                          }}
                        />
                        <CategoryTopics data={this.state.categories} />
                      </Grid>
                      <Grid item xs={8}>
                        <div className="entityTitle">
                          {this.props.t("general.entityTitle")}{" "}
                          <Tooltip
                            className="ml-2 mt-auto mb-auto"
                            placement="right"
                            overlayStyle={{ maxWidth: "325px" }}
                            title={this.props.t("entities.tooltip")}
                          >
                            <img src={Info} />
                          </Tooltip>
                        </div>
                        <EntityList
                          vipId={this.state.id}
                          startDate={this.state.startDate || null}
                          endDate={this.state.endDate || null}
                        />
                      </Grid>
                      <Grid item xs={4} className="grid-charts">
                        <Paper className="mt-4">
                          <EntityDoughnut vipId={this.state.id} />
                        </Paper>
                      </Grid>
                    </Grid>
                </TabPanel>

                
                <TabPanel value={this.state.menuTab} index={0}>
                  { this.state.vip !== null && this.state.vip.fromCount < 2  ? 
                    <NoConnectionsContact name={this.state.vip.name} /> 
                  : (
                  <Grid className="grid-topics mb-2 d-flex mt-4" spacing={4}>
                    {this.state.vipData ? (
                      <>
                        <div className="d-flex justify-content-start">
                          <TabPanel value={this.state.tab} index={1}>
                            <SearchBar
                              isSmall={true}
                              getData={this.getVipDetails}
                              search={this.handleSetSearch}
                            />
                          </TabPanel>
                        </div>
                        <div className="d-flex ml-auto saved-contacts-container">
                          <TabPanel value={this.state.tab} index={1}>
                            <span className="text">
                              {this.props.t("vipsPage.showOnlySaved")}
                            </span>
                            <Checkbox defaultChecked={this.state.showSaved} onChange={this.onChangeShowSaved} />
                          </TabPanel>
                        </div>
                        <div className="d-block tabs-container">
                          <Tabs
                            value={this.state.tab}
                            onChange={this.handleChange}
                          >
                            <Tab
                              icon={
                                <img
                                  src={
                                    this.state.tab === 1
                                      ? interaction
                                      : interactionFocus
                                  }
                                  alt="interaction"
                                />
                              }
                              aria-label="list"
                            />
                            <Tab icon={<ListIcon />} aria-label="list" />
                          </Tabs>
                        </div>
                      </>
                    ) : null}
                  </Grid>
                  )}
                  { this.state.vip !== null && this.state.vip.fromCount < 2  ? 
                    null : (
                    <TabPanel value={this.state.tab} index={0}>
                      <Grid className="grid-table">
                        <div
                          style={{
                            width: this.div_ref
                              ? this.div_ref.current?.offsetWidth
                              : "100%",
                              height: this.div_ref
                              ? "650px"
                              : "100%",
                          }}
                        >
                          {this.state.json ? (
                            this.renderIframe()
                          ) : (
                            <> Loading...</>
                          )}
                        </div>
                      </Grid>
                    </TabPanel>

                  )}
                  <TabPanel value={this.state.tab} index={1}>
                    {this.state.vipData && this.state.vipData.length > 0 ? (
                      <>
                        <Grid
                          container
                          className="grid-table"
                          spacing={4}
                          sx={{}}
                        >
                          {this.state.vip && this.state.vipData
                            ? this.state.vipData.map((contact: any) => (
                                <Grid item xs={3}>
                                  <PersonCardContact
                                    person={
                                      contact.to_person
                                        ? contact.to_person
                                        : undefined
                                    }
                                    score={contact.score}
                                    connectionId={contact.id}
                                    interactionNumber={
                                      contact.interactionNumber
                                    }
                                    showAddFavourite={true}
                                    reload={this.getVipDetails}
                                  />
                                </Grid>
                              ))
                            : null}
                        </Grid>
                        <div className="table-pagination pb-4">
                          {this.state.vip && this.state.vipData ? (
                            <Pagination
                              count={this.state.count}
                              page={this.state.page}
                              onChange={this.handlePageChange}
                              variant="outlined"
                              shape="circular"
                            />
                          ) : null}
                        </div>
                      </>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "3em",
                        }}
                      >
                        <img src={emptyContacts} alt="no contacts" />
                        <p
                          style={{
                            color: "#85879C",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          {this.props.t("general.emptyContacts")}
                        </p>
                      </Box>
                    )}
                  </TabPanel>
                </TabPanel>
              </>
            ) : (
              <Calculating active={!this.state.dataAvailable} />
            )}
          </div>
          <Modal
            open={this.state.open}
            onClose={this.handleClose}
            className={"graph-modal"}
          >
            <Box sx={this.style}>
              <PersonCardContact
                person={this.state.selectedPerson}
                score={this.state.selectedPerson?.score}
                interactionNumber={this.state.selectedPerson?.interactionNumber}
                connectionId={this.state.selectedPerson?.connectionId}
                showAddFavourite={true}
                reload={this.getGraphDetails}
              />
            </Box>
          </Modal>
        </div>
        <BottomBar height={(event) => (this.bottom_ref = event)} />
      </>
    );
  }
}

export default withTranslation("")(Vip);
