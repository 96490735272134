import React from 'react'
import TopNavbar from "app/components/TopNavbar/TopNavbar"
import { withTranslation, WithTranslation } from "react-i18next";
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Button,
  TextField,
  styled,
  InputAdornment,
  IconButton,
  OutlinedInput,
  FormHelperText,
  Snackbar,
  Slide
} from '@mui/material';
import UserProvider from 'app/services/UserProvider';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Visibility from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import refreshIcon from 'app/assets/images/refresh.png'
import MDBox from "materials/components/MDBox";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { TransitionProps } from "@mui/material/transitions";

import "./Profile.scss";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
class Profile extends React.Component<
  WithTranslation,
  {
    username: string
    email: string
    oldPassword: string
    newPassword: string
    errorMessage: string
    changePassword: boolean
    showNewPassword: boolean
    showOldPassword: boolean
    openSnackbar: boolean
    openErrorSnackbar: boolean
  }> {
  constructor(props: any) {
    super(props);

    this.state = {
      username: "",
      email: "",
      newPassword: "",
      oldPassword: "",
      errorMessage: this.props.t("profilePage.changesNotSaved"),
      changePassword: false,
      showNewPassword: false,
      showOldPassword: false,
      openSnackbar: false,
      openErrorSnackbar: false
    };
  }

  CustomInput = styled(TextField)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      color: "#B8B9C6",
      backgroundColor: "#fff",
      border: '1px solid #B8B9C6',
      fontSize: 16,
      width: '100%',
      padding: '10px 12px'
    },
    '& .MuiInputBase-input:focus': {
      border: '2px solid #2EA0B2 !important',
      borderRadius: '4px'
    },
    '& div.Mui-error': {
      border: '1px solid #F44335 !important',
      borderRadius: '4px'
    }
  }));

  componentDidMount() {
    this.getUser();
  }

  getUser() {
    const user = JSON.parse(sessionStorage.user);

    this.setState({
      username: user["cognito:username"], email: user.email, oldPassword: ""
    })
  }

  updateUser = async () => {
    if (this.state.oldPassword !== "" && !this.validatePassword()) {
      const oldPassword = btoa(this.state.oldPassword);
      const newPassword = btoa(this.state.newPassword);

      const response = await UserProvider.putEditUser(oldPassword, newPassword)

      if (response === "success") {
        this.setState({
          openSnackbar: true,
        });
        await new Promise((resolve) => setTimeout(resolve, 1000));
        document.cookie = "id_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        caches.delete("profile");
        window.location.href = "/";
      }
      else if (response === "LimitExceededException") {
        this.setState({
          errorMessage: this.props.t("profilePage.tooMuchAttemps"),
          openErrorSnackbar: true
        });
      }
      else if (response === "Incorrect username or password.") {
        this.setState({
          errorMessage: this.props.t("profilePage.wrongPassword"),
          openErrorSnackbar: true
        });
      }
      else {
        this.setState({
          openErrorSnackbar: true
        });
      }

    } else {
      this.setState({
        errorMessage: this.props.t("profilePage.emptyPassword"),
        openErrorSnackbar: true
      });
    }
  }

  validatePassword() {
    let check = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;

    if (this.state.newPassword.match(check)) {
      return false
    } else {
      return true
    }
  }

  render() {

    return (
      <>
        <TopNavbar />
        <div className='container pt-4 pb-4 profile-container'>
          <h1>{this.props.t("general.profileSettings")}</h1>
          <Grid container spacing={4} sx={{ marginTop: "1em" }} >
            <Grid item xs={3} >
              <Typography variant='body1' sx={{ color: "#585A6A", fontWeight: "bold", fontSize: "15px", borderBottom: "1px solid #EBEBF4", paddingBottom: "1.5em" }} >{this.props.t("profilePage.editProfile")}</Typography>
            </Grid>
            <Grid item xs={9} sx={{ display: "flex", flexDirection: "column" }} >
              <FormControl variant="standard" sx={{ marginTop: "1em" }}>
                <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: "#85879C", fontSize: "18px !important" }} >
                  {this.props.t("profilePage.username")}
                </InputLabel>
                <this.CustomInput variant="standard" disabled sx={{ width: "70%" }} inputProps={{ disableUnderline: true }} value={this.state.email} id="bootstrap-input" onChange={(e) => this.setState({ email: e.target.value })} />
              </FormControl>
              <FormControl variant="standard" sx={{ marginTop: "1em" }}>
                <InputLabel shrink htmlFor="outlined-adornment-password" sx={{ color: "#85879C", fontSize: "18px !important", marginBottom: "2em" }} >
                  {this.props.t("profilePage.oldPassword")}
                </InputLabel>
                <div style={{ display: "flex", flexDirection: "row", alignContent: "center", marginTop: "1.2em" }}>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    style={!this.state.changePassword ? {background: "#EBEBF4"} : {}}
                    sx={{ border: "1px solid #B8B9C6", borderRadius: "4px", width: "70%" } }
                    type={this.state.showOldPassword ? 'text' : 'password'}
                    value={this.state.oldPassword}
                    onChange={(e) => this.setState({ oldPassword: e.target.value })}
                    disabled={!this.state.changePassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => this.setState({ showOldPassword: true })}
                          onMouseDown={() => this.setState({ showOldPassword: false })}
                          edge="end"
                        >
                          {this.state.showOldPassword ? <Visibility /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <IconButton
                    onClick={() => this.setState({ changePassword: true })}
                    edge="end"
                  >
                    <img src={refreshIcon} alt="refresh" />
                    <span style={{ fontSize: "14px", color: "#2EA0B2", marginLeft: "0.4em" }}>{this.props.t("profilePage.resetPassword")}</span>
                  </IconButton>
                </div>
              </FormControl>
              <FormControl variant="standard" sx={{ marginTop: "1em" }}>
                <InputLabel shrink htmlFor="outlined-adornment-password" sx={{ color: "#85879C", fontSize: "18px !important" }} >
                  {this.props.t("profilePage.newPassword")}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  className='mt-4'
                  style={!this.state.changePassword ? {background: "#EBEBF4"} : {}}
                  sx={{ border: "1px solid #B8B9C6", borderRadius: "4px", width: "70%" }}
                  type={this.state.showNewPassword ? 'text' : 'password'}
                  value={this.state.newPassword}
                  error={this.state.newPassword.length > 0 ? this.validatePassword() : false}
                  onChange={(e) => this.setState({ newPassword: e.target.value })}
                  disabled={!this.state.changePassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => this.setState({ showNewPassword: true })}
                        onMouseDown={() => this.setState({ showNewPassword: false })}
                        edge="end"
                      >
                        {this.state.showNewPassword ?  <Visibility /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {(this.state.newPassword.length > 0 && this.validatePassword()) && (
                  <FormHelperText error >
                    {this.props.t("error.password")}
                  </FormHelperText>
                )}
              </FormControl>
              <div className='mt-4 d-flex align-center justify-content-end' style={{ width: "70%" }}>
                <Button
                  sx={{ background: "#EBEBF4", borderRadius: "4px", padding: "7px 14px", color: "#585A6A", marginRight: "1em", textTransform: "none" }}
                  onClick={() => this.setState({ oldPassword: "", newPassword: "", changePassword: false })}
                >
                  {this.props.t("general.cancel")}
                </Button>
                <Button
                  sx={{ background: "#2EA0B2", color: "#fff", padding: "8px 16px", borderRadius: "4px", textTransform: "none" }}
                  onClick={this.updateUser}
                >
                  {this.props.t("general.save")}
                </Button>
              </div>
            </Grid>
          </Grid>
          <Snackbar
            open={this.state.openSnackbar}
            autoHideDuration={6000}
            TransitionComponent={Transition}
            onClose={() => this.setState({ openSnackbar: false })}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <MDBox
              sx={{
                width: "15.75rem",
                borderLeft: "1px solid var(--positive)",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                backgroundColor: "#fff",
                height: "7rem",
                boxShadow: " 1px 1px 4px rgba(0, 0, 0, 0.15)",
                borderRadius: "2px",
                marginTop: "5rem",
                padding: "2rem",
              }}
            >
              <CheckCircleRoundedIcon
                sx={{
                  color: "var(--positive)",
                  marginRight: "1rem",
                }}
              />
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "24px",
                }}
              >
                {this.props.t(
                  "profilePage.successPasswordChange"
                )}
              </Typography>
              <CloseIcon
                sx={{
                  position: "absolute",
                  right: "0.5rem",
                  height: "100%",
                  paddingBottom: "2rem",
                  cursor: "pointer",
                }}
                onClick={() => this.setState({ openSnackbar: false })}
              />
            </MDBox>
          </Snackbar>
          <Snackbar
            open={this.state.openErrorSnackbar}
            autoHideDuration={6000}
            TransitionComponent={Transition}
            onClose={() => this.setState({ openErrorSnackbar: false, errorMessage: this.props.t("profilePage.changesNotSaved") })}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <MDBox
              sx={{
                width: "15.75rem",
                borderLeft: "1px solid var(--acent---error)",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                backgroundColor: "var(--white)",
                height: "7rem",
                boxShadow: " 1px 1px 4px rgba(0, 0, 0, 0.15)",
                borderRadius: "2px",
                marginTop: "5rem",
                padding: "2rem",
              }}
            >
              <ErrorRoundedIcon
                sx={{
                  color: "var(--acent---error)",
                  marginRight: "1rem",
                }}
              />
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "24px",
                }}
              >
                {this.state.errorMessage}
              </Typography>
              <CloseIcon
                sx={{
                  position: "absolute",
                  right: "0.5rem",
                  height: "100%",
                  paddingBottom: "2rem",
                  cursor: "pointer",
                }}
                onClick={() => this.setState({ openErrorSnackbar: false })}
              />
            </MDBox>
          </Snackbar>
        </div>
      </>
    )
  }
}

export default withTranslation("")(Profile);