import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  Grid,
  Pagination,
  Box,
  Typography,
  Paper,
  Tabs,
  Tab,
} from "@mui/material";
import { t } from "i18next";
import toast, { Toaster } from "react-hot-toast";
import { Button, Dropdown, DatePicker, Tooltip } from "antd";
import { DownOutlined, MinusOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import DoughnutCustom from "app/components/DoughnutCustom/DoughnutCustom";
import LineCustom from "app/components/LineCustom/LineCustom";
import TabPanel from "app/components/TabPanel/TabPanel";
import CategoryTopics from "app/components/CategoryTopics/CategoryTopics";
import EntityList from "app/components/EntityTopics/EntityList";
import EntityDoughnut from "app/components/EntityDoughnut/EntityDoughnut";

import "./Connection.scss";

import ConnectionProvider from "app/services/ConnectionProvider";
import TopNavbar from "app/components/TopNavbar/TopNavbar";
import DataTable from "app/components/DataTable/DataTable";
import PersonCardDetail from "app/components/PersonCardDetail/PersonCardDetail";
import GoBackButton from "app/components/GoBackButton/GoBackButton";
import ParametersProvider from "app/services/ParametersProvider";
import { Status } from "app/types/Status";
import Calculating from "app/components/Load/Calculating";

import arrows from "app/assets/images/arrows.png";
import emptyInteractions from "app/assets/images/empty_interactions.png";
import filterIcon from "app/assets/images/filter.svg";
import chevronDown from "app/assets/images/chevron_down.svg";
import checkmark from "app/assets/images/checkmark.svg";
import checkmarkLight from "app/assets/images/checkmark_light.svg";
import Info from "app/assets/images/info.png";
import moment from "moment";

type TableSort = "ASC" | "DESC";
const { RangePicker } = DatePicker;
//set enabledDateFiltersConnection to the last 6 months and actual date in format DD/MM/YYYY
let enabledDateFiltersConnection: string[] = [
  moment('2023-01-01').format("DD/MM/YYYY"),
  moment().format("DD/MM/YYYY"),
];

const disabledDate: RangePickerProps["disabledDate"] = (current) => {
  // Can not select days before today
  return current && current > dayjs().endOf("day");
};

class Connection extends React.Component<
  WithTranslation,
  {
    id: number;
    connection: any;
    maxScore: number;
    interactions: any;
    interactionCount: any;
    sorting: TableSort;
    page: number;
    count: number;
    dataAvailable: boolean;
    enabledInteractionFilters: string[];
    enabledDirectionalityFilters: string[];
    possibleInteractionFilters: any;
    possibleDirectionalityFilters: string[];
    timetable: any;
    dougnutData: any;
    lineData: any;
    openDateFilter: boolean;
    menuTab: number;
    categories: any;
    startDate: string;
    endDate: string;
  }
> {
  constructor(props: any) {
    super(props);
    const id = window.location.pathname.split("/").pop();
    enabledDateFiltersConnection = [
      moment('2023-01-01').format("DD/MM/YYYY"),
      moment().format("DD/MM/YYYY"),
    ];
    this.state = {
      id: parseInt(id),
      connection: null,
      interactions: [],
      interactionCount: [],
      maxScore: 0,
      sorting: "DESC",
      page: 1,
      count: 0,
      dataAvailable: false,
      enabledInteractionFilters: [],
      enabledDirectionalityFilters: [],
      possibleDirectionalityFilters: [
        "all",
        "bidirectional",
        "contact-to-vip",
        "vip-to-contact",
      ],
      possibleInteractionFilters: {
        all: "#FFFFFF",
        quote: "#1070C9",
        comment: "#BDDEFF",
        like: "#18B19D",
        mention: "#65AEDC",
        "mention-third-party": "#82D480",
        retweet: "#4383FA",
      },
      timetable: {},
      dougnutData: null,
      lineData: null,
      openDateFilter: false,
      menuTab: 0,
      categories: null,
      startDate:  undefined,
      endDate:  undefined,
      
    };
  }

  doughnutOptions = () => {
    return {
      cutout: 70,
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
      },
    };
  };

  calculateDoughnutData() {
    const dataValues: any = {};
    for (const idx in this.state.possibleInteractionFilters) {
      if (idx !== "all")
        dataValues[idx] = this.state.interactionCount[idx] || 0;
    }
    const ret = {
      labels: Object.keys(this.state.possibleInteractionFilters)
        .filter((idx: string) => idx !== "all")
        .map((idx) => t(`general.filter.interaction.${idx}`)),
      datasets: [
        {
          label: "",
          data: Object.values(dataValues),
          backgroundColor: Object.values(
            this.state.possibleInteractionFilters
          ).filter(
            (idx: any) => idx !== this.state.possibleInteractionFilters.all
          ),
          rotation: 90,
        },
      ],
    };
    this.setState({ dougnutData: ret });
  }

  calculateLineChartData() {
    const ret = {
      labels: Object.keys(
        this.state.timetable[Object.keys(this.state.timetable)[0]]
      ),
      datasets: Object.keys(this.state.possibleInteractionFilters)
        .filter((idx) => idx !== "all")
        .map((val: string) => {
          return {
            label: t(`general.filter.interaction.${val}`),
            data: Object.values(this.state.timetable[val]),
            borderColor: this.state.possibleInteractionFilters[val],
            backgroundColor: this.state.possibleInteractionFilters[val],
            fill: false,
            pointRadius: 1,
          };
        }),
    };
    this.setState({ lineData: ret });
  }

  handleChange = (event: any, value: number) => {
    this.setState(
      {
        page: value,
      },
      () => {
        this.retrieveInteractions();
        this.getConnectionCategories();
      }
    );
  };



  retrieveInteractions(sorting = this.state.sorting) {
    const { page } = this.state;
    let skip = page === 1 ? 0 : (page - 1) * 25;
    ConnectionProvider.getInteractionsFromConnection(
      this.state.id,
      this.state.sorting,
      skip,
      this.state.enabledInteractionFilters,
      this.state.enabledDirectionalityFilters,
      enabledDateFiltersConnection
    )
      .then((response) => {
        const { data, count, timetable } = response;
        let amount = data.count / 25;
        amount = amount > 1 ? Math.trunc(amount) + 1 : 1;
        this.setState({
          interactions: data.data,
          count: amount,
          dataAvailable: true,
          sorting,
          timetable,
          interactionCount: count,
        });
        this.calculateDoughnutData();
        this.calculateLineChartData();
      })
      .catch(() => {
        toast(t("error.fetchData"));
      });
  }

  componentDidMount() {

    this.getConnectionDetails();
    this.getConnectionCategories();
  }

  getStatus = async () => {
    return new Promise((resolve, reject) =>
      ParametersProvider.getStatus().then((status) => {
        if (status.findIndex((s: Status) => !s.score_available) < 0) {
          resolve(true);
        } else {
          this.setState({ dataAvailable: false });
          setTimeout(() => {
            this.getStatus().then(() => resolve(true));
          }, 5000);
        }
      })
    );
  };

  getConnectionCategories = async () => {

    if (
      enabledDateFiltersConnection.length === 0 ||
      enabledDateFiltersConnection[0] == undefined ||
      enabledDateFiltersConnection[1] == undefined
    ) {
      this.setState({
        dataAvailable: true,
      });
      return;
    }
    const startDate = enabledDateFiltersConnection[0].split("/").reverse().join("-");
    const endDate = enabledDateFiltersConnection[1].split("/").reverse().join("-");
    const result = await ConnectionProvider.getConnectionCategories(
      this.state.id,
      startDate,
      endDate
    );
    if (result?.category?.length === 0) {
      result.category = [];
    }
    this.setState({
      categories: result?.category || null,
      dataAvailable: true,
      startDate: startDate,
      endDate: endDate,
    });
  };

  getConnectionDetails = async () => {
    await this.getStatus();
    const connection = await ConnectionProvider.getConnection(this.state.id);
    this.setState(
      { connection: connection.data, maxScore: connection.maxScore },
      () => {
        this.retrieveInteractions();

      }
    );
  };



  handleChangeSort(value: TableSort) {
    this.setState({ sorting: value, dataAvailable: false, page: 1 }, () => {
      this.retrieveInteractions();
    });
  }

  handleInteractionDropdownFilterClick: MenuProps["onClick"] = (e) => {
    const { key } = e;
    let { enabledInteractionFilters } = this.state;
    if (key === "all") {
      enabledInteractionFilters = [];
    } else {
      if (this.state.enabledInteractionFilters.indexOf(key) >= 0) {
        enabledInteractionFilters = enabledInteractionFilters.filter(
          (idx) => idx !== key
        );
      } else {
        enabledInteractionFilters.push(key);
      }
      if (
        Object.keys(this.state.possibleInteractionFilters).length - 1 ===
        enabledInteractionFilters.length
      ) {
        enabledInteractionFilters = [];
      }
    }

    this.setState(
      {
        enabledInteractionFilters,
        interactions: [],
        dataAvailable: false,
        page: 1,
      },
      () => {
        this.retrieveInteractions();
      }
    );
  };

  handleDirectionalityDropdownFilterClick: MenuProps["onClick"] = (e) => {
    const { key } = e;
    let { enabledDirectionalityFilters } = this.state;
    if (key === "all") {
      enabledDirectionalityFilters = [];
    } else {
      if (this.state.enabledDirectionalityFilters.indexOf(key) >= 0) {
        enabledDirectionalityFilters = enabledDirectionalityFilters.filter(
          (idx) => idx !== key
        );
      } else {
        enabledDirectionalityFilters.push(key);
      }
      if (
        this.state.possibleDirectionalityFilters.length - 1 ===
        enabledDirectionalityFilters.length
      ) {
        enabledDirectionalityFilters = [];
      }
    }

    this.setState(
      { enabledDirectionalityFilters, interactions: [], page: 1 },
      () => {
        this.retrieveInteractions();
      }
    );
  };

  handleRenderInteractionDropdownLabel(
    stateItem: string[],
    translationKeyBase: "interaction" | "directionality",
    size: number
  ) {
    const orderSource =
      translationKeyBase === "interaction"
        ? Object.keys(this.state.possibleInteractionFilters)
        : this.state.possibleDirectionalityFilters;
    if (stateItem.length === 0 || stateItem.length === size - 1) {
      return t(`general.filter.${translationKeyBase}.all`);
    } else {
      return stateItem
        .sort((a, b) =>
          orderSource.indexOf(a) > orderSource.indexOf(b) ? 1 : -1
        )
        .map((idx) => t(`general.filter.${translationKeyBase}.${idx}`))
        .join(", ");
    }
  }

  handleRenderCheckmark(
    filter: "interaction" | "directionality",
    value: string
  ) {
    const filterList =
      filter === "interaction"
        ? this.state.enabledInteractionFilters
        : this.state.enabledDirectionalityFilters;
    const possibilities =
      filter === "interaction"
        ? Object.keys(this.state.possibleInteractionFilters)
        : this.state.possibleDirectionalityFilters;
    if (
      filterList.length === 0 ||
      filterList.length === possibilities.length - 1
    ) {
      if (value === "all")
        return <img className="checkmark" src={checkmark} alt="checkmark" />;
      return <img className="checkmark" src={checkmarkLight} alt="checkmark" />;
    }
    if (filterList.indexOf(value) >= 0)
      return <img className="checkmark" src={checkmark} alt="checkmark" />;
    return null;
  }

  interactionTypes(): MenuProps {
    return {
      items: Object.keys(this.state.possibleInteractionFilters).map((idx) => ({
        key: idx,
        label: (
          <>
            {t(`general.filter.interaction.${idx}`)}
            {this.handleRenderCheckmark("interaction", idx)}
          </>
        ),
      })),
      onClick: this.handleInteractionDropdownFilterClick,
    };
  }

  directionalities(): MenuProps {
    return {
      items: this.state.possibleDirectionalityFilters.map((idx) => ({
        key: idx,
        label: (
          <>
            {t(`general.filter.directionality.${idx}`)}
            {this.handleRenderCheckmark("directionality", idx)}
          </>
        ),
      })),
      onClick: this.handleDirectionalityDropdownFilterClick,
    };
  }

  handleDateFilterClick = () => {
    this.setState(
      {
        dataAvailable: false,
        openDateFilter: false,
      },
      () => {
        this.retrieveInteractions();
        this.getConnectionCategories();
      }
    );
  };

  handleOnChangeDateFilter = (date: any, dateString: any) => {
    if (dateString[0] === "" && dateString[1] === "") {
      enabledDateFiltersConnection = [
        moment('2023-01-01').format("DD/MM/YYYY"),
        moment().format("DD/MM/YYYY"),
      ];
    } else {
      enabledDateFiltersConnection = dateString;
    }
  };

  handleRemoveDateFilter = () => {
    enabledDateFiltersConnection = [
      moment('2023-01-01').format("DD/MM/YYYY"),
      moment().format("DD/MM/YYYY"),
    ];
    this.setState(
      {
        dataAvailable: true,
        openDateFilter: false,
      },
      () => {
        this.retrieveInteractions();
        this.getConnectionCategories();
      }
    );
  };

  handleMenuChange = (event: React.SyntheticEvent, newValue: number) => {
    this.setState({ menuTab: newValue });
  };

  renderTimetableDates(position: "first" | "last"): string {
    const key = Object.keys(this.state.timetable)[0];
    if (!key) return;

    let dateElem = "";

    if (position === "first") {
      dateElem = Object.keys(this.state.timetable[key])[0];
    } else if (position === "last") {
      dateElem = Object.keys(this.state.timetable[key])[
        Object.keys(this.state.timetable[key]).length - 1
      ];
    }
    return moment(dateElem).format("DD MMM YYYY");
  }

  render() {
    
    return (
      <>
        <Toaster />
        <TopNavbar />
        <div className="card-connection-detail">
          <div className="container pt-4">
            <GoBackButton />
            <h1>{this.props.t("general.connectionDetails")}</h1>
            <Grid className="grid-profile d-flex pb-4" container spacing={4}>
              {this.state.connection && (
                <>
                  <Grid item xs={10} className="d-flex">
                    <PersonCardDetail
                      person={this.state.connection?.from_person}
                      isVip={true}
                      isGoToProfile={true}
                      isShareIcons={true}
                      saveContactMode="compact"
                    />
                    <img src={arrows} className="arrows-image" alt="arrows" />
                    <PersonCardDetail
                      person={this.state.connection?.to_person}
                      getContactDetails={() => this.getConnectionDetails()}
                      isSaveContact={true}
                      saveContactMode="compact"
                      isVip={false}
                      isGoToProfile={true}
                      isShareIcons={true}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className="d-flex flex-column justify-content-center align-self-center"
                  >
                    <div className="scoring-container align-self-end">
                      <span>{t("general.score")}</span>
                      <span>
                        {(
                          (this.state.connection.score / this.state.maxScore) *
                          100
                        ).toFixed(1)}
                        %
                      </span>
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid className="grid-tabs" container spacing={4}>
              <Box
                className="menutabs-container"
                sx={{
                  display: "flex",
                  borderBottom: 1,
                  borderColor: "#EBEBF4",
                }}
              >
                <Tabs
                  value={this.state.menuTab}
                  onChange={this.handleMenuChange}
                >
                  <Tab label={this.props.t("connectionPage.tab.first.title")} />
                  <Tab label={this.props.t("general.hotTopics")} />
                </Tabs>
              </Box>
            </Grid>
          </div>
        </div>
        <TabPanel value={this.state.menuTab} index={0}>
          <div className="container interactions-detail">
            {this.state.dataAvailable && (
              <Grid item xs={12}>
                <div className="table-title">
                  {t("interactionData.title")}
                  <small>{t("interactionData.lastUpdated")}</small>
                  {this.state.menuTab === 0? <RangePicker
                    size="large"
                    separator={<MinusOutlined />}
                    suffixIcon={<DownOutlined />}
                    disabledDate={disabledDate}
                    open={this.state.openDateFilter}
                    onFocus={() => this.setState({ openDateFilter: true })}
                    onBlur={() => this.setState({ openDateFilter: false })}
                    onKeyDown={() => this.setState({ openDateFilter: false })}
                    value={[
                      enabledDateFiltersConnection[0]
                        ? dayjs(enabledDateFiltersConnection[0], "DD/MM/YYYY")
                        : null,
                      enabledDateFiltersConnection[1]
                        ? dayjs(enabledDateFiltersConnection[1], "DD/MM/YYYY")
                        : null,
                    ]}
                    renderExtraFooter={() => (
                      <div
                        style={{
                          float: "right",
                          marginRight: "0.5em",
                          paddingBottom: "0.5em",
                          fontSize: "13px",
                        }}
                        className="filter-date-container"
                      >
                        <Button
                          className="ant-cancel-button"
                          onClick={() =>this.state.menuTab === 0? this.handleRemoveDateFilter() : null}
                        >
                          {t("general.cancel")}
                        </Button>
                        <Button
                          onClick={() => this.state.menuTab === 0? this.handleDateFilterClick() :null}
                          className="ant-apply-button"
                          disabled={enabledDateFiltersConnection.length === 0}
                        >
                          {t("interactionData.apply")}
                        </Button>
                      </div>
                    )}
                    format={"DD/MM/YYYY"}
                    onChange={this.handleOnChangeDateFilter}
                    style={{ float: "right", marginBottom: "1em" }}
                  /> : null }
                </div>
                <Grid container>
                  <Grid item xs={3} className="grid-charts">
                    <Paper>
                      <Typography variant="h6">
                        {t("interactionData.total")}
                      </Typography>
                      <div className="grid-chart-wrapper">
                        <div className="grid-charts-amount">
                          <span>
                            {Object.values(this.state.interactionCount).reduce(
                              (acc: any, cur: any) => {
                                return acc + cur;
                              },
                              0
                            )}
                          </span>
                        </div>
                        <div className="grid-charts-doughnut">
                          <DoughnutCustom
                            data={this.state.dougnutData}
                            options={this.doughnutOptions}
                          />
                        </div>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={9} className="grid-charts">
                    <Paper>
                      <Typography variant="h6">
                        {t("interactionData.evolution")}
                      </Typography>
                      <div className="grid-chart-wrapper">
                        <div className="grid-charts-line">
                          <LineCustom data={this.state.lineData} />
                          <div className="grid-charts-amount-bottom-labels">
                            <div className="grid-chart-first-date">
                              {this.renderTimetableDates("first")}
                            </div>
                            <div className="grid-chart-last-date">
                              {this.renderTimetableDates("last")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {Object.keys(this.state.possibleInteractionFilters).map(
                    (idx: string) => {
                      const color = this.state.possibleInteractionFilters[idx];
                      if (idx === "all") return null;
                      return (
                        <div className="legend-item-wrapper">
                          <div
                            className="legend-color"
                            style={{ backgroundColor: color }}
                          ></div>
                          <span>{t(`general.filter.interaction.${idx}`)}</span>
                        </div>
                      );
                    }
                  )}
                </Grid>
              </Grid>
            )}
            <div className="card-connection-table">
              <div className="container pt-2 pb-4">
                <Grid container className="grid-table pt-4" spacing={4}>
                  {this.state.dataAvailable ? (
                    <>
                      <Grid item xs={12}>
                        <div className="table-title">
                          {t("interactionTable.title")}
                        </div>
                        <Grid container>
                          <Grid item xs={3}>
                            <Paper className="filters-container">
                              <Typography
                                variant="h6"
                                className="filters-title"
                              >
                                <img src={filterIcon} alt="filter" />
                                {t("general.filters.title")}
                              </Typography>
                              <div className="filters-dropdowns-container">
                                <Typography className="dropdown-title">
                                  {t("general.filters.interactionTypes")}
                                </Typography>
                                <Dropdown
                                  className="filters-dropdown"
                                  menu={this.interactionTypes()}
                                  trigger={["click"]}
                                >
                                  <Button>
                                    <span style={{ fontFamily: "Poppins" }}>
                                      {this.handleRenderInteractionDropdownLabel(
                                        this.state.enabledInteractionFilters,
                                        "interaction",
                                        this.interactionTypes().items.length
                                      )}
                                    </span>
                                    <img src={chevronDown} alt="chevron" />
                                  </Button>
                                </Dropdown>
                                <div className="separator"></div>
                                <Typography className="dropdown-title">
                                  {t("general.filters.directionality")}
                                </Typography>
                                <Dropdown
                                  className="filters-dropdown"
                                  menu={this.directionalities()}
                                  trigger={["click"]}
                                >
                                  <Button>
                                    <span style={{ fontFamily: "Poppins" }}>
                                      {this.handleRenderInteractionDropdownLabel(
                                        this.state.enabledDirectionalityFilters,
                                        "directionality",
                                        this.directionalities().items.length
                                      )}
                                    </span>
                                    <img src={chevronDown} alt="chevron" />
                                  </Button>
                                </Dropdown>
                              </div>
                            </Paper>
                          </Grid>
                          <Grid item xs={9} className="tableContainer">
                            {this.state.interactions &&
                            this.state.interactions.length > 0 ? (
                              <>
                                <DataTable
                                  data={this.state.interactions}
                                  sorting={this.state.sorting}
                                  changeSorting={(s: TableSort) =>
                                    this.handleChangeSort(s)
                                  }
                                />
                                <div className="table-pagination">
                                  <Pagination
                                    count={this.state.count}
                                    page={this.state.page}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                    shape="circular"
                                  />
                                </div>
                              </>
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginTop: "3em",
                                }}
                              >
                                <img
                                  className="mb-4"
                                  src={emptyInteractions}
                                  alt="no"
                                />
                                <p
                                  style={{
                                    color: "#85879C",
                                    fontSize: "18px",
                                    textTransform: "unset",
                                    fontWeight: "bold",
                                    marginBottom: 6,
                                  }}
                                >
                                  {this.props.t("general.emptyInteractions")}
                                </p>
                                <p
                                  style={{
                                    color: "#85879C",
                                    fontSize: "18px",
                                    textTransform: "unset",
                                  }}
                                >
                                  {this.state.connection?.from.name +
                                    this.props.t("general.notHadInteractions") +
                                    this.state.connection?.to.name}
                                </p>
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <Calculating active={!this.state.dataAvailable} />
                  )}
                </Grid>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={this.state.menuTab} index={1}>
          <div className="container connection-topics">
            {this.state.dataAvailable && (
            <Grid container spacing={2} sx={{ marginBottom: "2em" }}>
              <Grid item xs={12} sx={{ position: "relative" }}>
              {this.state.menuTab === 1? <RangePicker
                    size="large"
                    separator={<MinusOutlined />}
                    suffixIcon={<DownOutlined />}
                    disabledDate={disabledDate}
                    open={this.state.openDateFilter}
                    onFocus={() => this.setState({ openDateFilter: true })}
                    onBlur={() => this.setState({ openDateFilter: false })}
                    onKeyDown={() => this.setState({ openDateFilter: false })}
                    value={[
                      enabledDateFiltersConnection[0]
                        ? dayjs(enabledDateFiltersConnection[0], "DD/MM/YYYY")
                        : null,
                      enabledDateFiltersConnection[1]
                        ? dayjs(enabledDateFiltersConnection[1], "DD/MM/YYYY")
                        : null,
                    ]}
                    renderExtraFooter={() => (
                      <div
                        style={{
                          float: "right",
                          marginRight: "0.5em",
                          paddingBottom: "0.5em",
                          fontSize: "13px",
                        }}
                        className="filter-date-container"
                      >
                        <Button
                          className="ant-cancel-button"
                          onClick={() =>this.state.menuTab === 1? this.handleRemoveDateFilter() : null}
                        >
                          {t("general.cancel")}
                        </Button>
                        <Button
                          onClick={() => this.state.menuTab === 1? this.handleDateFilterClick() :null}
                          className="ant-apply-button"
                          disabled={enabledDateFiltersConnection.length === 0}
                        >
                          {t("interactionData.apply")}
                        </Button>
                      </div>
                    )}
                    format={"DD/MM/YYYY"}
                    onChange={this.handleOnChangeDateFilter}
                    style={{ float: "right", marginBottom: "1em" }}
                  /> : null }
                <CategoryTopics data={this.state.categories} />
              </Grid>
              <Grid item xs={8}>
                <div className="entityTitle">
                  {this.props.t("general.entityTitle")}{" "}
                  <Tooltip
                    className="ml-2 mt-auto mb-auto"
                    placement="right"
                    overlayStyle={{ maxWidth: "325px" }}
                    title={this.props.t("entities.tooltip")}
                  >
                    <img src={Info} />
                  </Tooltip>
                </div>
                <EntityList
                  vipId={this.state.id}
                  startDate={this.state.startDate || null}
                  endDate={this.state.endDate || null}
                  isConnection={true}
                />
              </Grid>
              <Grid item xs={4} className="grid-charts">
                <Paper className="mt-4">
                  <EntityDoughnut 
                    vipId={this.state.id}
                    startDate={this.state.startDate || null}
                    endDate={this.state.endDate || null} />
                </Paper>
              </Grid>
            </Grid>
            )}
            {this.state.dataAvailable ? (
                    null
              ) : (
                <Calculating active={!this.state.dataAvailable} />
              )}
          </div>
        </TabPanel>
      </>
    );
  }
}

export default withTranslation("")(Connection);
