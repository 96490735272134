import chevronDown from "app/assets/images/chevron_down.svg";
import FlagWhite from "app/assets/images/flag_white.png";
import NLPGrey from "app/assets/images/nlp_grey.png";
import { Entity } from "app/types/Entity";
import { useEffect, useState } from "react";
import { Collapse } from "antd";
import "./EntityContainer.scss";
import EntityDropdown from "./EntityDropdown";

import TwitterLogoGray2 from "app/assets/images/twitter_logo_gray_2.png";
import React from "react";

interface Props {
  entity: Entity;
  count: number;
  vipId: number;
  sources: { url: string; date: string; type: string }[];
}

const EntityContainer = (props: Props) => {
  const [entity, setEntity] = useState(props.entity);
  const [count, setCount] = useState(props.count);
  const [vipId, setVipId] = useState(props.vipId);
  const [type, setType] = useState<"dict" | "nlp">(
    props.entity.from_platform ? "dict" : "nlp"
  );
  const [sources, setSources] = useState<
    { url: string; date: string; type: string }[]
  >([]);
  const [icon, setIcon] = useState(null);

  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);

  const sourceIcon = (type: string) => {
    switch (type) {
      case "twitter":
        return TwitterLogoGray2;
      default:
        return null;
    }
  };

  const parseDate = (date: string) => {
    const d = new Date(date);
    // return format %d/%m/%Y
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
  };

  const handleSourceClick = (url: string) => {
    const sanitazedUrl =
      "https://" + url.replace("http://", "").replace("https://", "");
    window.open(sanitazedUrl, "_blank");
  };

  useEffect(() => {
    setEntity(props.entity);
    setCount(props.count);
    setVipId(props.vipId);
    setSources(props.sources.sort((a, b) => {
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    }).filter((source) => source.url != null && source.url != ""));
    if (!props.entity.from_platform) {
      setIcon(NLPGrey);
      setType("nlp");
    } else {
      setIcon(FlagWhite);
      setType("dict");
    }
  }, [props.entity, props.count, props.vipId, props.sources]);

  return (
    <div
      className={"entityContainer" + (open ? " active" : "")}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => setOpen(!open)}
    >
      <div className="entityContainerTitle">
        <img
          className="entityContainerChevron"
          src={chevronDown}
          alt="chevron"
          style={{ transform: open ? "rotate(180deg)" : "rotate(0deg)" }}
        />
        <div className={"entityContainerIcon " + type}>
          <img src={icon} alt="" />
        </div>
        <span className="entityContainerName">
          {entity.name} · {count}
        </span>

        <EntityDropdown
          id={entity.id}
          dictionary={entity.dictionary}
          group_id={entity.group_id}
          name={entity.name}
          type={type}
          vipId={vipId}
          hidden={!hover}
          setOpen={setOpen}
        />
      </div>
      {sources && sources.length > 0? (
        <div className="entityContainerSources" hidden={!open}>
          {sources
            .map((source) => {
              return (
                <div className="entityContainerSource">
                  <span className="entityContainerSourceDate">
                    {parseDate(source.date)}
                  </span>
                  <div
                    className="entityContainerSourceIcon"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleSourceClick(source.url);
                    }}
                  >
                    <img src={sourceIcon(source.type)} alt="" />
                  </div>
                </div>
              );
            })}
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(EntityContainer);
