import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import './GoBackButton.scss'
import arrowLeft from 'app/assets/images/arrow-left.png'

const GoBackButton = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(-1)
    };

    return (
        <Button className='goback-button' onClick={handleClick} ><img src={arrowLeft} alt="left" /></Button>
    )
}

export default GoBackButton;