import { Badge, Button, Input, List, Tooltip } from "antd";
import { GroupType } from "app/types/GroupType";
import { t } from "i18next";
import AddGroup from "app/assets/images/add_group.png";
import FlagGray from "app/assets/images/flag_gray.png";
import Trash from "app/assets/images/trash.png";
import Edit from "app/assets/images/edit.png";
import Info from "app/assets/images/info.png";
import Xgray3 from "app/assets/images/x_gray_3.png";
import CloseCircleFilled from "app/assets/images/close_circle_filled.png";
import SuccessIcon from "app/assets/images/success_icon.png";
import Alert from "app/assets/images/alert.png";
import React, { useEffect } from "react";

import "./GroupCard.scss";

import GroupProvider from "app/services/GroupProvider";
import { ToastContainer, toast } from "react-toastify";
interface GroupCardProps {
  groups: GroupType[];
  getGroups: () => void;
  selected: (group: number) => void;
}

const CustomToast = (text: string) => (
  <div>
    <p>{t(text)}</p>
  </div>
);

const trimEveryWord = (text: string) => {
  const words = text.split(" ");
  const trimmedWords = words
    .filter((word) => word.trim() !== "")
    .map((word) => word.trim());
  return trimmedWords.join(" ");
};

export const GroupCard = (props: GroupCardProps) => {
  const [groups, setGroups] = React.useState<GroupType[]>([]);
  const [selectedGroup, setSelectedGroup] = React.useState<number>(-1);
  const [hover, setHover] = React.useState<number>(-1);
  const [notValid, setNotValid] = React.useState<{
    noText: boolean;
    alreadyExists: boolean;
  }>({ noText: false, alreadyExists: false });
  const [input, setInput] = React.useState<string>("");

  const [createGroup, setCreateGroup] = React.useState<boolean>(false);
  const [editGroup, setEditGroup] = React.useState<boolean | number>(false);
  const [deleteGroup, setDeleteGroup] = React.useState<boolean | number>(false);

  const createGroupModal = () => {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setNotValid({ noText: false, alreadyExists: false });
      setInput(event.target.value);
    };
    const validate = (value: string) => {
      value = trimEveryWord(value);
      if (value === "") {
        setNotValid({ noText: true, alreadyExists: false });
        return false;
      }
      if (
        groups.some((group) => group.name.toLowerCase() === value.toLowerCase())
      ) {
        setNotValid({ noText: false, alreadyExists: true });
        return false;
      }
      return true;
    };
    const save = async () => {
      const text = trimEveryWord(input);
      if (validate(text)) {
        const group: GroupType = {
          createdAt: new Date().toString(),
          updatedAt: new Date().toString(),
          id: 0,
          name: text.charAt(0).toUpperCase() + text.slice(1).toLowerCase(),
          dictionary: [],
        };
        const [response, status] = await GroupProvider.saveGroup(group);
        if (status === "valid") {
          toast.success(CustomToast("groupCard.toast.create.success"), {
            icon: <img src={SuccessIcon} />,
          });
          setInput("");

          setNotValid({ noText: false, alreadyExists: false });
          setCreateGroup(false);
          getGroups();
        } else {
          setNotValid({ noText: false, alreadyExists: true });
        }
      }
    };

    const close = () => {
      setCreateGroup(false);
      setNotValid({ noText: false, alreadyExists: false });
      setInput("");
    };

    return (
      <div className="open-modal" onClick={close}>
        <div
          className="create-group-modal"
          onClick={(event) => event.stopPropagation()}
        >
          <div className="cancel" onClick={close}>
            <img className="cancel-icon" src={Xgray3} />
          </div>
          <div className="modal-body">
            <div className="title">
              <h3 className="title-text">
                {t("groupCard.modal.create.title")}
              </h3>
            </div>
            <div className="input">
              <img
                className="not-valid-icon"
                hidden={Object.values(notValid).indexOf(true) === -1}
                src={CloseCircleFilled}
                onClick={() => setInput("")}
              />
              <Input
                value={input}
                onChange={onChange}
                className={
                  Object.values(notValid).indexOf(true) > -1
                    ? "input-text not-valid"
                    : "input-text"
                }
                placeholder={""}
              />
              <div
                className="not-valid-text"
                hidden={Object.values(notValid).indexOf(true) === -1}
              >
                {t(
                  "groupCard.modal.create." +
                    Object.keys(notValid)[Object.values(notValid).indexOf(true)]
                )}
              </div>
            </div>
            <div className="">
              <Button
                className="button-text"
                disabled={
                  input.length === 0 ||
                  Object.values(notValid).indexOf(true) !== -1
                }
                onClick={save}
              >
                {t("groupCard.modal.create.button")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const editGroupModal = () => {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setNotValid({ noText: false, alreadyExists: false });
      setInput(event.target.value);
    };
    const validate = (value: string) => {
      value = trimEveryWord(value);
      if (value === "") {
        setNotValid({ noText: true, alreadyExists: false });
        return false;
      }
      if (groups.some((group) => group.name === value)) {
        setNotValid({ noText: false, alreadyExists: true });
        return false;
      }
      return true;
    };
    const save = async () => {
      const text = trimEveryWord(input);
      if (validate(text)) {
        const group: GroupType = {
          createdAt: new Date().toString(),
          updatedAt: new Date().toString(),
          id: editGroup as number,
          name: text.charAt(0).toUpperCase() + text.slice(1).toLowerCase(),
          dictionary: [],
        };
        const [response, status] = await GroupProvider.updateGroup(group);
        if (status === "valid") {
          toast.success(CustomToast("groupCard.toast.edit.success"), {
            icon: <img src={SuccessIcon} />,
          });
          setInput("");

          setNotValid({ noText: false, alreadyExists: false });
          setEditGroup(false);
          getGroups();
        } else {
          setNotValid({ noText: false, alreadyExists: true });
        }
      }
    };

    const close = () => {
      setEditGroup(false);
      setNotValid({ noText: false, alreadyExists: false });
      setInput("");
    };

    return (
      <div className="open-modal" onClick={close}>
        <div
          className="create-group-modal"
          onClick={(event) => event.stopPropagation()}
        >
          <div className="cancel" onClick={close}>
            <img className="cancel-icon" src={Xgray3} />
          </div>
          <div className="modal-body">
            <div className="title">
              <h3 className="title-text">{t("groupCard.modal.edit.title")}</h3>
            </div>
            <div className="input">
              <img
                className="not-valid-icon"
                hidden={Object.values(notValid).indexOf(true) === -1}
                src={CloseCircleFilled}
                onClick={() => setInput("")}
              />
              <Input
                value={input}
                onChange={onChange}
                className={
                  Object.values(notValid).indexOf(true) > -1
                    ? "input-text not-valid"
                    : "input-text"
                }
                placeholder={""}
              />
              <div
                className="not-valid-text"
                hidden={Object.values(notValid).indexOf(true) === -1}
              >
                {t(
                  "groupCard.modal.create." +
                    Object.keys(notValid)[Object.values(notValid).indexOf(true)]
                )}
              </div>
            </div>
            <div className="">
              <Button
                className="button-text"
                disabled={
                  input === groups.find((it) => it.id === editGroup)?.name ||
                  input.length === 0 ||
                  Object.values(notValid).indexOf(true) !== -1
                }
                onClick={save}
              >
                {t("groupCard.modal.edit.button")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const deleteGroupModal = () => {
    const remove = async () => {
      const group: GroupType = {
        createdAt: new Date().toString(),
        updatedAt: new Date().toString(),
        id: deleteGroup as number,
        name: "",
        dictionary: [],
      };
      const [response, status] = await GroupProvider.removeGroup(group);
      if (status === "valid") {
        toast.success(CustomToast("groupCard.toast.delete.success"), {
          icon: <img src={SuccessIcon} />,
        });
        setDeleteGroup(false);
        getGroups();
      } else {
        setNotValid({ noText: false, alreadyExists: true });
      }
    };

    const close = () => {
      setDeleteGroup(false);
      setNotValid({ noText: false, alreadyExists: false });
      setInput("");
    };

    return (
      <div className="open-modal" onClick={close}>
        <div
          className="create-group-modal"
          onClick={(event) => event.stopPropagation()}
        >
          <div className="cancel" onClick={close}>
            <img className="cancel-icon" src={Xgray3} />
          </div>
          <div className="modal-body">
            <img className="delete-icon" src={Alert} />
            <div className="delete">
              <h3 className="delete-text">
                {t("groupCard.modal.delete.title")}
              </h3>
            </div>
            <div className="explanation">
              {t("groupCard.modal.delete.text")}
            </div>
            <div className="button">
              <Button
                className="button-cancel"
                onClick={() => setDeleteGroup(false)}
              >
                {t("groupCard.modal.delete.cancelButton")}
              </Button>
              <Button className="button-text" onClick={remove}>
                {t("groupCard.modal.delete.button")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getGroups = async () => {
    props.getGroups();
  };

  useEffect(() => {
    setGroups(props.groups);
  }, [props.groups]);

  //on selected group change
  useEffect(() => {
    props.selected(selectedGroup);
  }, [selectedGroup]);

  const calculateZoomLevel = () => {
    return window.devicePixelRatio * 100;
  };


  return (
    <>
      <ToastContainer autoClose={8000} />
      {createGroup ? createGroupModal() : null}
      {editGroup ? editGroupModal() : null}
      {deleteGroup ? deleteGroupModal() : null}
      <div className={calculateZoomLevel() === 150 ? "group-card-zoomed" : "group-card"}>
        <div className="card-header">
          <h3 className="card-title">{t("groupCard.title")}</h3>
          <Tooltip className="info-icon" placement="rightTop"  title={t("groupCard.tooltip")}>
            <img src={Info} />
          </Tooltip>
        </div>
        <div className="card-body">
          <List
            dataSource={groups}
            renderItem={(item) => (
              <>
                <Tooltip
                  showArrow={false}
                  title={
                    <>
                      <div>{item.name}</div>
                      <div>
                        {item.dictionary.filter((it) => it.list?.id === 1)
                          .length || 0}
                        {" : "}
                        {t("groupCard.relevant")}
                      </div>
                      <div>
                        {item.dictionary.filter((it) => it.list?.id === 2)
                          .length || 0}
                        {" : "}
                        {t("groupCard.notRelevant")}
                      </div>
                    </>
                  }
                >
                  <List.Item
                    className={
                      selectedGroup !== item.id ? "item" : "item selected"
                    }
                    onMouseLeave={() =>
                      hover === item.id ? setHover(-1) : null
                    }
                    onMouseEnter={() => setHover(item.id)}
                    onClick={() => {
                      setSelectedGroup(
                        selectedGroup === item.id ? -1 : item.id
                      );
                    }}
                  >
                    <span className="item-text">{item.name}</span>
                    <div className="item-action">
                      {calculateZoomLevel() !== 150 ? <img className="item-icon" src={FlagGray} /> : null}
                      <Badge
                        className="item-badge"
                        color="transparent"
                        overflowCount={99}
                        count={item.dictionary?.length || 0}
                        showZero
                      />
                      <div
                        className="edit"
                        hidden={
                          (hover !== item.id && selectedGroup !== item.id) ||
                          item.id === 0 ||
                          item.entity_type_id !== null
                        }
                      >
                        <div className="edit-box">
                          <img
                            className="edit-icon"
                            src={Edit}
                            onClick={(event) => {
                              event.stopPropagation();
                              setInput(item.name);
                              setEditGroup(item.id);
                            }}
                          />
                        </div>
                        <div className="edit-box">
                          <img
                            className="edit-icon"
                            src={Trash}
                            onClick={(event) => {
                              event.stopPropagation();
                              setDeleteGroup(item.id);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </List.Item>
                </Tooltip>
              </>
            )}
          />
        </div>
        <div className="card-bottom">
          <div
            className="card-bottom-button"
            onClick={() => {
              setInput("");
              setCreateGroup(true);
            }}
          >
            <img className="footer-icon" src={AddGroup} />
            <h4 className="card-text">{t("groupCard.footer")}</h4>
          </div>
        </div>
      </div>
    </>
  );
};
